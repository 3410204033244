import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    language: "alb",
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        checkLanguage: (state) => {
            const language = localStorage.getItem("lng");
            if (language) {
                state.language = language
            }else{
                localStorage.setItem('lng', state.language)
                state.language = initialState.language
            }
        },
        setLanguage: (state, action) => {
            if (action.payload) {
                state.language = action.payload
                localStorage.setItem('lng', state.language)
            }
        }
    },
})

export const { checkLanguage, setLanguage } = languageSlice.actions

export default languageSlice.reducer