import React from "react";
import { 
    // useDispatch, 
    useSelector 
} from "react-redux";
import { 
    Navigate, 
    // Route 
} from "react-router-dom";
// import { tokenValidation } from "../api/utils";
// import { setLoginDispatcher } from "../redux/app/auth/actions";

const ProtectedRoute = ({ path, exact, children, from }) => {
  const { authenticated } = useSelector((state) => state.auth);
  return authenticated ? <>{children}</> : <Navigate to="/login" state={{ from: from }}/>;
};

export default ProtectedRoute;
