import React from 'react'
import ShowTypographyModule from './modules/ShowTypographyModule'
import ShowImageModule from './modules/ShowImageModule'
import ShowButtonModule from './modules/ShowButtonModule'
import ShowVideoModule from './modules/ShowVideoModule'

export default function ModuleLoader({data}) {
  return (
    data &&
    data.map((data, index) => (
        <>
            {
                data.type === "typography" &&
                <ShowTypographyModule
                    currentIndex={index}
                    key={index}
                    data={data.data}
                    style={data.style}
                    extraProperties={data.extraProperties}
                />
            }
            {
                data.type === "image" &&
                <ShowImageModule
                    currentIndex={index}
                    key={index}
                    data={data.data}
                    style={data.style}
                    extraProperties={data.extraProperties}
                />
            }
            {
                data.type === "button" &&
                <ShowButtonModule
                    currentIndex={index}
                    key={index}
                    data={data.data}
                    style={data.style}
                    extraProperties={data.extraProperties}
                />
            }
            {
                data.type === "video" &&
                <ShowVideoModule
                    currentIndex={index}
                    key={index}
                    data={data.data}
                    style={data.style}
                    extraProperties={data.extraProperties}
                />
            }
        </>
    ))
  )
}