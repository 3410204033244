import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function BannerCard(props) {

    const 
    {
        containetrStyle,

            mainTextContainerStyle,
                header, 
                headerStyle,

                secondHeader, 
                secondHeaderStyle,

                desc, 
                descStyle,
                
                button, 
                buttonTxt,
                buttonStyle,
                buttonLink,

            // IMAGE PART
            imgBannerContainerStyle,
                textBannerContainerStyle,

                imgUrl,
                imgUrlStyle,

                secondImg, 
                secondImgStyle, 

    } = props;

    return (
        <Box sx={containetrStyle}>

            <Box sx={mainTextContainerStyle}>
                {
                    header &&
                    <Typography variant="h1" sx={headerStyle}>{header}</Typography>
                }

                {
                    secondHeader &&
                    <Typography variant="h5" sx={secondHeaderStyle}>{secondHeader}</Typography>
                }

                {
                    desc &&
                    <Typography variant="body1" sx={descStyle}>{desc}</Typography>
                }

                {
                    button && buttonTxt &&
                    <Button sx={buttonStyle} LinkComponent={Link} to={buttonLink}>{buttonTxt}</Button>
                }
            </Box>
            
            <Box sx={imgBannerContainerStyle}>
                {/* IMAGE */}
                <Box component='img' src={imgUrl} sx={[{width: 1, height: 1}, imgUrlStyle]} />
                {/* OTHER TEXT */}
                <Box component="img" src={secondImg} sx={[{width: 1, height: 1}, secondImgStyle]} />
            </Box>
        </Box>
    )
}