import { 
    // useDispatch, 
    useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../../../components/general/Wrapper";
import lng from "../../../utils/language";
// import {  setLogout } from "../../../redux/app/auth/actions";
import { useEffect, useState } from "react";
import LoginForm from "../../../components/auth/LoginForm";
import RegisterForm from "../../../components/auth/RegisterForm";
// import { logOutUser } from "../../../app/auth/Auth";
// import { GrFormClose } from "react-icons/gr";

function UserWrapper() {
  const { language } = useSelector((state) => state.language);
//   const dispatch = useDispatch();
  const location = useLocation();
  const [modalContent] = useState(0);
  const state = location.state;
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    //Clear localStorage
    // dispatch(logOutUser());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      setTimeout(function () {
        if (state?.from) {
          navigate(state?.from);
        } else {
          navigate("/dashboard");
        }
      }, 5000);
    }
    // eslint-disable-next-line
  }, [user]);

  const changeModalComponent = (val) =>{
    // setModalContent(val);
  }

  const components = [
    <LoginForm changeComponent = {changeModalComponent()}/>,
    <RegisterForm changeComponent = {changeModalComponent()}/>,
  ];

  return (
    <>
      <Wrapper title={lng[language]["login"]} logoTitle="Aventura Fillon tani!">
        {/* <LoginForm/> */}
        {components[modalContent]}
      </Wrapper>
    </>
  );
}
export default UserWrapper;
