import style from "./Message.module.css";
import { GrFormClose } from "react-icons/gr";
import { useDispatch } from "react-redux";
// import { setLoginMessage } from "../../../redux/app/auth/actions";

function Alert(props) {
  const dispatch = useDispatch();

//   const closeMessage = () => {
//     dispatch(setLoginMessage(""));
//   };
  return (
    <div className={style[props.type]}>
      {props.message}
      <span>
        {/* <GrFormClose size={20} color={"white"} onClick={() => closeMessage()} /> */}
      </span>
    </div>
  );
}
export default Alert;
