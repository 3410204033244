import { useEffect, useState } from "react";
import style from "./ShoppingCart.module.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../api/config";
// import { showLoader } from "../../redux/app/loader/actions";
import constants from "../../utils/consts";
// import { AiFillCloseCircle } from "react-icons/ai";
import lng from "../../utils/language";
import LoginRegisterModal from "../../components/auth/LoginRegisterModal";
import Button from "@mui/material/Button";
// import { removeCartProducts } from "../../app/cart/Cart";

function ShoppingCartList() {
//   const dispatch = useDispatch();
  const { items, playerInfo, coupon } = useSelector((state) => state.cart);
  const [errorMessage, setErrorMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const [waiver, setWaiver] = useState([]);
  const { language } = useSelector((state) => state.language);
  const { user, message, isLoading } = useSelector((state) => state.auth);
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();

  const getIds = () => {
    var ids = [];
    items.forEach((id) => {
      ids.push(...id.reservationIds);
    });
    return ids;
  };

  useEffect(() => {
    if(user) setShowLogin(false);
  }, [user]);

  useEffect(() => {

    if (isLoading === false && !user && message !== null) {
        setShowLogin(true)
    }
    //eslint-disable-next-line
  }, [message, isLoading])

  useEffect(() => {
    const myCart = JSON.parse(localStorage.getItem("cart"));
    var showWaver = [];
    if (myCart && myCart.length > 0) {
      myCart.forEach((item) => {
        if (item.name === "HyperDrive") showWaver.push("Karting");
        if (item.name === "Sakica") showWaver.push("Sakica");
      });
    }
    return setWaiver(showWaver);
  }, []);

  const checkout = () => {
    if (!user) {
      setShowLogin(true);
      return;
    }

    if (!items.length > 0) {
      setErrorMessage("Ju lutem zgjedhni së paku një aktivitet!");
      window.scrollTo(0, 0);
    } else if (!checked) {
      setErrorMessage("Ju lutem pajtohuni me kushtet tona te privatësisë");
      window.scrollTo(0, 0);
    } else {
      setErrorMessage("");
      api
        .post(
          "reservation/checkout",
          {
            ids: getIds(),
            players: playerInfo,
            coupon: coupon,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            // dispatch(showLoader(true));
            window.location.replace(res.data.url);
          } else {
            //Find which activity belongs to non-existing id
            const myCart = JSON.parse(localStorage.getItem("cart"));
            if (res.data.type === "coupon") {
              setErrorMessage(
                "Kuponi nuk është më valid! Ju lutem largoni kuponin nga shporta."
              );
            } else {
              var notExists = [];
              if (Array.isArray(myCart)) {
                myCart.forEach((item) => {
                  item.reservationIds.forEach((id) => {
                    if (
                      res.data.missing_values &&
                      res.data.missing_values.includes(id)
                    ) {
                      if (!notExists.includes(item.name))
                        notExists.push(item.name);
                    }
                  });
                });
                setErrorMessage(
                  "Ju kërkojmë falje, bileta në shportë  për " +
                    notExists.join(", ") +
                    " nuk është më valide! Produktet në shportën tuaj janë valide vetëm 10 minuta."
                );
              } else {
                setErrorMessage(
                  "Ju kërkojmë falje, bileta në shportë nuk është më valide! Produktet në shportën tuaj janë valide vetëm 10 minuta."
                );
              }
              setTimeout(() => {
                setErrorMessage("");
              }, 2000);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleInputChange = () => {
    setChecked(!checked);
  };

  const docs = {
    Sakica: "DeklarataAxeThrowing.docx",
    Karting: "DeklarataGoKart.docx",
  };

//   const removeCart = (reservation, index) => {
//     dispatch(removeCartProducts(reservation, false));
//   };

  const closeModal = () =>{
    setShowLogin(false);
  }

  return (
    <>
      {
        showLogin 
        && 
        <LoginRegisterModal  closeModal={()=>closeModal()} />
        
      }
      <div className={style.checkoutWrapper}>
        <div className={style.checkoutHeader}>
          <h1>{lng[language]["cart"]}</h1>
          <p>
            <span>{lng[language]["hurryMessage"]} </span>
          </p>
        </div>
        {errorMessage.length > 0 && <p className="errorMsg">{errorMessage}</p>}
        <table className={style.checkout}>
          <thead>
            <tr>
              <th>{lng[language]["activity"]}</th>
              <th>{lng[language]["price"]}</th>
              <th>{lng[language]["quantity"]}</th>
              <th>Total</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody className={style.speedScalesBody}>
            {items?.length > 0 ? (
              items.map((data, index) => (
                <tr key={index}>
                  <td>{data.name}</td>
                  <td>{data.unitPrice}&euro;</td>
                  <td>{data.data.length}</td>
                  <td>{data.price} &euro;</td>
                  {/* <td>
                    {" "}
                    <span
                      className={style.close}
                      onClick={() => removeCart(data, index)}
                    >
                      <AiFillCloseCircle />
                    </span>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr className={style.center} aria-colspan={4}>
                Asnjë rezervim i zgjedhur!
              </tr>
            )}
          </tbody>
        </table>
        {items?.length > 0 && (
          <>
            <label className={style.terms}>
              <input type="checkbox" onChange={handleInputChange} />
              {lng[language]["terms"]}
              <Link to="/terms" target="_blank">
                View{" "}
              </Link>{" "}
            </label>
            {waiver.length > 0 &&
              waiver.map((i, j) => {
                return (
                  <label className={style.terms} key={j}>
                    <input type="checkbox" checked readOnly />
                    {[i]} - {lng[language]["terms"]}
                    <a href={constants.doc + docs[i]} target="_blank" rel="noreferrer">
                      View{" "}
                    </a>
                  </label>
                );
              })}
            {/*  */}
            <button className="orangeBtn" onClick={() => checkout()}>
              {lng[language]["pay"]}
            </button>
            <Button onClick={() => navigate('../reservation')} variant="contained" sx={{marginLeft: '20px', 
                backgroundColor: '#321eff',
                padding: '12.5px 30px',
                borderRadius: '20px',
                color: 'white',
                outline: 'none',
                textDecoration: 'none',
                fontSize: '14px',
                width: 'fit-content',
                cursor: 'pointer',
                border: '1px solid #321eff',
                transition: '0.5s',
                ':hover': {
                    backgroundColor: 'transparent',
                }
            }}>
              {lng[language]["addAttraction"]}
            </Button>
            
          </>
        )}
      </div>
    </>
  );
}
export default ShoppingCartList;
