// SITE KEY: 6Lc6CZ0pAAAAAItRG4ghnapA4tr4RXNANlPgcB4E
// SECRET: 6Lc6CZ0pAAAAAE7OdAs2IuRDBilgQD9tKgFQ5Zez
import React from 'react';
import Box from '@mui/material/Box';
import ReCAPTCHA from "react-google-recaptcha";

const siteKey = '6Lc6CZ0pAAAAAItRG4ghnapA4tr4RXNANlPgcB4E';

export default function Recapcha({change}) {
    return (
        <Box>
            <ReCAPTCHA
                sitekey={siteKey}
                onChange={(val) => change(val)}
            />
        </Box>
    )
}