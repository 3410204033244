import style from "../ReservationLayout.module.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import moment from "moment";
import { useSelector } from "react-redux";
const sqLocale = require("moment/locale/sq");

function ReservationDatePicker(props) {
  const { language } = useSelector((state) => state.language);

  if (language === "alb") {
    moment.updateLocale("sq", sqLocale);
  } else moment.updateLocale("eng", sqLocale);

  const dates = [];

  for (let i = 0; i < 30; i++) {
    const date = new Date(new Date());
    date.setDate(new Date().getDate() + i);
    dates.push(date);
  }
  return (
    <div className={style.borders}>
      <CarouselProvider
        children={2}
        naturalSlideWidth={400}
        naturalSlideHeight={350}
        isPlaying={false}
        loop={true}
        interval={2500}
        visibleSlides={window.innerWidth > 768 ? 3 : 2}
        totalSlides={30}
      >
        <Slider className={style.corner}>
          {dates.map((date) => (
            <Slide
              index={date}
              key={Math.random()}
              onClick={() => props.changeDate(date)}
            >
              <div
                className={`${style.carouselDay} ${
                  props.selectedDate === moment(date).format("YYYY-MM-DD")
                    ? style.active
                    : ""
                }`}
              >
                <h1>{moment(date).format("dddd")}</h1>
                <span>
                  {date.getDate()} {moment(date).format("MMMM")}
                </span>
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={style.arrow}>
          <MdOutlineKeyboardArrowLeft />
        </ButtonBack>
        <ButtonNext className={`${style.arrow} ${style.rightArrow}`}>
          <MdOutlineKeyboardArrowRight />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
}
export default ReservationDatePicker;
