import style from "./SingleCarousel.module.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import constants from "../../utils/consts";

function SingleCarousel(props) {
  return props.images ? (
    <div className={style.singleCarousel}>
      <div className={style.singleCarouselWrapper}>
        <AwesomeSlider
          organicArrows={false}
          buttonContentRight={false}
          buttonContentLeft={false}
          play
          cancelOnInteraction={false}
          interval={6000}
          bullets={true}
          className={style.carouselBackground}
        >
          {props.images &&
            props.images.map((data, index) => (
              <div key={index}>
                <img src={constants.imgPath + data} alt="single" />
              </div>
            ))}
        </AwesomeSlider>
      </div>
    </div>
  ) : (
    ""
  );
}
export default SingleCarousel;
