import React from "react";
import style from "./TopUpCard.module.css";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";

export default function TopUpCard({ id, name, balance, showLink = true }) {
  // console.log("cardId", id);
  return (
    <div className={style.randomCardContainer}>
      <div to={`card/${id}`} className={style.cardBody}>
        {showLink && (
          <Link to="/card/history/1" className={style.settings}>
            <FiSettings color="#fff" size={20} />
          </Link>
        )}
        <h1>{name}</h1>
        <img src="../../assets/images/TopUpCard.svg" alt="card" />
        <p>Bilanci</p>
        <span>{balance} &euro;</span>
        <div className={style.cardBtn}>
          {showLink && (
            <Link to={`/card/refill/${id}`} className="btn">
              Mbush Kartelën
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
