import Box from '@mui/material/Box'
import React from 'react'
import Typography from '@mui/material/Typography'

export default function HyperStrikeCustom({data}) {
    
    
    
    return (
        <Box 
            sx={{
                position: 'relative',
                display: 'grid',
                gridGap: '40px',
                gridTemplateColumns: {
                    sm: '1fr 180px',
                    xs: '1fr'
                },
            }}
        >
            <Box 
            sx={{
                position: {
                    sm: 'relative', 
                    xs: 'absolute' 
                }, 
                top:{
                    sm: 'unset', 
                    xs: '0' 
                },
                left:{
                    sm: 'unset', 
                    xs: '0' 
                },
                opacity:{
                    sm: '1', 
                    xs: '0.3' 
                },
                height:{
                    sm: 'auto', 
                    xs: '100%' 
                },
                width:{
                    sm: 'auto', 
                    xs: '100%' 
                },
            }}
            >
                <Box component={'img'} src={data?.image} alt='CT/TT' sx={{width: '100%', height: '100%'}} />
            </Box>

            <Box sx={{
                display: 'grid', 
                gridTemplateRows: {
                    sm: 'repeat(6, 1fr)', 
                    xs: 'unset'
                },
                gridTemplateColumns: {
                    sm: 'unset', 
                    xs: 'repeat(2, 1fr)'
                },
                justifyItems: 'centerq'
                }}>
                {
                    data?.info?.map((info, index) => (
                        <Box key={index} sx={{textAlign: {sm: 'unset', xs: 'center'}}}>
                            <Typography variant="h6" fontWeight={700} sx={{fontSize: '42px', color: '#00FF29'}}>{info.header}</Typography>
                            <Typography variant="h6" fontWeight={700} sx={{fontSize: '18px'}}>{info.desc}</Typography>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}