// import { useFormik } from "formik";
import React from "react";
import style from "./LoginModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../form/Input";
import Alert from "../general/message/AlertMessage";
import lng from "../../utils/language";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FetchData from "../../api/FetchData";
import { FaGoogle, FaFacebook } from "react-icons/fa";
import { LoginSocialFacebook } from 'reactjs-social-login'
import { loginUsers } from "../../app/auth/Auth";
import WaitingLoader from "../general/loader/WaitingLoader";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";

export default function LoginForm({ form, setForm, isForm }) {
    const { language } = useSelector((state) => state.language);
    const dispatch = useDispatch();
    const location = useLocation();
    const state = location.state;

    const load = useSelector((state) => state.auth.isLoading)
    const { message } = useSelector((state) => state.auth);

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email(lng[language]["invalidMail"])
            .required(lng[language]["required"]),
        password: Yup.string().required(lng[language]["required"]),
    });

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const token = tokenResponse.access_token;
          // fetching userinfo can be done on the client or the server
          const userInfo = await axios
            .get('https://www.googleapis.com/oauth2/v3/userinfo', 
              { headers: { Authorization: `Bearer ${token}` },
            })
          loginWithGoogle(userInfo.data)
        },
      });

    const loginWithGoogle = (data) => {
        FetchData.create('/auth/googleOAuth', data)
            .then(res => {
                dispatch(loginUsers({
                    email: res.data.user.email,
                    password: null,
                    externalType: 'external'
                }));
            })
            .catch(err => {
            })
    }
    return (

        <>
            {
                load ?
                    <WaitingLoader open={load} />
                    :
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={loginSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(loginUsers({
                                email: values.email,
                                password: values.password,
                                externalType: 'normal'
                            }));
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit} className={style.loginForm}>
                                {message && <Alert message={message} type="errorMessages" />}
                                {state?.from && (
                                    <div className={style.warningMessage}>
                                        {lng[language]["pleaseLogin"]}
                                    </div>
                                )}
                                <div className="mb10">
                                    <Input
                                        placeholder={lng[language]["email"]}
                                        name="email"
                                        type="text"
                                        onChange={handleChange}
                                        errorMessage={errors.email}
                                        label={lng[language]["email"]}
                                        touched={touched.email}
                                    />
                                </div>
                                <div className="mb10">
                                    <Input
                                        placeholder={lng[language]["password"]}
                                        label={lng[language]["password"]}
                                        name="password"
                                        type="password"
                                        onChange={handleChange}
                                        errorMessage={errors.password}
                                        touched={touched.password}
                                    />
                                </div>
                                <div className={style.center}>
                                    <Link to="/password" className={style.customLink}>
                                        {lng[language]["activateAcc"]}
                                    </Link>
                                    {!load.showLoader && (
                                        <button type="submit" className="btn">
                                            {lng[language]["login"]}
                                        </button>
                                    )}
                                    {
                                        isForm === true ?
                                            <Box onClick={() => setForm(false)} className={style.linkBtn} fontSize={'13px'} fontWeight={'bold'} sx={{ cursor: 'pointer' }}>
                                                {lng[language]["noAccount"]}
                                            </Box>
                                            :
                                            <Link to="/register" className={style.linkBtn}>
                                                {lng[language]["noAccount"]}
                                            </Link>
                                    }

                                    <Link to="/password">{lng[language]["forgotPsw"]}</Link>
                                </div>
                                <Box>
                                    <Typography sx={{ marginY: 2 }} variant="subtitle1" textAlign={'center'}>OR</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 1, flexDirection: { md: 'row', xs: 'column' } }}>
                                        {/* 
                                */}
                            
                            <Box
                            onClick={login}
                            sx={{
                                        width: '210px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 1,
                                        borderRadius: 2,
                                        backgroundColor: '#ff3b3b',
                                        cursor: 'pointer',
                                        marginBottom: {
                                            xs: '20px',
                                            md: 'auto'
                                            }
                                    }}
                                >
                                    <Box display={'flex'} marginRight={1}>
                                    <FaGoogle />
                                    </Box>
                                    <Box>
                                        {lng[language]['googleBtn']}
                                    </Box>
                                    </Box>    

                                        

                                        <Box>
                                            <LoginSocialFacebook
                                                appId="688053376761349"
                                                onResolve={(response) => {
                                                    FetchData.create('/auth/facebookAuth', response)
                                                        .then(res => {
                                                            dispatch(loginUsers({
                                                                email: res.email,
                                                                password: null,
                                                                externalType: 'external'
                                                            }));
                                                        })
                                                        .catch(err => { })
                                                }}
                                                onReject={(err) => {
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '210px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: 1,
                                                        borderRadius: 2,
                                                        backgroundColor: '#3b3bff',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <Box display={'flex'} marginRight={1}>
                                                        <FaFacebook />
                                                    </Box>
                                                    <Box>
                                                        {lng[language]['facebookBtn']}
                                                    </Box>
                                                </Box>
                                            </LoginSocialFacebook>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography variant="body2" textAlign={'center'} sx={{ marginTop: '20px', color: 'gray' }}>
                                            {lng[language]['otherLoginTerms']}
                                        </Typography>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
            }
            <Box>
            </Box>
            </>
    );
}