const constants = {
    imgPath: 'https://hyper-active.com/images/',
    imgPath2: 'https://hyper-active.com/',
    doc: 'https://hyper-active.com/docs/',
    reservationImgPath: 'https://hyper-active.com/assets/reservation/',

    // VR
    vrGames: 'https://hyper-active.com/assets/images/games/vr/games/',
    vrGallery: 'https://hyper-active.com/assets/images/games/vr/gallery/',
    
    // LASER TAG 
    laserTagGallery: 'https://hyper-active.com/assets/images/games/lasertag/gallery/',

    // ARCADE
    arcadeGallery: 'https://hyper-active.com/assets/images/games/arcade/gallery/',

    // KARTING
    kartingGallery: 'https://hyper-active.com/assets/images/games/karting/gallery/',
    kartingBanners: 'https://hyper-active.com/assets/images/games/karting/banners/',
    
    // LODRA
    lodraGallery: 'https://hyper-active.com/assets/images/games/lasertag/gallery/',
    
    //ESCAPEROOM
    escapeRoomGallery: 'https://hyper-active.com/assets/images/games/lasertag/gallery/',
    
    // BOWLING
    bowlingGallery: 'https://hyper-active.com/assets/images/games/lasertag/gallery/',
    
    // AXE THROWING
    axeThrowingGallery: 'https://hyper-active.com/assets/images/games/axe/gallery/',
    
    // GOOGLE OAUTH
    clientId: '487988760-uka6t680fihhl0d1cc2nnq5vl6cg914c.apps.googleusercontent.com ',
    clientSecret: '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'
}

export default constants;