import style from "./Reservation.module.css";
import Grid from "../../../layout/grid/Grid";
import ShoppingCartList from "../../../layout/reservation/ShoppingCartList";
import ShoppingCart from "../../../layout/reservation/ShoppingCart";
import { useSelector } from "react-redux";

function Checkout() {
  const { items } = useSelector((state) => state.cart);
  return (
    <div className="container pbt100">
      {items?.length > 0 ? (
        <Grid no={"Custom2"}>
          {
            <div className={style.reservationLayout}>
              <ShoppingCartList />
            </div>
          }
          <ShoppingCart />
        </Grid>
      ) : (
        <div className={style.noItems}>
          <ShoppingCart />
        </div>
      )}
    </div>
  );
}
export default Checkout;
