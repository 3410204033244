import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

export default function WaitingLoader(props) {
    const { open } = props;
    return (
        <>
        <Box sx={{height: '100vh'}}></Box>
        <Backdrop
            sx={{ zIndex: 9, color: '#000', width: '100%', height: '100vh'}}
            open={open}
        >
            <Box sx={{width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box component={'img'} src='../assets/images/logo.png' sx={{position: 'fixed', zIndex: 2, width: {sm: '350px', xs: '200px'}}} />
                <LinearProgress sx={{width: '100%', backgroundColor: '#9e49fa' }} />
            </Box>
        </Backdrop>
        </>
    )
}