import React from 'react'
import style from './CarouselGallery.module.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AiFillCloseCircle } from 'react-icons/ai'
import constants from '../../utils/consts';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
const image = {zIndex: 2, objectFit: 'contain', position: 'relative', height: {sm: '80%'}}
const closeBtn = {position: 'absolute', top: '25px', right: '30px', zIndex: 3, cursor: 'pointer', fontSize: '30px', color: '#FFF'}
const navBtn = {
    backgroundColor: '#FFF',
    color: '#000', 
    maxHeight: '60px',
    height: '60px',
    width: '60px',
    minWidth: 'unset',
    borderRadius: '80px',
    '&:hover': {
        backgroundColor: '#321eff',
        color: '#FFF'
    }}

export default function CarouselGallery({gallery, radius, imgHeight, itemIndeL, itemIndexM, buttonShow, ignorePath}) {
    
    const [open, setOpen] = React.useState(false);
    const [currImg, setCurrImg] = React.useState()

    const responsive = {
        0: { items: 1 },
        801: { items: 2 },
        1024: { items: itemIndexM ? itemIndexM :3 },
        1280: { items: itemIndeL ? itemIndeL : 4 },
    };

    const bigImage = (
        <Box 
            sx={{
                position: 'fixed', 
                width: '100%', 
                top: 0,
                left: 0, 
                height: '100%',
                display: open === true ? 'flex' : 'none', 
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2
            }}
        >
            <Box onClick={() => {setOpen(false); document.body.classList.remove("carousel")}} sx={closeBtn}><AiFillCloseCircle /></Box>
            <Box component={'img'} src={currImg?.img} height={'100%'} width={'100%'} sx={image} />
            <Box sx={{
                display: 'flex',
                width: '90%',
                zIndex: 2,
                position: 'absolute',
                justifyContent: 'space-between',

            }}>
                <Button variant='contained' onClick={() => setCurrImg({img: ignorePath === true ? gallery[currImg?.index-1] : constants.imgPath+gallery[currImg?.index-1], index: currImg?.index-1})} 
                    sx={navBtn} 
                    disabled={currImg?.index === 0 ? true: false}
                >
                    <FaArrowLeft />
                </Button>
                <Button variant='contained' onClick={() => setCurrImg({img: ignorePath === true ? gallery[currImg?.index+1] : constants.imgPath+gallery[currImg?.index+1], index: currImg?.index+1})} disabled={currImg?.index === gallery.length-1 ? true: false } sx={navBtn}><FaArrowRight /></Button>
            </Box>
            <Box sx={{position: 'absolute', background: '#000', opacity: .5, top: 0, left: 0, height: '100%', width: '100%'}}></Box>
        </Box>
    )

    const images = gallery && gallery.map((data, index) => (
        <Box 
            key={index} 
            onClick={e => {
                setOpen(true); 
                document.body.classList.add("carousel"); 
                setCurrImg({
                    img: ignorePath === true ? data : constants.imgPath+data,
                    index: index
                });
            }} 
            data-value={index + 1} 
            className={style.item} 
            sx={{
                borderRadius: radius ? radius : 0, 
                overflow: 'hidden', 
                height: imgHeight ? imgHeight : '440px', 
                cursor: 'pointer'
            }}
        >
            <Box component={'img'} src={ignorePath === true ? data : constants.imgPath+data} alt={`carouserImg:${index}`} />
        </Box>
    ));

    const carousel = React.useRef(null);
    return (
        <Box className={style.carouselGalleryContainer} sx={{position: 'relative'}}>
            <AliceCarousel
                disableDotsControls
                disableButtonsControls
                touchTracking={true}
                autoHeight
                autoPlay={true}
                autoPlayInterval={3000}
                infinite={true}
                items={images}
                ref={carousel}
                responsive={responsive}
                controlsStrategy="default"
            />
            <nav key="nav" className="b-refs-navs">
                {images.map((item, i) => {
                    return <span key={i} key-value={item} />;
                })}
            </nav>
            {
                !buttonShow ?
                // <Box key="btns" className="b-refs-buttons" sx={{margin: 'auto', display: 'flex', justifyContent: 'space-between',width: '200px', marginY: 3}}>
                //     <Button variant='contained' onClick={(e) => carousel.current.slidePrev(e)} sx={navBtn}><FaArrowLeft /></Button>
                //     <Button variant='contained' onClick={(e) => carousel.current.slideNext(e)} sx={navBtn}><FaArrowRight /></Button>
                // </Box>
                ''
            :
                ''
            }
            {open && bigImage}
        </Box>
    )
}