import React from "react";
import { Link } from "react-router-dom";
import style from "./ImageTextCard.module.css";
import lng from "../../utils/language";
import { AiFillCaretRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import constants from "../../utils/consts";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'

export default function ImageTextCard({ data, specialStyling, txtContainerStyling, reservationType, costumButton }) {
  const { language } = useSelector((state) => state.language);
  return (
    <Box className={style.eventBannerContainer} sx={[specialStyling && specialStyling, {minHeight: {md: '378px', xs: 'unset'}}]}>
      <div className={style.eventBannerImage}>
        <img src={data.fullPath ? data.images : constants.imgPath+data.images} alt={data.images} />
      </div>
      <Box 
            className={style.eventBannerInfo} 
            sx={[{
                width: {
                    md: '50%',
                    xs: 1
                },
                display: 'flex',
                padding: {
                    lg: '20px 50px',
                    xs: '20px' 
                },
                flexDirection: 'column',
                zIndex: 1,
                maxWidth: {
                    md: 'unset',
                    xs: 1
                },
                justifyContent: data.body_desc ? 'unset' : 'space-evenly'
            }, txtContainerStyling && txtContainerStyling]}
      >
        {data.body_header && 
        <Typography 
          variant='h1'
          sx={
            data.bodyHeaderStyle ?
            data.bodyHeaderStyle :
            {
            lineHeight: '1.2',
            letterSpacing: '1.2px',
            fontWeight: '600',
            fontSize: '30px',
            margin: '10px 0 0 0 !important',
          }}
        >
          {data.body_header}
        </Typography>
        }
        {data.small_header && 
          <Typography 
            variant='p'
            component={'p'}
            sx={{
              marginTop: '20px',
              fontSize: '18px',
              fontWeight: 300,
              color: '#e4e4e4'
            }}
          >
            {data.small_header}
          </Typography>
        }
        {data.body_desc && 
          <Typography
            variant='p'
            component={'p'}
            sx={{
                marginTop: '20px',
              fontSize: '18px',
              fontWeight: 300,
              color: '#e4e4e4',
              marginBottom: '25px',
              whiteSpace: 'pre-line'
            }}
          >
            {data.body_desc}
          </Typography>
        }
        {data.buttonText && (
          <Link to={"/reservation"} className="btn">
            {data.buttonText}
          </Link>
        )}
        {data.link && (
            reservationType ?
            <Button 
                LinkComponent={Link}
                to={data.link}
                variant="contained" 
                sx={costumButton}
            >
                {lng[language]['bookNow']}
            </Button>
            :
            <Button
            sx={{
                padding: '10px',
                marginBottom: '20px',
                '&:hover': {
                    borderColor: '#F03C44',
                    backgroundColor:'#F03C44',
                    'svg': {
                        fill: '#FFF'
                    }
                }
            }}
            className={style.linkBtn}
            href={data.link}
            target="_blank"
            rel="noreferrer"
          >
            {lng[language]["watchVideo"]}{" "}
            <span>
              <AiFillCaretRight />
            </span>
          </Button>
        )}
      </Box>
    </Box>
  );
}
