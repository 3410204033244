import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


export default function PaymentCards({data, type}) {
    
    return (
        <Box>
            {
                type === "payment" &&
                <Box sx={data.mainContainer}>
                    {
                        data.new &&
                        <Typography variant="h6" className='compNew'>
                            {data.newTxt}
                        </Typography>
                    }
                    <Box sx={data.textContainer}>
                        {
                            data.header &&
                            <Typography variant="h1" className='compHeader'>
                                {data.header}
                            </Typography>
                        }
                        {
                            data.secondHeader &&
                            <Typography variant="h1" className='compSecondHeader'>
                                {data.secondHeader}
                            </Typography>
                            
                        }
                        {
                            data.title &&
                            <Typography variant="h1" className='compTitle'>
                                {data.title}
                            </Typography>
                        }
                        <Box className='compDesc'>
                            <Typography variant="h1" className='money'>€</Typography>
                            {
                                data.desc &&
                                <Typography variant="body1" className='desc'>
                                    {data.desc}
                                </Typography>
                            }
                            <Typography variant="body1" className='cents'>99</Typography>
                            <Typography variant="body1" className='forKids'>{data.forWho}</Typography>
                        </Box>
                    </Box>
                    <Box className={'compBtn'}>
                        <Button LinkComponent={Link} to={data.link} variant='contained'>
                            {data.btnText}
                        </Button>
                    </Box>
                </Box>
            }
            {
                type === "show" &&
                <Box sx={data.mainContainer}>
                    <Box sx={data.imageContainer}>
                        <Box component={'img'} src={data.imgUrl} alt={data.imgAlt} />
                    </Box>
                    <Box sx={data.textContainer}>
                        {
                            data.header &&
                            <Typography variant="h1" className='compHeader'>
                                {data.header}
                            </Typography>
                        }
                        <Button LinkComponent={Link} to={data.link} variant='contained'>
                            {data.btnText}
                        </Button>
                    </Box>

                </Box>
            }
        </Box>
    )
}