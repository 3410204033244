import { Link } from "react-router-dom";
import Grid from "../../../layout/grid/Grid";
import style from "./RadiantBanner.module.css";
import { Box } from "@mui/material";

function RadiantBanner(props) {
  return (
    <Box className={style.radiantBanner} sx={props.mainContainer}>
      <Grid no={"2"}>
        <Box className={style.radiantInfo} sx={props.textContainer}>
          {props.header && <h1>{props.header}</h1>}
          {props.text && <p>{props.text}</p>}
          {
           props.url &&
           <Box 
            component={Link} 
            // to="/activities/rules"
            to={props.url}
            sx={{
              border: 'solid 2px #FFF !important',
              color: '#FFF !important'
            }}
          >{props.urlTxt}</Box>
          }
        </Box>
        <Box className={style.radiantImage} sx={props.imageContainer}>
          {props.imgUrl && <img src={props.imgUrl} alt={props.alt} />}
        </Box>
      </Grid>
    </Box>
  );
}
export default RadiantBanner;
