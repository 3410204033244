import React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
// import constants from '../../../utils/consts';
import style from "./Activities.module.css";
import { hyperstrike } from '../../../utils/gameLng';
import Videos from '../../../components/general/videos/Videos';
import CarouselGallery from '../../../components/carousel/CarouselGallery';
import Text from '../../../components/text/Text';
import PartyBanner from '../../../components/general/banner/PartyBanner';
import HyperStrikeCustom from '../../../components/general/HyperStrikeCustom';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AppBanner from '../../../components/general/banner/AppBanner';
import ReserveText from '../../../components/reservation/ReserveText';
import TextWrapper from '../../../components/general/text/TextWrapper';
import RandomCards from '../../../components/card/RandomCards';
import lng from '../../../utils/language';
import Grid from '../../../layout/grid/Grid';
import ImageTextCard from '../../../components/card/ImageTextCard';
export default function HyperStrike() {

    const { language } = useSelector((state) => state.language);

    React.useEffect(() => {
        // load();
        // eslint-disable-next-line
    }, [language]);

    const containerSpacing = {
        paddingY: '80px'
    }

    const gallery = [
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0011_Layer-0.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0000_DSC03167.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0001_DSC03165.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0002_DSC03161.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0003_DSC03155.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0004_DSC03143.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0005_DSC03140.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0006_DSC03138.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0007_DSC03136.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0008_DSC03131.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0009_DSC03128.jpg',
        'https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0010_DSC03127.jpg'
    ]

    const custom = {
        image: 'https://hyper-active.com/imageTests/hperstrike/compressed/HyperStrike-Map.png',
        info: [
            {
                header: '500m',
                desc: hyperstrike[language]['arenaRunWay']
            },
            {
                header: '24',
                desc: hyperstrike[language]['arenaPlayers']
            },
            {
                header: '15min',
                desc: hyperstrike[language]['arenaBattle']
            },
            {
                header: '48',
                desc: hyperstrike[language]['arenaMatches']
            },
            {
                header: <AllInclusiveIcon fontSize="large" />,
                desc: hyperstrike[language]['arenaAdrenaline']
            },
        ]
    }

    const cards = {
        eng: [
            {
                body_header: 'Team Building',
                bodyHeaderStyle: {
                    color: '#FFF',
                    marginBottom: '20px !important'
                },
                body_desc: `Laser Tag is an exceptional way to relax and bond with your colleagues. 

                Experience the best team-building activity, getting to know your coworkers through the exciting game of Laser Tag`,
                images: 'https://hyper-active.com/images/../imageTests/laser tag/compressed/item card//65b791b20bd52.png',
                fullPath: true
            },
            {
                body_header: 'HyperActive Sports Bar',
                bodyHeaderStyle: {
                    color: '#FFF',
                    marginBottom: '20px !important'
                },
                body_desc: `After the battle, indulge in a delightful meal and drinks at HyperActive Sports Bar. 

                Explore our menu, brimming with delicious options crafted to satisfy every craving.`,
                images: 'https://hyper-active.com/images/../imageTests/laser tag/compressed/item card/65b791c07eba7.png',
                fullPath: true
            },
            {
                body_header: 'Gift Card',
                bodyHeaderStyle: {
                    color: '#FFF',
                    marginBottom: '20px !important'
                },
                body_desc: `The best gifts are experiences, and with the Hyper Gift Card, you can give your loved ones an adrenaline-packed adventure 

                A gift that brings joy to every occasion`,
                images: 'https://hyper-active.com/images/../imageTests/laser tag/compressed/item card/65b791ca6bd84.png',
                fullPath: true
            }
        ],
        alb: [
            {
                body_header: 'Team Building',
                bodyHeaderStyle: {
                    color: '#FFF',
                    marginBottom: '20px !important'
                },
                body_desc: `Laser Tag është një mënyrë e shkëlqyer për t'u çlodhur dhe për të njohur kolegët tuaj.`,
                images: 'https://hyper-active.com/images/../imageTests/laser tag/compressed/item card//65b791b20bd52.png',
                fullPath: true
            },
            {
                body_header: 'HyperActive Sports Bar',
                bodyHeaderStyle: {
                    color: '#FFF',
                    marginBottom: '20px !important'
                },
                body_desc: `Pas betesë, ju mund ta trajtoni veten me ushqim dhe pije në HyperActive Sports Bar. 

                Menyja jonë është e mbushur me opsione të shijshme që e kënaqin çdo dëshirë.`,
                images: 'https://hyper-active.com/images/../imageTests/laser tag/compressed/item card/65b791c07eba7.png',
                fullPath: true
            },
            {
                body_header: 'Gift Card',
                bodyHeaderStyle: {
                    color: '#FFF',
                    marginBottom: '20px !important'
                },
                body_desc: `Dhuratat më të mira janë eksperiencat, e me Hyper Gift Card ju mund ti dhuroni personit të dashur nje ekperience plot adrenalinë🚀 
                
                Dhuratë që ju bën të lumtur çdo event.`,
                images: 'https://hyper-active.com/images/../imageTests/laser tag/compressed/item card/65b791ca6bd84.png',
                fullPath: true
            }
        ]
    }

    return (
        <Box
            sx={{
                color: '#FFF'
            }}
        >

            <Box className={style.container} sx={{ overflow: 'visible' }}>
                <ActivitiesBanner
                    title={hyperstrike[language]['bannerTitle']}
                    desc={hyperstrike[language]['bannerDesc']}
                    image={'https://hyper-active.com/imageTests/hperstrike/compressed/hyperstrikebanner.svg'}
                    linkPrice="karting"
                    vidId="SA4rWKB2cK4"
                    id={'/reservation/1133114'}
                    prices={true}
                    changeColor={'#00ee26'}
                />

                <Box
                    sx={{
                        height: {
                            md: '500px',
                            sm: '416px',
                            xs: '209px'
                        },
                        position: 'relative'
                    }}
                >
                    <Box sx={{ position: 'absolute', top: '-17%', right: '50px', width: '30%', height: '50%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/upperFirstVid.svg' />
                    <Box sx={{ position: 'absolute', top: '-30px', right: '-10%', width: '30%', height: '50%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/multiLine.png' />

                    <Box sx={{ position: 'absolute', zIndex: 222, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                    <Box sx={{ zIndex: 10, width: '100%', height: '100%', position: 'relative' }}>
                        <Videos id={'kcB85-S0Y1k'} />
                    </Box>

                    <Box sx={{ position: 'absolute', bottom: '-30px', left: '-10%', width: '30%', height: '50%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/multiLine.png' />
                    <Box sx={{ position: 'absolute', bottom: '-17%', left: '50px', width: '30%', height: '50%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/lowerFirstVid.svg' />
                </Box>

            </Box>

            {/* GALLERY */}
            <Box>
                <Box sx={[containerSpacing, { marginY: '100px' }]}>
                    <CarouselGallery gallery={gallery} radius={5} ignorePath={true} />
                </Box>
            </Box>

            <Box className={style.container}>
                {/* ARENA EPIKE E LEGJENDAVE */}
                <Box sx={containerSpacing}>
                    <Text
                        containerStyling={{
                            marginBottom: '50px'
                        }}
                        descStyling={{
                            width: {
                                md: '700px',
                                xs: '100%'
                            },
                            marginX: 'auto',
                            textAlign: 'center'
                        }}
                        header={hyperstrike[language]['arenaHeader']}
                        desc={hyperstrike[language]['arenaDesc']}
                    />
                    <Box>
                        <HyperStrikeCustom data={custom} />
                    </Box>
                </Box>

                <Box sx={containerSpacing}>
                    <Text
                        containerStyling={{
                            marginBottom: '50px'
                        }}
                        descStyling={{
                            width: {
                                md: '700px',
                                xs: '100%'
                            },
                            marginX: 'auto',
                            textAlign: 'center'
                        }}
                        header={hyperstrike[language]['gunsHeader']}
                        desc={hyperstrike[language]['gunsDesc']}
                    />
                    <Box>
                        <Box component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/Arma.svg' height={"100%"} width={"100%"} />
                    </Box>
                </Box>

            </Box>

            {/* BIRTHDAY BANNER */}
            <Box>
                <Box sx={{ marginY: '100px' }}>
                    <PartyBanner
                        header={hyperstrike[language]['hyperBirthdayHeader']}
                        desc={hyperstrike[language]['hyperBirthdayDescBig']}
                        secondHeader={hyperstrike[language]['hyperBirthdayDescBigSH']}
                        buttonTxt={hyperstrike[language]['bookNow']}
                        link={"/activities/party"}
                        imageUrl={"https://hyper-active.com/assets/images/new_main_banner.png"}
                        containerStyle={{
                            width: { sm: "454px", xs: '90%' },
                            color: "#4600BE",
                            padding: 3,
                            borderRadius: "20px",
                            display: "grid",
                            gridTemplateRows: "0fr 0fr 0fr 0fr",
                            rowGap: "20px",
                            '.secondHeader': {
                                fontWeight: 'bold'
                            }
                        }}
                        imgStyling={{
                            height: {
                                sm: 1,
                                xs: '110%'
                            }
                        }}
                        headerStyle={{
                            fontWeight: 'bold',
                            fontSize: '40px'
                        }}
                        buttonStyle={{
                            height: 'max-content',
                            color: '#0AD73F',
                            backgroundColor: '#4600BE',
                            border: 'solid 1px #4600BE',
                            paddingY: '20px',
                            paddingX: '60px',
                            borderRadius: '40px',
                            marginTop: {
                                sm: '50px',
                                xs: '20px'
                            },
                            '&:hover': {
                                color: '#4600BE',
                                borderColor: '#0AD73F',
                                backgroundColor: '#0AD73F',
                            }
                        }}
                    />
                </Box>
                {/* VIDEO */}

            </Box>

            <Box className={style.container} sx={{ overflow: 'visible', paddingTop: '50px' }}>
                <Text
                    containerStyling={{
                        marginBottom: '50px'
                    }}
                    header={hyperstrike[language]['howItsPlayed']}
                />
                <Box
                    sx={{
                        height: {
                            md: '500px',
                            sm: '416px',
                            xs: '209px'
                        },
                        position: 'relative'
                    }}
                >
                    {/* ARROW */}
                    <Box sx={{ position: 'absolute', display:{sm: 'block', xs: 'none'}, top: '-30%', right: '-30%', width: '30%', height: '30%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/arrowDown.png' />
                    {/* LINES */}
                    <Box sx={{ position: 'absolute', top: '-30px', left: '-10%', width: '30%', height: '50%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/multiLine.png' />

                    <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                    <Box sx={{ zIndex: 10, width: '100%', height: '100%', position: 'relative' }}>
                        <Videos id={'oqwUqT6zGgo'} />
                    </Box>
                    {/* LINES */}
                    <Box sx={{ position: 'absolute', bottom: '-30px', right: '-10%', width: '30%', height: '50%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/multiLine.png' />
                    {/* ARROW */}
                    <Box sx={{ position: 'absolute', display:{sm: 'block', xs: 'none'}, bottom: '-20%', left: '-20%', width: '20%', height: '20%', zIndex: 3 }} component={'img'} src='https://hyper-active.com/imageTests/hperstrike/compressed/arrowUp.png' />
                </Box>
            </Box>

            <Box>
                {/* BIRTHDAY BANNER */}
                <Box sx={{ marginBottom: '100px', paddingTop: '15%' }}>
                    <PartyBanner
                        header={"Let's Heat That Laser Gun"}
                        desc={'Ju rekomandojmë rezervimin për çdo atraksion që të mos pritni në rend për një përvojë të jashtëzakonshme.'}
                        // secondHeader={hyperstrike[language]['hyperBirthdayDescBigSH']}
                        buttonTxt={hyperstrike[language]['bookNow']}
                        link={"/reservation/1133114"}
                        imageUrl={"https://hyper-active.com/imageTests/hperstrike/compressed/DSC03126_0004_DSC03143.jpg"}
                        containerStyle={{
                            width: { sm: "614px", xs: '90%' },
                            textAlign: 'center',
                            marginX: 'auto',
                            color: "#FFF",
                            padding: 3,
                            borderRadius: "20px",
                            display: "grid",
                            gridTemplateRows: "0fr 0fr 0fr 0fr",
                            rowGap: "20px",
                            '.secondHeader': {
                                fontWeight: 'bold'
                            }
                        }}
                        imgStyling={{
                            height: {
                                sm: 1,
                                xs: '110%'
                            }
                        }}
                        headerStyle={{
                            fontWeight: 'bold',
                            fontSize: '40px'
                        }}
                        buttonStyle={{
                            margin: 'auto',
                            height: 'max-content',
                            color: '#4600BE',
                            backgroundColor: '#00ff29',
                            border: 'solid 1px #00ff29',
                            paddingY: '15px',
                            paddingX: '60px',
                            borderRadius: '40px',
                            '&:hover': {
                                borderColor: '#4600BE',
                                backgroundColor: '#4600BE',
                                color: '#00ff29'
                            }
                        }}
                    />
                </Box>
            </Box>

            <Box className={['container']}>

                <Box sx={containerSpacing}>
                    <Grid no={"ReverseSingle"}>
                        {cards[language].map((i, j) => {
                            return <ImageTextCard data={i} key={j} specialStyling={{
                                backgroundColor: 'transparent'
                            }}
                                txtContainerStyling={{
                                    marginY: 'auto',
                                    '& h1': {
                                        fontSize: '42px',
                                        marginBottom: '0 !important'
                                    },
                                    '& p': {
                                        fontSize: '14px'
                                    }
                                }} />;
                        })}
                    </Grid>
                </Box>

                <Box sx={containerSpacing}>
                    <AppBanner
                        header={lng[language]['hyperAppHeader']}
                        desc={lng[language]['hyperAppDesc']}
                        imgUrl={'../images/6582cf3c6d165.webp'}
                    />
                </Box>

                <ReserveText
                    header={hyperstrike[language]['rezRace']}
                    desc={hyperstrike[language]['rezDesc']}
                    id={1133114}
                />

                <Box paddingBottom={5} sx={containerSpacing}>
                    <Box sx={{ 'h1': { textAlign: 'center' } }}>
                        <TextWrapper title={lng[language]['gameSuggestion']} />
                    </Box>
                    <RandomCards removeGame='HyperStrike' />
                </Box>
            </Box>
        </Box>
    )
}