import style from "./TextWrapper.module.css";

function TextWrapper(props) {
  return (
    <div className={style.textWrapper}>
      <h1>{props.title}</h1>
      {props.desc && <p>{props.desc}</p>}
    </div>
  );
}
export default TextWrapper;
