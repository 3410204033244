import { Link } from "react-router-dom";
import style from "./Dashboard.module.css";
function OptionList({ active }) {
  return (
    <div className="container">
      <ul className={style.optionUser}>
        <li>
          <Link
            to="/user/reservation"
            className={active === "reservation" ? style.active : style.deactive}
          >
            Rezervimet
          </Link>
        </li>
        <li>
          <Link
            to="/user/profile"
            className={active === "profile" ? style.active : style.deactive}
          >
            Profili Im
          </Link>
        </li>
      </ul>
    </div>
  );
}
export default OptionList;
