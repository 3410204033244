import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react'
// import { Link } from 'react-router-dom'

export default function RechargeBanner({
    // title, 
    image, 
    alt, 
    // url, 
    btnText, 
    click
}) {
  return (
    <Box sx={{height: '100vh', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridTemplateRows: '1fr 1fr', position: 'relative'}}>
        <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%'
        }}>
            <Box 
                component={'img'} 
                src={image}
                alt={alt}
                sx={{width: '100%', objectFit: 'cover', height: '100%'}}
            />
        </Box>
        <Box sx={{
            gridRow: 2,
            gridRowSpan: 1,
            gridColumn: 2,
            gridColumnSpan: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Button 
                sx={{
                    padding: '20px 60px', 
                    borderRadius: '40px', 
                    backgroundColor: '#321eff',
                    '&:hover': {
                        background: '#FFF', 
                        color: '#321eff'
                    }
                }} 
                variant='contained' 
                onClick={e => click(e)}
                // LinkComponent={Link} 
                // to={url}
                >
                    {btnText}
                </Button>        
        </Box>
    </Box>
  )
}