import Box from "@mui/material/Box";
import React from 'react'

export default function GridBox({
    children,
    gap,
    justifyContent,
    alignItems,
    gridTemplateColumns,
    gridTemplateRows,
}) {
  return (
    <Box
        display={'grid'}
        sx={{
            gap: gap ? gap : 0,
            justifyContent: justifyContent ? justifyContent : 'unset',
            alignItems: alignItems ? alignItems : 'unset',
            gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns : 'unset',
            gridTemplateRows: gridTemplateRows ? gridTemplateRows : 'unset'
        }}
    >
        {children}
    </Box>
  )
}