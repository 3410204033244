import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'




export default function FaceBookTerms() {
  return (
    <Box sx={{color: '#FFF', paddingTop: 5, marginX: 'auto', width: '70%'}}>

        <Typography variant="h4" sx={{paddingBottom: 3, textAlign: 'center'}}>How to remove your data</Typography>

        <Typography variant="body1" gutterBottom>
            HyperActive uses a facebook login app to authenticate our users. HyperActive does not save your personal data in our server.
        </Typography>
        <Typography variant="subtitle1"gutterBottom>
            According to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.
        </Typography>
        <Typography variant="subtitle1" sx={{whiteSpace: 'pre-line'}}>
            If you want to delete your activities for HyperActive'S facebook login app, you can remove your information by following these steps:<br />
            Go to your Facebook Account’s “Settings & Privacy”.<br />
            Click “Settings”<br />
            Then click “Security and Login”.<br />
            Look for “Apps and Websites” and you will see all of the apps and websites you have linked with your Facebook account.<br />
            Search and Click “HyperActive” in the search bar.<br />
            Scroll and click “Remove”.
        </Typography>‍
‍


    </Box>
  )
}