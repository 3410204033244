import React, { useEffect, useState } from "react";
import { api } from "../../../api/config";
import style from "./Reservation.module.css";
// import loader from "../../../utils/lotties/73060-blue-search-not-found.json";
import Loader from "../../../components/general/loader/Loader";
import LottieMessage from "../../../components/general/message/LottieMessage";
import { Link } from "react-router-dom";
import OptionList from "../../../layout/user/dashboard/OptionsList";

function MyReservation() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //GET USER ONLINE RESERVATION
    api
      .get("reservation/getUserReservation")
      .then((response) => {
        //Check message type
        if (response.data.reservations.length > 0) {
          setReservations(response.data.reservations);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <div className="container ">
          <>
            <OptionList active="reservation" />
            {reservations.length > 0 ? (
              <div className={`${style.reservationBoxes} `}>
                <h1>Historiku i rezervimeve</h1>
                {reservations.map((i, j) => {
                  return (
                    <div className={style.reservationWrapper} key={j}>
                      {i.products.split(", ").map((name, index) => (
                        <>
                          <div className={style.reservationDetails}>
                            <div>
                              <h2>{name}</h2>
                              <span> {i.date.split(", ")[index]}</span>
                            </div>
                            <div>
                              <h2>{i.price.split(", ")[index]}&euro;</h2>
                            </div>
                          </div>
                          <div className={style.reservationCharge}>
                            <span>
                              {
                                JSON.parse(i.charge.split(", ")[index])[
                                  "players"
                                ]
                              }
                              xlojtarë
                            </span>
                            <span>
                              {JSON.parse(i.charge.split(", ")[index])["time"]}{" "}
                              min
                            </span>
                          </div>
                        </>
                      ))}

                      <div className={style.reservationDetailsFooter}>
                        <h2>Total</h2>
                        <h1>{i.amount} &euro;</h1>
                      </div>
                    </div>
                  );
                })}
                <Link to="/reservation" className="btn mt20">
                  Rezervo Online!
                </Link>
              </div>
            ) : (
              <LottieMessage
                text="Ju nuk keni asnjë rezervim aktiv!"
                type="noFound"
                linkText="Vazhdoni tek rezervimet"
                linkTo="/reservation"
              />
            )}
          </>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default MyReservation;
