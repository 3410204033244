import { useEffect, useState } from "react";
import { api } from "../../../../api/config";
import { useLocation, useParams } from "react-router-dom";
import style from "./PickSlots.module.css";
import moment from "moment/moment";
import ReservationSlots from "./ReservationSlots";
import ReservationDatePicker from "../date/ReservationDatePicker";
import { useSelector } from "react-redux";
import { MdOutlineArrowBackIos } from "react-icons/md";
import KartingParams from "../params/KartingParams";
import lng from "../../../../utils/language";
import Box from '@mui/material/Box';
import ReservationLoader from "../../../../components/general/loader/ReservationLoader";

function PickSlotsKarting({
    setReservationInfo,
    reservationInfo,
    updatePage,
    reservationParams,
    type,
}) {
    const { language } = useSelector((state) => state.language);
    const { items } = useSelector((state) => state.cart);
    const location = useLocation();
    const state = location.state;
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [miniLoader, setMiniLoader] = useState(false);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState(-1);
    const [kartingType, setKartingType] = useState("std");

    useEffect(() => {
        setReservationInfo({ ...reservationInfo, time: "10" });
        // eslint-disable-next-line
    }, []);


    const getSlots = (date = null) => {

        api.post("reservation/slots", {
            id: id,
            date: date ? moment(date).format("YYYY-MM-DD") : reservationInfo.selectedDate,
            adult: reservationInfo.adult,
            children: reservationInfo.children,
            otherParams: reservationInfo.otherParams,
            type: type,
            time: reservationInfo.time,
            category: kartingType,
        })
            .then((res) => {
                if (res.data.success) {
                    setReservationInfo((prevState, props) => ({
                        ...prevState,
                        slots: res.data.slots,
                    }));
                    setLoading(false);
                    setMiniLoader(false);
                }
            })
            .catch((error) => { });
    };

    const changeDate = (date) => {
        var formattedDate = moment(date).format("YYYY-MM-DD");
        setReservationInfo((prevState, props) => ({
            ...prevState,
            selectedDate: formattedDate,
        }));
    };

    useEffect(() => {
        if (reservationInfo.selectedDate !== null) {
            (async () => {
                setMiniLoader(true);
                getSlots(null, true);
            })();
        }
        // eslint-disable-next-line
    }, [reservationInfo.selectedDate]);

    useEffect(() => {
        (async () => {
            setMiniLoader(true);
            getSlots(null, true);
        })();
        // eslint-disable-next-line
    }, [kartingType]);


    const updateStep = (nextPage = true) => {
        if (nextPage) {
            //Validate
            if (items.length <= 0) {
                setError(true);
                window.scrollTo(0, 0);
            } else {
                setError(false);
                updatePage();
            }
        } else updatePage(false);
    };

    return (
        <>
            {
                loading
                    ?
                    (<ReservationLoader />)
                    :
                    (
                        <>
                        <Box className={style.reservationLayout}>
                                <div className={style.border}>
                                    <span onClick={() => updateStep(false)}>
                                        <MdOutlineArrowBackIos />
                                    </span>
                                    <div>
                                        <h2>{state?.name}</h2>
                                        <p>{lng[language]["pickSlot"]}</p>
                                    </div>
                                </div>
                                <ReservationDatePicker
                                    changeDate={changeDate}
                                    selectedDate={reservationInfo.selectedDate}
                                />
                                {error && (
                                    <p className="errorMsg">
                                        Vendosni të paktën një aktivitet në shportë!
                                    </p>
                                )}
                            </Box>
                            <KartingParams
                                reservationInfo={reservationInfo}
                                setReservationInfo={setReservationInfo}
                                activeReservationInfo={reservationInfo}
                                info={info}
                                setKartingType={setKartingType}
                                kartingType={kartingType}
                            />
                            <ReservationSlots
                                loader={miniLoader}
                                reservationInfo={reservationInfo}
                                slots={reservationInfo.slots}
                                getSlots={getSlots}
                                reservationParams={reservationParams}
                                setInfo={setInfo}
                                kartingType={kartingType}
                            />
                        </>
                    )}
        </>
    );
}
export default PickSlotsKarting;
