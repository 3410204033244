import React from "react";
import Box from '@mui/material/Box'
import YouTube from 'react-youtube';

export default function Videos(props) {
    return (
        <Box
            component={YouTube}
            opts={{
                height: '100%',
                width: '100%',
                playerVars: {
                    playlist: props.id,
                    loop: 1,
                    autoplay: 1,
                    controls: 0,
                    mute: 1,
                    enablejsapi: 1,
                    disablekb: 1,
                    origin: 'https://hyper-active.com/'
                },  
            }}
            sx={{
                width: 1,
                height: 1,
                borderRadius: '20px',
                overflow: 'hidden'
            }}
            videoId={props.id}
            title={'Youtube Video Player'}
        />
    );
}
