import React from 'react'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function SkeletonLoader({data, containerStyling, styling}) {
    return (
    <Box sx={[{width: '100%'}, containerStyling ? containerStyling : {}]}>
        {
            data?.type === 'text' &&
            data?.map((_, index) => (
                <Skeleton key={index} className={`textSkeleton${index}`} sx={[styling ? styling : {}]} animation="wave" variant="text"/>
            ))
        }
        {
            data?.type === 'rectangular' &&
            data?.map((_, index) => (
                <Skeleton key={index} className={`rectangularSkeleton${index}`} sx={[styling ? styling : {}]} animation="wave" variant="rectangular"/>
            ))
        }
        {
            data?.type === 'rounded' &&
            data?.map((_, index) => (
                <Skeleton key={index} className={`roundedSkeleton${index}`} sx={[styling ? styling : {}]} animation="wave" variant="rounded"/>
            ))
        }
        {
            data?.type === 'circular' &&
            data?.map((_, index) => (
                <Skeleton key={index} className={`circularSkeleton${index}`} sx={[styling ? styling : {}]} animation="wave" variant="circular"/>
            ))
        }
    </Box>
  )
}