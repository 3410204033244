import React from "react";
import Grid from "../../layout/grid/Grid";
import style from "./BigCard.module.css";
import lng from "../../utils/language";
import { useSelector } from "react-redux";
import { AiFillCaretRight } from 'react-icons/ai';
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import constants from "../../utils/consts";

export default function BigCards({ data, reserveType, buttonStyle, noBlank }) {
  const { language } = useSelector((state) => state.language);
  return (
    data.active === 1 &&
    <div className={style.bigCard}>
      <Grid no={12}>
        <div className={style.bigCardImgContainer}>
          <img src={constants.imgPath+data.images} alt={data.body_header} />
        </div>
        <div className={style.bigCardInfoContainer}>
          {data.body_header && <Typography component={'h2'} fontSize={'24'} fontWeight={'700'} sx={{alignSelf: 'center'}}>{data.body_header}</Typography>}
          {data.small_header && <Typography component={'h4'} fontSize={'15px'} sx={{whiteSpace: 'pre-line'}}>{data.small_header}</Typography>}
          {data.body_desc && <Typography component={'p'} sx={{whiteSpace: 'pre-wrap', lineHeight: 'normal !important'}}>{data.body_desc}</Typography>}
          {
            data.link &&
            <Button 
                component={Link} 
                sx={[{
                    display: 'flex',
                    padding: '15px',
                    color: '#FFF',
                    marginBottom: '20px',
                    width: 'fit-content',
                    height: 'max-content',
                    alignItems: 'center',
                    borderRadius: '35px',
                    textDecoration: 'none',
                    justifyContent: 'center',
                    background: 'transparent',
                    border: 'solid 1px #FFF',
                }, buttonStyle && buttonStyle]} 
                className={style.linkBtn} 
                to={data.link} 
                target={noBlank === true ? "_self" :  "_blank"}
                rel="noreferrer"
              >
                {
                    reserveType ?
                        lng[language]["bookNow"]
                    :
                    <>
                        {lng[language]["watchVideo"]} 
                        <span>
                        <AiFillCaretRight />
                        </span>
                    </>
                }
            </Button>
          }
          {
            data.reserveNow &&
            <Typography 
                component={Link} 
                to={`../reservation/${data.reserveNow}`} 
                sx={
                [
                    // {
                    //     backgroundColor: 'transparent',
                    //     padding: '10px 30px',
                    //     fontWeight: 'bold',
                    //     border: 'solid 2px #b045fd',
                    //     width: 'fit-content',
                    //     color: '#FFF',
                    //     borderRadius: '40px',
                    //     textDecoration: 'none',
                    //     marginBottom: '20px',
                    //     height: 'min-content',
                    //     alignSelf: 'end',
                    //     transition: '.3s all',
                    //     "&:hover": {
                    //         backgroundColor: '#b045fd',
                    //     }
                    // },
                    // 
                ]
            }
            >Rezervo</Typography>
          }
        </div>
      </Grid>
    </div>
  );
}
