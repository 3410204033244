import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import style from "./VideoWrapper.module.css";
import { FaPlay } from "react-icons/fa";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button'
import lng from "../../../utils/language";

function VideoWrapper(props) {
  const { language } = useSelector((state) => state.language);
  return (
    <div className={style.videoWrapper}>
      <img
        src={props.imgUrl}
        alt="video"
      />
      <Box sx={{
        width: '100%',
        height: {
          sm: 'auto',
          xs: '100%',
          position: 'relative',
          justifyContent: 'center',
          // alignItems: 'center'
        }
        }}>
        <h1>{props.CompetitionRulesTitle}</h1>
        <div>
          <p>{props.CompetitionRulesDesc}</p>
          <Button 
            LinkComponent={Link} 
            to={props.videoUrl} 
            target="_blank" 
            rel="noreferrer" 
            className="whiteBtn" 
            sx={{
                border: 'solid 2px #FFF',
                borderRadius: '40px',
                color: '#FFF',
                padding: '15px 25px',
                '&:hover': {
                    backgroundColor: 'rgb(240, 60, 68)',
                    'svg': {
                        fill: '#FFF'
                    }
                }
            }}
        >
            {lng[language]['watchVideo']} <FaPlay />
          </Button>
        </div>
      </Box>
    </div>
  );
}
export default VideoWrapper;