import Grid from "../grid/Grid";
import style from "./Footer.module.css";
import { BsInstagram, BsYoutube, BsFacebook } from "react-icons/bs";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'

function Footer() {
  return (
    <footer className={style.footer}>
      <div className={style.mapouter}>
        <div className={style.gmap_canvas}>
          <div>
            <div>
              <iframe
                title='HyperMap'
                width="100%"
                height="600"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=HyperActive%20Kosovo+(HyperActive)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">
                  measure distance on map
                </a>
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <Grid no="3">
        <div>
          <p>Prishtina Mall, M2 (Prishtine - Ferizaj), Prishtine 10000</p>
        </div>
        <div className={style.mail}>
          <span>Na shkruaj</span>
          <a href="mailto:info@hyper-active.com">info@hyper-active.com</a>
          <a href="mailto:events@hyper-active.com">events@hyper-active.com</a>
        </div>
        <Box 
            sx={{
                'a': {
                    textDecoration: 'none',
                    color: '#FFF'
                }
            }}
        >
          <span>Kontakti</span>
          <p>
            <a href="tel:+38348418418">+383-48 418 418</a>
          </p>
        </Box>
        <div></div>
        <div>
          <span>Orari i punës</span>
          <p>11:00 - 00:00</p>
        </div>
        <div>
          <span>Na ndiçni</span>
          <ul className={style.social}>
            <li>
              <a
                href="https://www.instagram.com/hyperactive.ks"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram color="white" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/HyperActive.ks/"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook color="white" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@hyperactive2109"
                target="_blank"
                rel="noreferrer"
              >
                <BsYoutube color="white" />
              </a>
            </li>
          </ul>
        </div>
      </Grid>

        <Box>
            <Typography variant="subtitle2" textAlign={'center'} marginY={0} padding={0}>
                {/* Unlimited Gaming SH.P.K. - HyperActive Magjistralja Prishtinë-Ferizaj, Kilometri i 10-të  */}
                Prishtina Mall, Kati 2
            </Typography>
        </Box>


    </footer>
  );
}
export default Footer;
