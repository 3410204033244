import React from 'react';
import Box from '@mui/material/Box';
import MenuCard from '../../../components/card/MenuCard';
import TabsComp from '../../../components/tabs/TabsComp';
export default function HomeMenu() {

    const sups = [
        {
            id: 0,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Supa e ditës',
            price: '2.5',
            desc: `Shërbehet me krutona, bukë me hudhër ose bukë me brum të petëzuar`,
            url: `/item/1`
        },
        {
            id: 1,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë Cezar me pulë',
            price: '4.9',
            desc: `Pulë e pjekur në skarë, sallatë e gjelbër, krutona
            dhe rriska parmezani me salcë cezar`,
            url: ''
        },
        {
            id: 2,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë shtëpie',
            price: '4.2',
            desc: `Sallatë e gjelbër, krutona, domatina, djath, tranguj,
            qepë e kuqe dhe misër me salcë limoni`,
            url: ''
        },
        {
            id: 3,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë me panxhar',
            price: '3.5',
            desc: `Panxhar i kuq, djath, rukollë, arra të karamelizuara,
            rriska portokalli, vaj ulliri dhe uthull balsamike`,
            url: ''
        },
    ]

    const burgers = [
        {
            id: 4,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Burger klasik',
            price: '4.9',
            desc: `Shërbehet në bukë hamburgeri me mish viçi të shtypur, tranguj turshi
            cheddar, qepë krokante, majonez me hudhër dhe pomfrit`,
            url: ''
        },
        {
            id: 5,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Ultimate burger',
            price: '5.9',
            desc: `Shërbehet në bukë hamburgeri me mish viçi të shtypur,
            gorgonzolë,cheddar, xhem të specave, qepë të karamelizuara,
            majonez me hudhër dhe pomfrit`,
            url: ''
        },
        {
            id: 6,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Burger pule',
            price: '4.5',
            desc: `Shërbehet në bukë hamburgeri me pulë krokante, jalapeño,
            coleslaw, xhem të specave, cheddar, majonez me hudhër dhe pomfrit`,
            url: ''
        },
        {
            id: 7,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Burger pule BBQ',
            price: '4.2',
            desc: `Shërbehet në bukë hamburgeri me pulë të copëzuar, jalapeño,
            salcë të shtëpisë BBQ, coleslaw, majonez me hudhër dhe pomfrit`,
            url: ''
        },
        {
            id: 8,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Burger vegjetarian',
            price: '4.5',
            desc: `Shërbehet në bukë hamburgeri me djath halloumi në skarë,
            perime të pjekura, cheddar, majonez me hudhër, coleslaw dhe pomfrit`,
            url: ''
        },
        {
            id: 9,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'New York hot dog',
            price: '3.5',
            desc: `Shërbehet në bukë hot dogu me salsiçe viçi, qepë krokante,
            mustardë djegës dhe lakër turshi`,
            url: ''
        },
    ]
    
    const sharing = [
        {
            id: 10,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Nachos me kashkavall',
            price: '4.9',
            desc: `Tortilla chips me cheddar, jalapeño, guacamole
            dhe salcë chipotle anash`,
            url: ''
        },
        {
            id: 11,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Nachos me pulë BBQ',
            price: '5.9',
            desc: `Tortilla chips me gjoks pule të copëzuar, salcë shtëpie BBQ, speca të kuq,
            jalapeño, cheddar, misër, guacamol dhe salcë chipotle anash`,
            url: ''
        },
        {
            id: 12,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Ultimate nachos',
            price: '6.9',
            desc: `Tortilla chips me mish viçi të copëzuar, cheddar, jalapeño,
            speca të kuq, misër, guacamole dhe salcë chipotle anash`,
            url: ''
        },
        {
            id: 13,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Chicken strips',
            price: '4.9',
            desc: `Copa pule krokante me majonez sriracha`,
            url: ''
        },
        {
            id: 14,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Arancini me vaj tartufi',
            price: '6.2',
            desc: `Oriz Arborio, parmezan, vaj tartufi me majonez sriracha`,
            url: ''
        },
        {
            id: 15,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Kalamari',
            price: '6.5',
            desc: `Kallamari me salcë tartar`,
            url: ''
        },
        {
            id: 16,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Karkaleca tempura',
            price: '8.9',
            desc: `Karkaleca krokant me majonez sriracha`,
            url: ''
        },
        {
            id: 17,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Meze e nxehtë',
            price: '15.5',
            desc: `Këpurdha, arancini, krahë pule me hudhër, copa pule krokante
            me misër kremoz dhe salcë tensuyu`,
            url: ''
        },
        {
            id: 18,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Meze e ftohtë',
            price: '11.5',
            desc: `Bruschetta me halloumi &amp; domate, parmezan, djath,
            suxhuk djegës, proshutë, arra të karamelizuara, dardha,
            tranguj turshi, ullinjë dhe xhem të specave`,
            url: ''
        },
    ]

    const chicken = [
        {
            id: 19,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Supa e ditës',
            price: '2.5',
            desc: `Shërbehet me krutona, bukë me hudhër ose bukë me brum të petëzuar`,
            url: ''
        },
        {
            id: 20,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë Cezar me pulë',
            price: '4.9',
            desc: `Pulë e pjekur në skarë, sallatë e gjelbër, krutona
            dhe rriska parmezani me salcë cezar`,
            url: ''
        },
        {
            id: 21,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë shtëpie',
            price: '4.2',
            desc: `Sallatë e gjelbër, krutona, domatina, djath, tranguj,
            qepë e kuqe dhe misër me salcë limoni`,
            url: ''
        },
        {
            id: 22,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë me panxhar',
            price: '3.5',
            desc: `Panxhar i kuq, djath, rukollë, arra të karamelizuara,
            rriska portokalli, vaj ulliri dhe uthull balsamike`,
            url: ''
        },
    ]

    const mainDish = [
        {
            id: 23,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Supa e ditës',
            price: '2.5',
            desc: `Shërbehet me krutona, bukë me hudhër ose bukë me brum të petëzuar`,
            url: ''
        },
        {
            id: 24,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë Cezar me pulë',
            price: '4.9',
            desc: `Pulë e pjekur në skarë, sallatë e gjelbër, krutona
            dhe rriska parmezani me salcë cezar`,
            url: ''
        },
        {
            id: 25,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë shtëpie',
            price: '4.2',
            desc: `Sallatë e gjelbër, krutona, domatina, djath, tranguj,
            qepë e kuqe dhe misër me salcë limoni`,
            url: ''
        },
        {
            id: 26,
            imgUrl: 'https://hyper-active.com/assets/reservation/GoKart.webp',
            imgAlt: '',
            title: 'Sallatë me panxhar',
            price: '3.5',
            desc: `Panxhar i kuq, djath, rukollë, arra të karamelizuara,
            rriska portokalli, vaj ulliri dhe uthull balsamike`,
            url: ''
        },
    ]

    const tabs = [
        {
            tabInfo: {
                label: 'Supa dhe sallata',
            },
            children: (
                <Box>
                    <MenuCard menuData={sups} />
                </Box>
            )
        },
        {
            tabInfo: {
                label: 'Burgera',
            },
            children: (
                <Box>
                    <MenuCard menuData={burgers} />
                </Box>
            )
        },
        {
            tabInfo: {
                label: 'Sharing',
            },
            children: (
                <Box>
                    <MenuCard menuData={sharing} />
                </Box>
            )
        },
        {
            tabInfo: {
                label: 'Krahë pule',
            },
            children: (
                <Box>
                    <MenuCard menuData={chicken} />
                </Box>
            )
        },
        {
            tabInfo: {
                label: 'Pjata kryesore',
            },
            children: (
                <Box>
                    <MenuCard menuData={mainDish} />
                </Box>
            )
        },
    ]

    return (
        <Box sx={{minHeight: '100vh', backgroundColor: '#E5D8C5', width: '100%'}}>
            <Box sx={{ width: '95%', marginX: 'auto' }}>
                <Box sx={{paddingTop: 5, display: 'flex', justifyContent: 'center'}}>
                    <Box component={'img'} src={'https://hyper-active.com/assets/hyperbar/logo.svg'} />
                    <Box>
                        {/* <Button 
                            onClick={() => dispatch(changeLanugage('eng'))} 
                            disabled={lng === "eng" && true}
                        >Eng</Button>
                        <span>/</span>
                        <Button onClick={() => dispatch(changeLanugage('alb'))} disabled={lng === "alb" && true}>Alb</Button> */}
                    </Box>
                </Box>
                <TabsComp tabInfo={tabs} />
                {/* <Button onClick={() => ds()} variant='contained'>dasdas</Button> */}
            </Box>
        </Box>
    )
}