import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'

export default function AddBanner(props) {

    const { header, desc, imageUrl, headerStyle, descStyle, mainContainer } = props;
    
    const typo = {
        whiteSpace: {md: 'pre-line', xs: 'normal'}
    }

    return (
        <Box 
            sx={[
                {
                    width: 1,
                    paddingY: '40px',
                    position: 'relative',
                    background: 'linear-gradient(90deg, rgba(50,30,255,1) 0%, rgba(50,32,255,0.8169468470982143) 16%, rgba(45,140,255,0.7273109927564776) 42%, rgba(103,36,215,1) 79%)'
                }, mainContainer ? mainContainer : {}
            ]}
        >
            <Box
                sx={{
                    height: '100%',
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: '1fr 1fr'
                    },
                    width: {
                        lg: '1024px',
                        md: '60%',
                        xs: '80%'
                    },
                    marginX: 'auto',
                    alignItems: 'center',
                }}
            >
                <Box
                    className="textContainer"
                    sx={{
                        color: '#FFF',
                        zIndex: 1,
                        display: {md: 'grid', xs: 'flex'},
                        flexDirection: 'column',
                        gridTemplateRows: header && desc ? '1fr 1fr' : '1fr',
                        rowGap: {
                            md: '20px',
                            xs: '0'
                        }
                    }}
                >
                    {
                        header &&
                        <Typography variant="h3" sx={[typo, {marginBottom: {md: 'unset', xs: '20px', }, fontSize: {md: '3rem', xs: '30px'}, fontWeight: 700}, headerStyle ? headerStyle : {}]}>{header}</Typography>

                    }
                    {
                        desc &&
                        <Typography variant="body1" sx={[typo, descStyle && descStyle]}>{desc}</Typography>
                    }
                </Box>
                <Box 
                className='imgCon'
                    sx={{
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        opacity: {
                            md: 1,
                            xs: 0.6
                        },
                        position: {
                            md: 'relative',
                            xs: 'absolute'
                        },
                        '&::before': {
                            content: "''",
                            backgroundImage: 'url("../assets/images/circleBck.png")',
                            backgroundPosition: 'center',
                            backgroundOrigin: 'border-box',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100%',
                            position: 'absolute',
                            opacity: '0.05',
                            top: '10%',
                            left: '10%',
                            height: '80%',
                            width: '80%'
                        }
                    }}
                >
                    <Box component={'img'} src={imageUrl} sx={{width: 1, height: 1, zIndex: 1, objectFit: {md: 'cover', xs: 'contain'}}} />
                </Box>
            </Box>

        </Box>
    )
}