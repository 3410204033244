const partyRoom = {
    eng: {
        //TOP 
            topHeader: 'SUPER FUN FOR KIDS',
            topDesc: 'SUPER EASY FOR PARENTS',
        //Package Banner
            packageBanner: 'BIRTHDAY HOLIDAY PACKAGES',
            unlimitedGame: 'ENDLESS GAME',
            perfectGame: 'CREATE THE PERFECT DAY',
            startingFrom: 'Starting from',
            forWho: 'For Children',
            btn: 'More',
            new: 'NEW',
        // Radiant Banner
            menuHeader: 'Food In Lodra',
            menuDesc: 'Our menu is packed with mouth-watering options that will satisfy any craving and our bar is fully stocked with a range of beers, wines, and cocktails.',
            menuBtn: 'Book Now',
            midPaymentCards: 'Birthday rooms are available for two hours. To reserve a room, there must be a minimum of 10 children. If there are less than 10 children, the birthday party is organized in lodges.',
        // GALLERY
            gallery: 'Gallery',
        // FORM Header
            formHeader: "Let's organize the party together",
        // INPUTS
            fullName: 'Name & Surname',
            companyName: 'Company',
            email: 'E-Mail',
            phone: 'Telephone',
            date: 'Date',
            guests: 'Number of guests',
            partyType: 'Package Type',
            request: 'Requirements and details',
            submitBnt: 'Submit your request',
        // inputs packages
            firstPackage: 'Super Birthday',
            secondPackage: 'Arcade Birthday',
            thirdPackage: 'Ultimate Birthday',
        // res now
            rezNow: 'Book Now!',
            contactTeam: `Get in touch with our team to ensure your next event is perfectly tailored to your needs`,
            callNow: 'Call 049/909-196',

        // ACCORDIONS
            
    },
    alb: {

            //TOP 
            topHeader: 'SUPER FUN PËR FËMIJËT',
            topDesc: 'SUPER LEHTË PËR PRINDËRIT',
            //Package Banner
            packageBanner: 'PAKOT E FESTAVE TË DITËLINDJEVE',

            unlimitedGame: 'LOJË PA FUND',
            perfectGame: 'KRIJO DITËN PERFEKTE',
            startingFrom: 'Duke filluar prej',
            forWho: 'Për Fëmijë',
            btn: 'Më shumë',
            new: 'E RE',
        // Radiant Banner
            menuHeader: 'Ushqimi Në Lodra',
            menuDesc: 'Menuja jonë është e mbushur me zgjedhje të shijshme që plotësojnë çdo dëshirë. Shumë llojshmëri ushqimesh dhe ëmbëlsirash.',
            menuBtn: 'REZERVO',

            midPaymentCards: 'Dhomat e ditëlindjeve ofrohen për dy orë Për të rezervuar dhomën duhet të jenë minimum 10 fëmijë Nëse janë më pak se 10 fëmijë, ditëlindja organizohet në shtëpiza.',

        // GALLERY
            gallery: 'Galeria',

        // FORM Header
            formHeader: 'Të organizojmë festën së bashku',

        // INPUTS   
            fullName: 'Emri dhe Mbiemri',
            companyName: 'Emri i kompanisë',
            email: 'E-Mail',
            phone: 'Telephone',
            date: 'Data',
            guests: 'Numri i mysafirëve',
            partyType: 'Lloji i Pakos',
            request: 'Kërkesat dhe detajet',
            submitBnt: 'Dërgo kërkesen',

        // inputs packages
           firstPackage: 'Super Birthday',
           secondPackage: 'Arcade Birthday',
           thirdPackage: 'Ultimate Birthday',
        // res now
            rezNow: 'Rezervo Tani!',
                contactTeam: `Kontakto ekipën tone për të pregaditur eventing tuaj të radhës`,
            callNow: 'Thirr tani 049/909-196',
    }
}

const birthdayRoom = {
    eng: {
        mainHeader: 'SUPER FUN FOR KIDS',
        mainDesc: 'SUPER EASY FOR PARENTS',

        packageHeader: 'BIRTHDAY PARTY PACKAGES',

        packagesTitle1: 'Super Birthday',
        packagesTitle2: 'Arcade Birthday',
        packagesTitle3: 'Ultimate Birthday',
        packageshelperTxt: 'Starting from',
        packageForWho: 'For Kids',
        
        // FEATURES
        room: 'Birthday room',
        water: 'Water',
        slushy: 'Slushy',
        tea: 'Ice Tea',
        pizza: 'Pizza Margherita or Burger',
        snacks: 'Snacks',
        loje: 'Playtime at Lodra',
        bowling: 'Bowling',
        laser: 'Laser Tag',
        arcade: '1 hour in the Arcade games',
        photo: 'Photography',
        karaoke: 'Karaoke',

    },
    alb: {
        mainHeader: 'FUNTASTIC PËR FËMIJËT',
        mainDesc: 'SUPER LEHTË PËR PRINDËRIT',

        packageHeader: 'PAKOT E FESTAVE TË DITËLINDJEVE',

        packagesTitle1: 'Super Birthday',
        packagesTitle2: 'Arcade Birthday',
        packagesTitle3: 'Ultimate Birthday',
        packageshelperTxt: 'Duke filluar prej',
        packageForWho: 'Për Fëmijë',

        perfectDay: "KRIJO DITËN PERFEKTE",        

        // FEATURES
        room: 'Dhoma e ditëlindjeve',
        water: 'Ujë Rugove',
        slushy: 'Slushy',
        tea: 'Ice Tea',
        pizza: 'Pizza Margherita ose Burger',
        snacks: 'Snacks',
        loje: 'Lojë në Lodra',
        bowling: 'Bowling',
        laser: 'Laser Tag',
        arcade: '1 orë në lojërat Arcade',
        photo: 'Fotografi',
        karaoke: 'Karaoke',

    }
}

export { partyRoom, birthdayRoom };