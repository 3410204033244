import Box from "@mui/material/Box";
import style from "./Input.module.css";

function Input(props) {
  return (
    <Box className={style.input} sx={props.labelStyle && props.labelStyle}>
      {props.label && <label>{props.label}</label> }
      <Box
        component={'input'}
        placeholder={props.placeholder}
        value={props.value}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        className={`${props.errorMessage && props.touched && style.error}`}
      />
      {props.errorMessage && props.touched ? (
        <span className={style.error}>{props.errorMessage}</span>
      ) : null}
    </Box>
  );
}
export default Input;
