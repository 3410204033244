import React from "react";
import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
import ImageTextCard from "../../../components/card/ImageTextCard";
import Grid from "../../../layout/grid/Grid";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
import { useRef } from "react";
import { Box } from "@mui/material";
import RandomCards from "../../../components/card/RandomCards";
import ReserveText from "../../../components/reservation/ReserveText";
import GridBox from "../../../layout/grid/GridBox";
import GalleryBox from "../../../components/gallery/Gallery";
import { arcade } from "../../../utils/gameLng";
import lng from "../../../utils/language";
import FetchData from "../../../api/FetchData";
import PartyBanner from "../../../components/general/banner/PartyBanner";
import AddBanner from "../../../components/general/banner/AddBanner";
import Videos from "../../../components/general/videos/Videos";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
import Text from "../../../components/text/Text";

function Arcade() {
    const { language } = useSelector((state) => state.language);
    const gallery = useRef(null);
    const games = useRef(null);
    const birthdays = useRef(null);
    const [arcadeData, setArcadeData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const load = () => {
        FetchData.create("/data/getAll", {
            id: 3,
            lng: language === "alb" ? 1 : 2,
        }).then((res) => {
            setArcadeData(res.data.data);
            setLoading(false);
        });
    };
    React.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language]);
    const interest = {
        eng: {
            interest: [
                {
                    interest: "Gallery",
                    ref: gallery,
                },
                {
                    interest: "Games",
                    ref: games,
                },
                {
                    interest: "Birthdays",
                    ref: birthdays,
                },
            ],
        },
        alb: {
            interest: [
                {
                    interest: "Galeria",
                    ref: gallery,
                },
                {
                    interest: "Lojërat",
                    ref: games,
                },
                {
                    interest: "Festo ditëlindjen",
                    ref: birthdays,
                },
            ],
        },
    };
    const containerSpacing = {
        paddingY: '80px'
    }
    // const containerBottomSpacing = {
    //     paddingBottom: '80px'
    // }
    // const shownGames = {
    //     eng: {
    //         games: [
    //             {
    //                 title: "Fireteam Raven",
    //                 subtitle: `1-2 Players 
    //       Age: 6+`,
    //                 desc: `Play together with your friend Fireteam Raven in the two player cabin⚡️👽

    //       Play as Fireteam Raven against aliens on the stunning 4k - 55 inch screen using weapons with vibration and 5.1 surround sound that will awaken all your senses to experience the real fight inter-galactic.`,
    //                 img: constants.imgPath + "Halo Fire Raven.png",
    //                 youtube: "https://www.youtube.com/watch?v=ix8c1VaaFUQ",
    //             },
    //             {
    //                 title: "Fast & Furious",
    //                 subtitle: `1-2 Players 
    //       Age: 6+`,
    //                 desc: `Set in the timeframe of Halo: Combat Evolved, this arcade experience hot-drops players feet first into the boots of Fireteam Raven – a group of elite Orbital Drop Shock Troopers (ODST) on the surface of Alpha Halo fighting alongside the Master Chief and the rest of the UNSC as they try desperately to prevent the alien Covenant alliance from taking control of the ancient super-weapon.`,
    //                 img: constants.imgPath + "Fast & Furious.png",
    //                 youtube: "https://www.youtube.com/watch?v=0hDgDTfuU88",
    //             },
    //             {
    //                 title: "Mario",
    //                 subtitle: `1-2 Players 
    //       Age: 6+`,
    //                 desc: `Mario Kart Arcade GP DX – The next game in the extremely popular racing series has new courses, new game modes, new karts, new features, and a rainbow LED lit cabinet featuring a 42″ HD monitor. Linkable up to four cabinets, the game has three game modes: Grand Prix, Versus, and Co-Op. Players can compete through 40 rounds over five selectable cups in Grand Prix mode. Versus mode offers competitive racing excitement for all players. The new 2-Person Co-Op mode features “Fusion Karts.” One person drives while the other “shoots” other competitors.

    //       With over 20 types of karts that can specifically trigger over 90 types of items, the racing possibilities of Mario Kart Arcade GP DX are endless`,
    //                 img: constants.imgPath + "Mario.png",
    //                 youtube: "",
    //             },
    //             {
    //                 title: "Pac Man",
    //                 subtitle: `1-2 Players 
    //       Age: 6+`,
    //                 desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do",
    //                 img: constants.imgPath + "PacMan.png",
    //                 youtube: "",
    //             },
    //             {
    //                 title: "Minecraft Dungeons Arcade",
    //                 subtitle: "1-4 Lojtarë Mosha: 6+",
    //                 desc: `Minecraft Dungeons Arcade is an epic, action-packed adaptation of the popular console and computer game Minecraft Dungeons, a multiplayer “dungeon crawler” set within the Minecraft universe. In Minecraft Dungeons Arcade, up to four players use a diverse arsenal of melee and range weapons to battle through hordes of enemies and monstrous bosses across nine unique levels – totaling over 100 minutes of thrilling, fast-paced gameplay! With every play, the eye-catching cabinet vends one of 60 collectible cards that fit into one of five categories – Melee, Range, Armor, Pet, and Skin – and can customize the player’s adventure. Minecraft Dungeons Arcade is the blockbuster, family-friendly title that repeatedly keeps players of all ages returning to your location!`,
    //                 img: constants.imgPath + "Minecraft.png",
    //                 youtube: "https://www.youtube.com/watch?v=sxCytYrin0c",
    //             },
    //             {
    //                 title: "Super Bikes",
    //                 subtitle: `1-2 Players 
    //       Age: 6+`,
    //                 desc: `The Fast and the Furious: Super Bikes is the second game inspired by the hit movie franchise. 

    //       Players can choose from 12 licensed motorcycles and riders. Like The Fast and the Furious, players can upgrade their bikes with NOS, bigger engines, tires and more! Discover several shortcuts and unveil hidden secrets in these tracks, and learn to master the curves! Gain a huge advantage over your opponents by power-sliding through the curves and be the first to arrive at the Finish Line!`,
    //                 img: constants.imgPath + "Superbike.png",
    //                 youtube: "https://www.youtube.com/watch?v=zIUAA6I_3-g",
    //             },
    //             {
    //                 title: "Jurassic Park",
    //                 subtitle: `1-2 Players 
    //       Age: 6+`,
    //                 desc: `Rescue the dinosaurs on an island run amok! Use your high-powered tranquilizer gun to subdue menacing dinosaurs and restore order to the island.`,
    //                 img: constants.imgPath + "Jurassic Park.png",
    //                 youtube: "https://www.youtube.com/watch?v=NRDsEPHRnbA",
    //             },
    //             {
    //                 title: "Tomb Raider",
    //                 subtitle: `1-4 Players 
    //       Age: 6+`,
    //                 desc: `This latest installment continues in Tomb Raider’s epic tradition. The arcade game is a four-player light shooting skills game features three mind-blowing chapters with 5 sections each. It will take about one hour to complete the game! Gamers and movie fans alike will be totally stoked on Tomb Raider’s fast-action, heart-pumping gameplay. The cabinet feature a giant 120-inch screen: the size and graphics really pack a jaw-dropping punch.`,
    //                 img: constants.imgPath + "Tomb Raider.png",
    //                 youtube: "",
    //             },
    //             {
    //                 title: "Rabbids Hollywood",
    //                 subtitle: `1-4 Players 
    //       Age: 6+`,
    //                 desc: `Shoot Rabbids out of Hollywood Movies

    //       Are you a sharpshooter? Compete with buddies to stop the Rabbids invasion of Hollywood. Shoot all the crazy creatures during a four-player adventure and explore 3 different movie sets on the 65-Inch HD screen`,
    //                 img: constants.imgPath + "Rabbids Hollywood.png",
    //                 youtube: "https://www.youtube.com/watch?v=uYLJGl1GD8o",
    //             },
    //         ],
    //     },
    //     alb: {
    //         games: [
    //             {
    //                 title: "Fireteam Raven",
    //                 subtitle: "1-2 Lojtarë Mosha: 6+",
    //                 desc: `Luaj bashkë me shokun/shoqen Fireteam Raven në kabinën për dy lojtarë⚡️👽

    //       Luaj si Fireteam Raven kundër alienëve në ekranin mahnitës 4k - 55 inch duke përdorur armët me vibrim dhe supër zërim 5.1 që do ju zgjojnë të gjitha shqisat për të përjetuar luftën e vërtet inter-galaktike.`,
    //                 img: constants.imgPath + "Halo Fire Raven.png",
    //                 youtube: "https://www.youtube.com/watch?v=ix8c1VaaFUQ",
    //             },
    //             {
    //                 title: "Fast & Furious",
    //                 subtitle: "1-2 Lojtarë Mosha: 6+",
    //                 desc: `Uluni në veturë dhe garoni nëpër terne të veshtira, bashkë me shoqni e famijle - veturat e Fast and Furious përmbanë lëvizje emocionuese Thrill D që ju bën të ndjehni çdo gropë e çdo kthesë të terenit dhe dy ekrane që ju të ndiheni sikur jeni me t'vërtet pas timonit në shumë vende ekzotike`,
    //                 img: constants.imgPath + "Fast & Furious.png",
    //                 youtube: "https://www.youtube.com/watch?v=0hDgDTfuU88",
    //             },
    //             {
    //                 title: "Mario",
    //                 subtitle: "1-2 Lojtarë Mosha: 6+",
    //                 desc: `Loje jashtëzakonisht e popullarizuar e garave me monitor HD 42″. E lidhur deri në katër kabinete, loja ka tre mënyra të lojës: Grand Prix, Versus dhe Co-Op. Lojtarët mund të konkurrojnë në 40 raunde mbi pesë kupa të përzgjedhur në modalitetin Grand Prix. Modaliteti Versus ofron emocion garash konkurruese për të gjithë lojtarët. Modaliteti i ri Co-Op 2-Persona përmban "Fusion Karts". Një person drejton makinën, ndërsa tjetri "qëllon" konkurrentët e tjerë.

    //       Me mbi 20 lloje kartesh që mund të aktivizojnë në mënyrë specifike mbi 90 lloje artikujsh, mundësitë e garave të Mario Kart Arcade GP DX janë të pafundme.`,
    //                 img: constants.imgPath + "Mario.png",
    //                 youtube: "",
    //             },
    //             {
    //                 title: "Pac Man",
    //                 subtitle: "1-2 Lojtarë Mosha: 6+",
    //                 desc: `Arkada më e madhe e Pac-Man në botë

    //       Loja origjinale Pac-Man e riprodhuar në ekran të madh. Ekran i madh LED 108 inç. Lojtarët i hajnë pikat, fantazmat dhe fishekët për të përfunduar labirintet. Loja përfundon vetëm kur ju humbni jetën tuaj të fundit.

    //       Luajtja e njëkohshme me 2 lojtarë- Loja e versionit më të fundit përfshin lojën e njëkohshme me 2 lojtarë në Pac-Man. Lojtarët mund të bashkëpunojnë për të përfunduar labirintet në Pac-Man.

    //       Pac-Man-Lojtari 1 do të kontrollonë një Pac-Man klasik të verdhë dhe lojtari 2 do të kontrollonë një Pac-Man të gjelbërt`,
    //                 img: constants.imgPath + "PacMan.png",
    //                 youtube: "",
    //             },
    //             {
    //                 title: "Minecraft Dungeons Arcade",
    //                 subtitle: "1-4 Lojtarë Mosha: 6+",
    //                 desc: `Minecraft Dungeons Arcade është një mix epik, plot aksion, e konsolës popullore dhe lojës kompjuterike Minecraft Dungeons, shumë lojtarë të vendosur brenda universit Minecraft. Në Minecraft Dungeons Arcade, deri në katër lojtarë përdorin një arsenal të larmishëm armësh dhe gamë për të luftuar me shumë armiq dhe shefa monstruoz në nëntë nivele unike – që arrijnë në mbi 100 minuta lojëra emocionuese dhe me ritme të shpejta! Me çdo lojë, kabineti tërheqës shet një nga 60 kartat e koleksionit që përshtaten në një nga pesë kategoritë - Përleshje, Range, Armor, Pet dhe Lëkurë - dhe mund të personalizojnë aventurën e lojtarit.`,
    //                 img: constants.imgPath + "Minecraft.png",
    //                 youtube: "https://www.youtube.com/watch?v=sxCytYrin0c",
    //             },
    //             {
    //                 title: "Super Bikes",
    //                 subtitle: "1-2 Lojtarë Mosha: 6+",
    //                 desc: `Fast and the Furious: Super Bikes është loja e dytë e frymëzuar nga ekskluziviteti i filmave të suksesshëm.

    //       Lojtarët mund të zgjedhin nga 12 motoçikleta dhe kalorës të licencuar. Ashtu si “The Fast and the Furious”, lojtarët mund ti bëjnë më të fuqishme biçikletat e tyre me NOS, motorë më të mëdhenj, goma shumë ndërrime tjera! Zbuloni disa rrugë të shkurta dhe zbuloni sekretet e fshehura në këto pista dhe mësoni të zotëroni kthesat! Fitoni një avantazh të madh ndaj kundërshtarëve tuaj duke i përfeksionuar kthesat dhe fitojeni lojën.`,
    //                 img: constants.imgPath + "Superbike.png",
    //                 youtube: "https://www.youtube.com/watch?v=zIUAA6I_3-g",
    //             },
    //             {
    //                 title: "Jurassic Park",
    //                 subtitle: "1-2 Lojtarë Mosha: 6+",
    //                 desc: `Shpëtoni dinosaurët në një ishull të hutuar! Përdorni armën tuaj me fuqi të lartë që i vë në gjumë për të mposhtur dinosaurët kërcënues dhe për të rivendosur rregullin në ishull.`,
    //                 img: constants.imgPath + "Jurassic Park.png",
    //                 youtube: "https://www.youtube.com/watch?v=NRDsEPHRnbA",
    //             },
    //             {
    //                 title: "Tomb Raider",
    //                 subtitle: "1-4 Lojtarë Mosha: 6+",
    //                 desc: `Tradita e Tomb Rader vazhdon me arkadën e fundit epike, me ekran gjigant 120 inç. Tomb Rader është një lojë për katër lojtarë për të testuar aftësitë e gjuajtjes, me tre kapituj mbresëlënës me 5 seksione secili. Pritni shumë aksion dhe emocione dhe rreth një orë për të përfunduar të gjithë lojën!`,
    //                 img: constants.imgPath + "Tomb Raider.png",
    //                 youtube: "",
    //             },
    //             {
    //                 title: "Rabbids Hollywood",
    //                 subtitle: "1-4 Lojtarë Mosha: 6+",
    //                 desc: `A je gjuajtës i saktë? Konkurro me shoqninë për ta ndaluar pushtimin e Rabbids në Hollywood.

    //       Rrokni të gjitha krijesat e çmendura gjatë aventurës. 4 lojëtare mund të eksploroni 3 grupe të ndryshme filmash ne ekranin HD 65 inç.`,
    //                 img: constants.imgPath + "Rabbids Hollywood.png",
    //                 youtube: "https://www.youtube.com/watch?v=uYLJGl1GD8o",
    //             },
    //         ],
    //     },
    // };
    // const img = [
    //     {
    //         imgPath: constants.arcadeGallery + "A01.webp",
    //         imgAlt: "",
    //     },
    //     {
    //         imgPath: constants.arcadeGallery + "A02.webp",
    //         imgAlt: "",
    //     },
    //     {
    //         imgPath: constants.arcadeGallery + "A03.webp",
    //         imgAlt: "",
    //     },
    //     {
    //         imgPath: constants.arcadeGallery + "A04.webp",
    //         imgAlt: "",
    //     },
    //     {
    //         imgPath: constants.arcadeGallery + "A05.webp",
    //         imgAlt: "",
    //     },
    //     {
    //         imgPath: constants.arcadeGallery + "A06.webp",
    //         imgAlt: "",
    //     },
    // ];

    return loading === false ? (
        <div className={style.detailsWrapper}>
            <div className="container">
                {arcadeData[1] && (
                    <ActivitiesBanner
                        title={arcadeData[1].body_header}
                        desc={arcadeData[1].body_desc}
                        image={constants.imgPath + arcadeData[1].images}
                        linkPrice="karting"
                        vidId="SA4rWKB2cK4"
                        id={arcadeData[1].link}
                        prices={true}
                    />
                )}
                <Box
                    sx={{
                        marginTop: {
                            xs: "100px",
                            sm: 20,
                            md: 0,
                        },
                    }}
                >
                    <ActivitiesInterest interests={interest[language].interest} />
                </Box>

                <Box
                    className={'bodyWrapper'}
                    sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative' }}
                >
                    <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                    <Videos id={'SA4rWKB2cK4'} />
                </Box>
            </div>

            <Box sx={{
                marginY: '100px'
            }}>
                <AddBanner
                    mainContainer={{
                        height: '400px',
                        paddingY: 0,
                        '.imgCon': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: 1,
                            backgroundColor: '#AF46FA',
                            '&::before': {
                                content: "none"
                            },
                            'img': {
                                objectFit: {
                                    sm: '',
                                    xs: 'cover'
                                }
                            }
                        }
                    }}
                    descStyle={{
                        width: {
                            sm: '470px',
                            xs: '100%'
                        }
                    }}
                    header={lng[language]['girlheader']}
                    desc={lng[language]['girldesc']}
                    imageUrl={"https://hyper-active.com/images/girlWj.png"}
                />
            </Box>

            <div className="container">

                {arcadeData[7] && (
                    <Box className="container" sx={{ marginTop: 10 }} ref={gallery}>
                        <Text
                            containerStyling={{
                                marginBottom: '50px'
                            }}
                            descStyling={{
                                width: {
                                    md: '570px',
                                    xs: '100%'
                                },
                                marginX: 'auto',
                                textAlign: 'center',
                                fontWeight: '20px'
                            }}
                            header={arcadeData[7].wrapper_header}
                            desc={arcadeData[7].wrapper_desc}
                        />
                        <GridBox
                            gap={2}
                            gridTemplateColumns={{
                                xs: "repeat(2, 1fr)",
                                sm: "repeat(3, 1fr)",
                                md: "repeat(4, 1fr)",
                            }}
                            gridTemplateRows={{
                                xs: "repeat(3, 1fr)",
                                sm: "repeat(3, 1fr)",
                                md: "repeat(2, 1fr)",
                            }}
                        >
                            <GalleryBox galleryImages={arcadeData[7].images} />
                        </GridBox>
                    </Box>
                )}

            </div>



            <div className="container">

                <Box
                    className={style.wrapper}
                    sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative' }}
                >
                    <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                    <Videos id={'SA4rWKB2cK4'} />
                </Box>

                <div className={style.wrapper} ref={games}>
                    {arcadeData[8] && (
                        <>
                            <Text header={lng[language]["gameTypes"]} desc={lng[language]["gameTypesDesc"]} containerStyling={{
                                paddingBottom: '80px'
                            }} />

                            <Grid no={"ReverseSingle"}>
                                {arcadeData[8].map((i, j) => {
                                    return <ImageTextCard data={i} key={j} txtContainerStyling={{ '.MuiTypography-p': { fontSize: '14px' } }} />;
                                })}
                            </Grid>
                        </>
                    )}

                    <Box sx={containerSpacing}>
                        <Box className={style.moreOptions}>
                            <h1>{arcade[language].aracadeInfoHeader}</h1>
                            <p>{arcade[language].aracadeInfoDesc}</p>
                        </Box>
                    </Box>
                </div>
            </div>

            <Box sx={containerSpacing}>
                <AddBanner
                    headerStyle={{
                        fontSize: {
                            sm: '3rem',
                            xs: '29px'
                        }
                    }}

                    descStyle={{
                        width: {
                            sm: '470px',
                            xs: '100%'
                        },
                    }}
                    header={lng[language]['cardAddHeader2']}
                    desc={lng[language]['cardAddDesc']}
                    imageUrl={"https://hyper-active.com/images/content/GiftCard.png"}
                />
            </Box>

            <Box className={style.wrapper} ref={birthdays}>
                <PartyBanner
                    header={lng[language]['hyperBirthdayHeader']}
                    desc={lng[language]['hyperBirthdayDesc']}
                    buttonTxt={lng[language]['hyperBirthdayBtn']}
                    link={"/activities/party"}
                    imageUrl={"https://hyper-active.com/images/arcadegames/banner/arcade.birthday.jpg"}
                    containerStyle={{
                        width: { sm: "454px", xs: '90%' },
                        color: "#FFF",
                        background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 100%)",
                        padding: 3,
                        borderRadius: "40px",
                        display: "grid",
                        gridTemplateRows: "1fr 70px 60px",
                        rowGap: "20px",
                        '.MuiTypography-h3': {
                            fontSize: {
                                md: '3rem',
                                xs: '29px'
                            }
                        }
                    }}
                />
            </Box>

            <Box sx={{
                marginY: '100px'
            }}>
                <AddBanner
                    mainContainer={{
                        height: '400px',
                        paddingY: 0,
                        '.textContainer': {
                            display: 'grid',
                            gridTemplateRows: 'auto auto',
                        },
                        '.imgCon': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: 1,
                            backgroundColor: '#070f40',
                            '&::before': {
                                content: "none"
                            }
                        }
                    }}
                    descStyle={{

                        width: {
                            sm: '470px',
                            xs: '100%'
                        }
                    }}
                    header={lng[language]['boyheader']}
                    desc={lng[language]['boydesc']}
                    imageUrl={"https://hyper-active.com/images/Mask Group 194.png"}
                />
            </Box>

            <div className="container">
                <div className={style.wrapper}>
                    <AppBanner
                        header={lng[language]['hyperAppHeader']}
                        desc={lng[language]['hyperAppDesc']}
                        imgUrl={'../images/6582cf3c6d165.webp'}
                    />
                </div>
            </div>

            <div className="container">
                <ReserveText header={lng[language]['rezNow']} id={739727} />
                <Box paddingBottom={5} sx={containerSpacing}>
                    <Box sx={{ textAlign: 'center' }}>
                        <TextWrapper title={lng[language]["gameSuggestion"]} />
                    </Box>
                    <RandomCards removeGame="Arcade Games" />
                </Box>
            </div>
        </div>
    ) : (
        <WaitingLoader open={loading} />
    );
}
export default Arcade;