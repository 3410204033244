import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';

export default function PartyBanner(props) {

    const {
        mainConStyling,
        header, 
        secondHeader, 
        desc, 
        buttonTxt, 
        link, 
        imageUrl, 
        containerStyle, 
        headerStyle,
        buttonStyle,
        imgStyling
    } = props;

    return (
        <Box  position={'relative'} sx={[{display: 'grid', height: {md: 700, sm: 500, xs: 400}, width: '100%', gridTemplateColumns: '1fr'}, mainConStyling ? mainConStyling : {} ]}>
            <Box sx={[{position: 'absolute', top: 0, left: 0, height: 1, width: 1}, imgStyling]}>
                <Box component={'img'} src={imageUrl} width={1} height={1} sx={{objectFit: 'cover', opacity: {md: 1, xs: 0.5}}} />
            </Box>
            <Box sx={{zIndex: 1, marginY: 'auto', width: {md: '70%', sm: '500px', xs: '90%'}, display: 'flex', marginX: 'auto'}}>
                <Box sx={containerStyle}>
                    <Typography variant="h3" sx={[{fontSize: {md: '48px', xs: '30px', fontWeight: 'bold'}}, headerStyle]}>{header}</Typography>
                    {secondHeader &&
                    <Typography variant="h6" sx={[{fontSize: '20px'}]} className="secondHeader">{secondHeader}</Typography>
                    }
                    <Typography variant="body1" sx={[{fontSize: {xl: '16px', xs: '16px'}}]}>{desc}</Typography>
                    <Button 
                        variant='contained' 
                        LinkComponent={Link} 
                        to={link}
                        sx={[{
                            boxShadow: 0,
                            fontWeight: '600',
                            borderRadius: '20px',
                            width: 'fit-content',
                            background: 'transparent',
                            border: 'solid 2px #FFF',
                            paddingY: 2,
                            '&:hover': {
                                boxShadow: 0,
                                backgroundColor: '#FFF',
                                color: 'rgba(250,160,0,1)'
                            }
                        }, buttonStyle]}    
                    >
                        {buttonTxt}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}