import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from "react-redux";
import { partyRoom } from '../../../utils/roomsLng';
import Text from '../../../components/text/Text';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import GridBox from '../../../layout/grid/GridBox';
import GalleryBox from '../../../components/gallery/Gallery';
import RadiantBanner from '../../../components/general/banner/RadiantBanner';
import constants from '../../../utils/consts';
import PaymentCards from '../../../components/card/PaymentCards';
// import Accordions from '../../../components/accordions/Accordions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import lng from '../../../utils/language';
import AddBanner from '../../../components/general/banner/AddBanner';
import Videos from '../../../components/general/videos/Videos';
import FetchData from '../../../api/FetchData';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Recapcha from '../../../components/recaptcha/Recapcha';

export default function PartyRoom() {
    const { language } = useSelector((state) => state.language);

    const [partyRequest, setPartyRequest] = React.useState({
        fullName: '',
        email: '',
        phone: '',
        date: new Date(),
        guests: '',
        requestsAndDetails: '',
        partType: ''
    });

    const [partyRequestError, setPartyRequestError] = React.useState({
        fullNameError: false,
        emailError: false,
        phoneError: false,
        guestsError: false,
        requestsAndDetailsError: false
    });

    const [recatpchaVal, setRecaptchaVal] = React.useState(null);
    const [success, setSuccess] = React.useState(false);
    const [msg, setMsg] = React.useState({
        type: ''
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSuccess(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.values(partyRequestError);
        const confirmation = validation.includes(true);
        if (confirmation === false) {
            FetchData.create('/data/partyEvents', partyRequest)
            .then((res => {
                setPartyRequest({
                    fullName: '',
                    email: '',
                    phone: '',
                    date: new Date(),
                    guests: '',
                    requestsAndDetails: '',
                    partType: ''
                })
                setSuccess(true)
                setMsg({...msg, type: res.data.type})
            }))
            .catch((err) => {
                setSuccess(true)
                setMsg({...msg, type: "error"})
            })
        }else{
            setSuccess(true)
            setMsg({...msg, type: "error"})
        }
    }

    const captchaChange = (val) => {
        setRecaptchaVal(val)
    }

    const galleryImages = [
        '/rooms/packedGallery-1.png',
        '/rooms/packedGallery-2.png',
        '/rooms/packedGallery-3.png',
        '/rooms/packedGallery-4.png',
        '/rooms/packedGallery-5.png',
        '/rooms/packedGallery-6.png',
    ]

    const defaultContainerSpacing = {
        paddingY: '80px'
    }

    const defaultContainerSpacingBottom = {
        paddingBottom: '80px'
    }

    // const defaultContainerSpacingTop = {
    //     paddingTop: '80px'
    // }

    const mainContainer = {
        width: {
            lg: '1024px',
            md: '',
            sm: '',
            xs: '90%',
        },
        marginX: 'auto'
    }

    const inputs = [
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['fullName'], type: 'text', multiline: false, required: true, id: 'name', value: partyRequest.fullName,
            error: partyRequestError.fullNameError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                    setPartyRequestError({ ...partyRequestError, fullNameError: true })
                } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
                    setPartyRequestError({ ...partyRequestError, fullNameError: false })
                }
                setPartyRequest({ ...partyRequest, fullName: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['email'], type: 'email', multiline: false, required: true, id: 'email', value: partyRequest.email,
            error: partyRequestError.emailError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) {
                    setPartyRequestError({ ...partyRequestError, emailError: true })
                } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") {
                    setPartyRequestError({ ...partyRequestError, emailError: false })
                }
                setPartyRequest({ ...partyRequest, email: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['phone'], type: 'tel', multiline: false, required: true, id: 'phone', value: partyRequest.phone,
            error: partyRequestError.phoneError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/)) {
                    setPartyRequestError({ ...partyRequestError, phoneError: true })
                } else if (value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/) || value === "") {
                    setPartyRequestError({ ...partyRequestError, phoneError: false })
                }
                setPartyRequest({ ...partyRequest, phone: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            typeOf: 'Date',
            size: 'double', label: partyRoom[language]['date'], type: 'date', multiline: false, required: true, id: 'date', value: partyRequest.date,
            error: partyRequestError.dateError,
            multiCall: (e) => {
                var value = e;
                var time = new Date() - 1;
                if (value === time || value !== "") {
                    setPartyRequestError({ ...partyRequestError, dateError: true })
                } else if (value !== time || value === "") {
                    setPartyRequestError({ ...partyRequestError, dateError: false })
                }
                setPartyRequest({ ...partyRequest, date: value })
            },
            styling: {
                '.react-datepicker-wrapper input': {
                    width: '91.5%',
                    paddingY: '12.8px',
                    paddingX: '14px',
                    height: '1.4375em',
                    borderRadius: '20px',
                    backgroundColor: '#E3E3E3',
                    // borderColor: data.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    outline: '0'
                },
                '.react-datepicker-wrapper input:focus': {
                    borderColor: '#1975d2',
                    borderWidth: '2px'
                },
            },
        },

        // SINGLE INPUTS
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['guests'], type: 'number', multiline: false, required: true, id: 'guests', value: partyRequest.guests,
            error: partyRequestError.guestsError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^\d+$/)) {
                    setPartyRequestError({ ...partyRequestError, guestsError: true })
                } else if (value.match(/^\d+$/) || value === "") {
                    setPartyRequestError({ ...partyRequestError, guestsError: false })
                }
                setPartyRequest({ ...partyRequest, guests: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            typeOf: 'Select',
            size: 'double', label: partyRoom[language]['partyType'], required: true, id: 'partyType', value: partyRequest.partType,
            error: null,
            multiCall: (e) => {
                var value = e.target.value;
                setPartyRequest({ ...partyRequest, partType: value })
            },
            styling: {
                '.MuiSelect-select ': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px !important',
                },
                'MuiSelect-select:focus': {
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                },
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                }
            },
        },
        {
            typeOf: 'Single',
            size: 'single', label: partyRoom[language]['request'], type: 'text', multiline: true, required: true, id: 'guests', value: partyRequest.requestsAndDetails,
            error: partyRequestError.requestsAndDetailsError,
            multiCall: (e) => {
                var value = e.target.value;
                setPartyRequest({ ...partyRequest, requestsAndDetails: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    // paddingY: 1.6
                }
            },
        }
    ]

    const paymentCards = [
        {
            // STYLING
            mainContainer: {
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '50px',
                paddingBottom: '60px',
                border: '3px solid #FFFFFF',
                borderRadius: '46px',
                background: '#AF46FA',
                '.compNew': {
                    position: 'absolute',
                    top: '-22px',
                    backgroundColor: '#F03C44',
                    borderRadius: '30px',
                    padding: '5px 25px',
                },

                '.compBtn': {
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: '-22px',
                },

                '.MuiButton-root': {
                    background: '#F03C44',
                    padding: '10px 30px',
                    borderRadius: '30px',
                    fontWeight: 600,
                    minWidth: 'max-content',
                    '&:hover': { backgroundColor: '#FFF', color: '#F03C44' }
                }
            },
            imageContainer: {
                marginBottom: '20px',
                'img': {
                    width: '300px',
                    borderRadius: '100%'
                }
            },
            textContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '.compHeader': {
                    fontSize: '40px',
                    fontWeight: 700,
                    textAlign: 'center',
                },
                '.compSecondHeader': {
                    marginBottom: '30px',
                    fontSize: '25px',
                    fontWeight: 700,
                    textAlign: 'center',
                },
                '.compTitle': {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '60px',
                },
                '.compDesc': {
                    position: 'relative',
                    '.money': {
                        position: 'absolute',
                        left: '-45px',
                        fontWeight: 'bold',
                        fontSize: '50px',
                        top: '22px',
                    },
                    '.cents': {
                        fontWeight: 'bold',
                        position: 'absolute',
                        top: '17px',
                        right: '-65px',
                        fontSize: '44px'
                    },
                    '.desc': {
                        fontSize: '90px',
                        fontWeight: 'bold'
                    },
                    '.forKids': {
                        position: 'absolute',
                        lineHeight: '15px',
                        right: '-100px',
                        top: '69px',
                        fontWeight: 'bold',
                        width: '86px',
                    }
                }
            },
            // DATA
            new: true,
            newTxt: partyRoom[language]['new'],
            header: partyRoom[language]['unlimitedGame'],
            secondHeader: partyRoom[language]['perfectGame'],
            title: partyRoom[language]['startingFrom'],
            forWho: partyRoom[language]['forWho'],
            desc: '11',
            link: '/activities/birthday',
            btnText: partyRoom[language]['btn']
        },
        {
            // STYLING
            mainContainer: {
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '50px',
                paddingBottom: '60px',
                border: '3px solid #FFFFFF',
                borderRadius: '46px',
                background: '#AF46FA',
                '.compNew': {
                    position: 'absolute',
                    top: '-22px',
                    backgroundColor: '#F03C44',
                    borderRadius: '30px',
                    padding: '5px 25px',
                },

                '.compBtn': {
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: '-22px',
                },

                '.MuiButton-root': {
                    background: '#F03C44',
                    padding: '10px 30px',
                    borderRadius: '30px',
                    fontWeight: 600,
                    minWidth: 'max-content',
                    '&:hover': { backgroundColor: '#FFF', color: '#F03C44' }
                }
            },
            imageContainer: {
                marginBottom: '20px',
                'img': {
                    width: '300px',
                    borderRadius: '100%'
                }
            },
            textContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '.compHeader': {
                    fontSize: '40px',
                    fontWeight: 700,
                    textAlign: 'center',
                },
                '.compSecondHeader': {
                    marginBottom: '30px',
                    fontSize: '25px',
                    fontWeight: 700,
                    textAlign: 'center',
                },
                '.compTitle': {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '60px',
                },
                '.compDesc': {
                    position: 'relative',
                    '.money': {
                        position: 'absolute',
                        left: '-45px',
                        fontWeight: 'bold',
                        fontSize: '50px',
                        top: '22px',
                    },
                    '.cents': {
                        fontWeight: 'bold',
                        position: 'absolute',
                        top: '17px',
                        right: '-65px',
                        fontSize: '44px'
                    },
                    '.desc': {
                        fontSize: '90px',
                        fontWeight: 'bold'
                    },
                    '.forKids': {
                        position: 'absolute',
                        lineHeight: '15px',
                        right: '-100px',
                        top: '69px',
                        fontWeight: 'bold',
                        width: '86px',
                    }
                }
            },
            // DATA
            newTxt: partyRoom[language]['new'],
            header: partyRoom[language]['unlimitedGame'],
            secondHeader: partyRoom[language]['perfectGame'],
            title: partyRoom[language]['startingFrom'],
            forWho: partyRoom[language]['forWho'],
            desc: '14',
            link: '/activities/birthday',
            btnText: partyRoom[language]['btn']
        },
        {
            // STYLING
            mainContainer: {
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '50px',
                paddingBottom: '60px',
                border: '3px solid #FFFFFF',
                borderRadius: '46px',
                background: '#AF46FA',
                '.compNew': {
                    position: 'absolute',
                    top: '-22px',
                    backgroundColor: '#F03C44',
                    borderRadius: '30px',
                    padding: '5px 25px',
                },

                '.compBtn': {
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bottom: '-22px',
                },

                '.MuiButton-root': {
                    background: '#F03C44',
                    padding: '10px 30px',
                    borderRadius: '30px',
                    fontWeight: 600,
                    minWidth: 'max-content',
                    '&:hover': { backgroundColor: '#FFF', color: '#F03C44' }
                }
            },
            imageContainer: {
                marginBottom: '20px',
                'img': {
                    width: '300px',
                    borderRadius: '100%'
                }
            },
            textContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '.compHeader': {
                    fontSize: '40px',
                    fontWeight: 700,
                    textAlign: 'center',
                },
                '.compSecondHeader': {
                    marginBottom: '30px',
                    fontSize: '25px',
                    fontWeight: 700,
                    textAlign: 'center',
                },
                '.compTitle': {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '60px',
                },
                '.compDesc': {
                    position: 'relative',
                    '.money': {
                        position: 'absolute',
                        left: '-45px',
                        fontWeight: 'bold',
                        fontSize: '50px',
                        top: '22px',
                    },
                    '.cents': {
                        fontWeight: 'bold',
                        position: 'absolute',
                        top: '17px',
                        right: '-65px',
                        fontSize: '44px'
                    },
                    '.desc': {
                        fontSize: '90px',
                        fontWeight: 'bold'
                    },
                    '.forKids': {
                        position: 'absolute',
                        lineHeight: '15px',
                        right: '-100px',
                        top: '69px',
                        fontWeight: 'bold',
                        width: '86px',
                    }
                }
            },
            // DATA
            newTxt: partyRoom[language]['new'],
            header: partyRoom[language]['unlimitedGame'],
            secondHeader: partyRoom[language]['perfectGame'],
            title: partyRoom[language]['startingFrom'],
            forWho: partyRoom[language]['forWho'],
            desc: '17',
            link: '/activities/birthday',
            btnText: partyRoom[language]['btn']
        },
    ]

    const containerSpacing = {
        paddingY: '80px'
    }

    // const accordions = {
    //     eng:[
    //         {
    //             title: 'How much can a birthday party cost in Lodra?',
    //             desc: 'Our packages start at €11.99 per guest and go up to €29.99 per guest, depending on what guests want to include.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'What comes in a birthday package?',
    //             desc: 'Most of our birthday packages include activities of your choice, food and refreshments for children, a fun card and a party planner.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'Can I bring a cake or cupcakes?',
    //             desc: 'Yes, we allow dessert to be brought in from outside',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'Can I decorate for the birthday party?',
    //             desc: 'We allow our guests to decorate, however we provide all plates, glasses, napkins and cutlery for any event. We also offer excellent decoration packages to satisfy every taste.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'What does the main event offer?',
    //             desc: 'We provide plates, napkins, silverware and glasses for meals.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },

    //     ],
    //     alb: [
    //         {
    //             title: 'Sa mund të kushtojë një festë e ditëlindjes në Lodra?',
    //             desc: 'Paketat tona fillojnë nga 11,99€ për mysafir dhe shkojnë deri në 29,99€ për mysafir, varësisht se çka të ftuarit duan të përfshijnë.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'Çfarë vjen në një paketë ditëlindjeje?',
    //             desc: 'Shumica e paketave tona të ditëlindjes përfshijnë aktivitete sipas zgjedhjes suaj, ushqime dhe pije freskuese për fëmijë, një kartolinë argëtimi dhe një organizator feste.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'A mund të sjell një tortë ose cupcakes?',
    //             desc: 'Po, ne lejojmë të sillet ëmbëlsirë nga jashtë',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'A mund të dekoroj për festën e ditëlindjes?',
    //             desc: 'Ne i lejojmë mysafirët tanë të dekorojnë, megjithatë ne ofrojmë të gjitha pjatat, gotat, pecetat dhe takëm për çdo ngjarje. Ne ofrojmë gjithashtu paketa të shkëlqyera të dekorimeve që kënaq çdo shije.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //         {
    //             title: 'Çfarë ofron ngjarja kryesore?',
    //             desc: 'Ne ofrojmë pjata, peceta, sende argjendi dhe gota për ushqimet.',
    //             styling: {
    //                 '.Mui-expanded': {
    //                     margin: 0
    //                 },
    //                 '.MuiAccordion-root': {
    //                     background: 'transparent !important'
    //                 },
    //                 '.MuiAccordionSummary-gutters': {
    //                     padding: '10px',
    //                     background: '#AF46FA',
    //                     color: '#FFF',
    //                     margin: 0,
    //                     borderRadius: '5px'
    //                 },
    //                 '.MuiAccordionDetails-root': {
    //                     margin: '20px 10px',
    //                     borderRadius: '5px'
    //                 }
    
    //             }
    //         },
    //     ]
        
    // }

    return (

        <Box sx={[{ backgroundColor: '#4600BE', color: '#FFF' }, defaultContainerSpacing]}>

            <Box sx={mainContainer}>
                <Box sx={defaultContainerSpacingBottom}>
                    <Text
                        header={partyRoom[language]['topHeader']}
                        desc={partyRoom[language]['topDesc']}
                        headerStyle={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: {
                                sm: '35px',
                                xs: '29px'
                            },
                            marginBottom: '10px'
                        }}
                        descStyling={{
                            fontWeight: 'bold',
                            fontSize: {
                                sm: '37px',
                                xs: '24px'
                            },
                            textAlign: 'center'
                        }}
                    />
                    <Box sx={{
                            height: {
                                md: '500px',
                                sm: '416px',
                                xs: '209px'
                            },
                            position: 'relative',
                            marginTop: '50px'
                        }}
                    >
                        <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                        <Videos id={'D3dPaLYw694'} />
                    </Box>
                </Box>

                {/* PAYMENT CARDS */}
                <Box>
                    <Text
                        header={partyRoom[language]['packageBanner']}
                        headerStyle={{
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}
                    />
                    <Box
                        sx={[{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr 1fr', sm: '1fr 1fr 1fr', xs: '1fr' }, columnGap: '20px', rowGap: '80px' }, defaultContainerSpacing]}>
                        {paymentCards.map((data, index) => (
                            <PaymentCards
                                key={index}
                                data={data}
                                type="payment"
                            />
                        ))}
                    </Box>
                </Box>

                <Box sx={{ paddingY: '50px' }}>
                    <Text
                        desc={partyRoom[language]['midPaymentCards']}
                        containerStyling={{
                            marginX: 'auto'
                        }}
                        descStyling={{
                            width: {
                                sm: '560px',
                                xs: '100%'
                            },
                            textAlign: 'center',
                            marginX: 'auto',
                            fontWeight: 'bold',
                            whiteSpace: 'pre-line'
                        }}
                    />
                </Box>

                {/* Tabs */}
                {/* <Box sx={defaultContainerSpacing}>
                    {accordions[language].map((data, index) => (
                        <Accordions key={index} data={data} />
                    ))}
                </Box> */}

                {/* Radiant Banner */}
                <Box sx={{ paddingY: '40px', marginX: { lg: 'auto', xs: 'auto' }, width: { lg: '1024px', sm: '70%', xs: '100%' } }}>
                    <RadiantBanner
                        header={partyRoom[language]['menuHeader']}
                        text={partyRoom[language]['menuDesc']}
                        urlTxt={partyRoom[language]['menuBtn']}
                        url={'/activities/birthday'}
                        mainContainer={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url( "${constants.imgPath}../../imageTests/rooms/party rooms/compressed/radiant.png")`,
                        }}
                        textContainer={{
                            '& h1': {
                                fontSize: {
                                    sm: '44px',
                                    xs: '32px'
                                }
                            },
                            '& a': {
                                transition: '.3s all',
                                '&:hover': {
                                    backgroundColor: '#FFF',
                                    color: 'rgb(175,70,250) !important'
                                }
                            },
                        }}
                        imageContainer={{
                            position: {
                                sm: 'relative',
                                xs: 'absolute'
                            },
                            '& img': {
                                position: {
                                    md: 'absolute',
                                    xs: 'unset'
                                },
                                width: {
                                    md: 'unset',
                                    xs: '100%'
                                },
                                height: 'auto'
                            }
                        }}
                    />
                </Box>

                {/* GALLERY */}
                <Box sx={defaultContainerSpacingBottom}>

                    <Box className={["container"]} sx={{ marginTop: '80px' }}>
                        <Text header={partyRoom[language]['gallery']} headerStyle={{ marginBottom: '50px', marginX: 'auto', textAlign: 'center' }} />
                        <GridBox
                            gap={2}
                            gridTemplateColumns={{
                                xs: "repeat(2, 1fr)",
                                sm: "repeat(3, 1fr)",
                                md: "repeat(4, 1fr)",
                            }}
                            gridTemplateRows={{
                                xs: "repeat(3, 1fr)",
                                sm: "repeat(3, 1fr)",
                                md: "repeat(2, 1fr)",
                            }}
                        >
                            <GalleryBox galleryImages={galleryImages} />
                        </GridBox>
                    </Box>

                </Box>
            </Box>
            {/* ADD */}
            <Box sx={containerSpacing}>
                <AddBanner
                    mainContainer={{
                        background: "linear-gradient(180deg, rgba(70,0,190,1) 0%, rgba(105,25,221,1) 43%, rgba(166,63,246,1) 78%)",
                        'img': {
                            rotate: '50deg'
                        }
                    }}
                    headerStyle={{
                        fontSize: {
                            sm: '3rem',
                            xs: '29px'
                        }
                    }}
                    descStyle={{
                        width: {
                            sm: '470px',
                            xs: '100%'
                        },
                    }}
                    header={lng[language]['cardAddHeader2']}
                    desc={lng[language]['cardAddDesc']}
                    imageUrl={"https://hyper-active.com/images/content/GiftCard.png"}
                />
            </Box>
            <Box sx={mainContainer}>
            {/* Reservation Form */}
            <Box sx={defaultContainerSpacing}>
                <Box
                    sx={{ borderRadius: '40px', border: 'solid 3px #AF46FA' }}>
                    <Text
                        header={partyRoom[language]['formHeader']}
                        containerStyling={{
                            textAlign: 'center', position: 'relative', marginX: 'auto',
                            '&::before': { borderTopLeftRadius: '30px', borderTopRightRadius: '30px', width: 1, height: 1, content: "''", position: 'absolute', backgroundColor: '#AF46FA' }
                        }}
                        headerStyle={{ fontWeight: 700, zIndex: 1, marginBottom: '25px', marginTop: '20px', fontSize: { sm: '44px', xs: '30px' } }}
                    />
                
                    <Box component={'form'} sx={[{ width: { sm: '70%', xs: '90%' }, marginX: 'auto' }, defaultContainerSpacing]} onSubmit={(e) => handleSubmit(e)}> 
                        <Box sx={{ rowGap: '20px', display: 'grid', columnGap: '20px', gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr' }}}>
                            {inputs.map((data, index) => (data.size === 'double' && data.typeOf === 'Normal' ?
                                <Box key={index}>
                                    <InputLabel shrink id={data.id} sx={{color: '#FFF', fontSize: '20px'}}>{data.label}</InputLabel>
                                    <TextField error={data.error} required={data.required} fullWidth id={data.id} sx={data.styling} onChange={(e) => data.multiCall(e)} type={data.type} defaultValue={data.value} />
                                </Box>
                                : data.typeOf === 'Date' ?
                                    <Box key={index}>
                                        <InputLabel shrink id={data.id} sx={{color: '#FFF', fontSize: '20px'}}>{data.label}</InputLabel>
                                        <Box sx={data.styling}>
                                            <Box component={DatePicker} selected={data.value} onChange={(date) => data.multiCall(date)} minDate={new Date()} />
                                        </Box>
                                    </Box> : data.typeOf === "Select" ?
                                        <Box key={index}>
                                            <InputLabel shrink id={data.id} sx={{color: '#FFF', fontSize: '20px'}}>{data.label}</InputLabel>
                                            <FormControl fullWidth required={data.required}>
                                                <Select
                                                    labelId={data.id}
                                                    id={data.id}
                                                    value={data.value}
                                                    onChange={(e) => data.multiCall(e)}
                                                    sx={data.styling}
                                                >
                                                    <MenuItem value={1}>{partyRoom[language]['firstPackage']}</MenuItem>
                                                    <MenuItem value={2}>{partyRoom[language]['secondPackage']}</MenuItem>
                                                    <MenuItem value={3}>{partyRoom[language]['thirdPackage']}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        :
                                        ''
                            ))}
                        </Box>

                        <Box sx={{ rowGap: '20px', display: 'grid', columnGap: '20px', gridTemplateColumns: '1fr', marginTop: '30px' }}>
                            {inputs.map((data, index) => (data.size === 'single' && data.typeOf === 'Single' &&
                                <Box key={index}>
                                    <InputLabel shrink id={data.id} sx={{ color: '#FFF', fontSize: '20px' }}>{data.label}</InputLabel>
                                    <TextField multiline={data.multiline} error={data.error} required={data.required} fullWidth id={data.id} minRows={'6'} sx={data.styling} onChange={(e) => data.multiCall(e)} type={data.type} defaultValue={data.value} />
                                </Box>
                            ))}
                        </Box>
                        <Box marginY={2}>
                            <Recapcha change={captchaChange} state={recatpchaVal} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                            <Button type='submit' variant='contained' disabled={!recatpchaVal} sx={{ backgroundColor: '#321EFF', boxShadow: 'none', paddingY: 2, paddingX: 5, color: '#FFF', borderRadius: '40px', '&.Mui-disabled': {border: 'solid 1px red'}, '&:hover': { backgroundColor: '#FFF', '.MuiTypography-body1': { color: '#321EFF' } } }}>
                                <Typography variant="body1" color="#FFF" fontWeight={'500'}>{partyRoom[language]['submitBnt']}</Typography>
                            </Button>
                        </Box>
                    </Box>

                </Box>

            </Box>

            {/* Call Now Component */}
            <Box sx={defaultContainerSpacing}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Text
                        header={partyRoom[language]['rezNow']}
                        desc={partyRoom[language]['contactTeam']}
                        containerStyling={{ textAlign: 'center', marginBottom: '20px' }}
                        headerStyle={{ fontWeight: 700, marginBottom: '20px' }}
                        descStyling={{ marginX: 'auto', width: language === "alb" ? '280px' : '361px !important' }}
                    />
                    <Button variant='contained' sx={{ backgroundColor: '#F03C46', color: '#FFF', borderRadius: '40px', paddingX: 3, paddingY: 1.5, '&:hover': { backgroundColor: '#FFF', 'h6': { color: '#F03C46' } } }} href="tel:+38349909196">
                        <Typography variant="h6" color="#FFF">{partyRoom[language]['callNow']}</Typography>
                    </Button>
                </Box>
            </Box>
            </Box>

            {
                success &&
                <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={msg.type}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {
                            msg.type === "success" ?
                            language === "alb" ?
                            'Email-i u dërgua me sukses!'
                            :
                            'Email sent succesfully!'
                            :
                            language === "alb" ?
                            'Email-i nuk u dergua ju lutem mbushni te gjitha format siq duhet'
                            :
                            'Email was not sent. Make sure fomr is filled correctly!'
                        }
                    </Alert>
                </Snackbar>
            }

        </Box>
    )
}