import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

export default function FoodTab(props) {

    const [value, setValue] = React.useState(0);
  
    const handleChange = (event) => {
      setValue(event);
    };

    return (
        <Box>
            
            <ButtonGroup
                variant="contained" 
                aria-label="outlined primary button group" 
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box component={'ul'} sx={{
                    paddingX: '0',
                    display: 'flex',
                    overflow: 'scroll',
                    paddingBottom: '30px',
                }}>
                {
                    props.test &&
                    props.test.map((data, index) => (
                        <Box component='li' key={index} marginLeft={index === 0 ? '0' : '10px'} marginRight={index == props.test.length ? '0' : '10px'} sx={{listStyle: 'none', justifySelf: 'center', minWidth: 'fit-content'}}>
                            <Button 
                                value={index} 
                                onClick={(e) => handleChange(e.target.value)}
                                aria-selected={value == index  ? true : false}
                                sx={{
                                    backgroundColor: 'transparent',
                                    borderRadius: '20px !important',
                                    border: 'solid 1px #F03C44 !important',
                                    "&:hover": {
                                        backgroundColor: '#F03C44'
                                    },
                                    "&[aria-selected=true]": {
                                        backgroundColor: '#F03C44'
                                    }
                                }}
                            >
                                {data.tabHeader}
                            </Button>
                        </Box>
                    ))
                }
                </Box>
                {
                    props.test &&
                    props.test.map((data, index) => (
                        <Box component='li' key={index} sx={{display: value == index ? 'block' : 'none', marginTop: '30px'}}>
                            <Box
                                sx={{
                                    
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    gridGap: '20px'
                                }}
                            >
                                {
                                    data.tabData.map((data, index) => (
                                        <Box key={index}>
                                            {data.imgUrl && <Box component={'img'} src={data.imgUrl} />}
                                            {data.title && <Typography fontSize={'25px'} fontWeight={600} marginBottom={'15px'}>{data.title} {data.price}&euro;</Typography>}
                                            {data.type && <Typography component={'p'} sx={{whiteSpace: 'pre-line'}} variant='body2'>{data.type}</Typography>}
                                        </Box>       
                                    ))
                                }
                            </Box>
                        </Box>
                    ))
                }
            </ButtonGroup>

        </Box>
    )
}
