import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../components/form/Input";
import { useParams } from "react-router-dom";
import { api } from "../../../api/config";
import style from "./General.module.css";
import Wrapper from "../../../components/general/Wrapper";

function ChangePasswordForm(props) {
  let { token } = useParams();

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Kjo fushë është e domosdoshme!")
      .min(6, "Fjalëkalimi duhet të ketë të paktën 6 karaktere"),
    passwordConfirmation: Yup.string()
      .required("Kjo fushë është e domosdoshme!")
      .oneOf([Yup.ref("password"), null], "Fjalëkalimet nuk përputhen"),
  });

  return (
    <Wrapper>
      <Formik
        initialValues={{ password: "" }}
        validationSchema={changePasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.setLoading(true);
          api
            .post("auth/changePassword", {
              token: token,
              password: values.password,
            })
            .then((response) => {
              if (response.data.success) {
                props.setLoading(false);
                props.setChangePasswordStep(1);
              } else {
                props.setLoading(false);
                props.setChangePasswordStep(2);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }}
      >
        {({ errors, handleChange, handleSubmit, touched }) => (
          <form onSubmit={handleSubmit} className={style.loginForm}>
            <div className="mb10">
              <Input
                placeholder={"Fjalëkalimi"}
                type="password"
                name="password"
                onChange={handleChange}
                errorMessage={errors.password}
                touched={touched.password}
              />
            </div>
            <div className="mb10">
              <Input
                placeholder={"Rishkruaj Fjalëkalimin"}
                type="password"
                name="passwordConfirmation"
                onChange={handleChange}
                errorMessage={errors.passwordConfirmation}
                touched={touched.passwordConfirmation}
              />
            </div>
            <button type="submit" className="btn">
              {"Ndrysho Fjalëkalimin"}
            </button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}
export default ChangePasswordForm;
