import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const tabStyle = {
  marginRight: '10px',
  transition: '.3s all',
  paddingBottom: 0,
  fontWeight: 700,
  '&.Mui-selected': {
    color: '#FD0807'
  }
}

const tabsStyle = {
  '.MuiTabs-indicator': {
    backgroundColor: '#FD0807'
  },
  '.MuiTabs-flexContainer': {
    md: {
      justifyContent: 'center'
    },
    xs: {
      justifyContent: 'unset'
    }
  }
}

export default function TabsComp({ tabInfo }) {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'transparent' }}>
        <Tabs value={value} onChange={handleChange} variant='scrollable' scrollButtons={true} allowScrollButtonsMobile={true} sx={tabsStyle}>
          {tabInfo.map((data, index) => (
            <Tab key={index} disableRipple sx={tabStyle} value={index} label={data.tabInfo.label} />
          ))}
        </Tabs>
        <Box>
          {tabInfo[value].children}
        </Box>
      </Box>
    </Box>
  )
}