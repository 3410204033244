import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../../api/config";
// import { IconPageMessage } from "../../../components/general/message/IconPageMessage";
import LottieMessage from "../../../components/general/message/LottieMessage";

function RegisterVerification() {
  const [message, setMessage] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    //Check Email Verification
    api
      .post("auth/verification", { id: id})
      .then((response) => {
        setMessage(response.data.type);
      })
      .catch((error) => {
        // console.error(error);
      });
      // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      {message !== "error" ? (
         <LottieMessage
          text="Llogaria juaj u aktivizua me sukses!"
          type="success"
          linkText = "Kyçu"
          linkTo = "/"
        />
      ) : (
        <LottieMessage
          text="Oops! Linku për verifikimin e llogarisë suaj nuk është valid!"
          type="error"
          linkTo = "/"
        />
      )}
    </div>
  );
}
export default RegisterVerification;
