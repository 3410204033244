import React from 'react';
import Box from '@mui/material/Box';
import CarouselGallery from './Carusel';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import AwesomeSlider from "react-awesome-slider";

export default function ShowImageModule({ data, style, extraProperties}) {
    return (
        <Box sx={style ? style : {}}>
            {
                extraProperties?.carousel &&
                <CarouselGallery
                    radius={'20px'}
                    gallery={data ? data : ['https://hyper-active.com/assets/images/new_main_banner.png', 'https://hyper-active.com/assets/images/new_main_banner.png', 'https://hyper-active.com/assets/images/new_main_banner.png']}
                />
            }
            {
                Array.isArray(data) && data.length > 1  &&
                    <Box
                        play
                        animation="scaleOutAnimation"
                        component={AwesomeSlider}
                        sx={{
                            '.awssld__content': {
                                background: 'transparent'
                            },
                            '.awssld__bullets': {
                                zIndex: 2,
                                height: '110px'
                            }
                        }}
                        bullets={true}
                        infinite={true}
                        interval={3000}
                        mobileTouch={true}
                        organicArrows={false}
                        buttonContentLeft={false}
                        buttonContentRight={false}
                        cancelOnInteraction={false}
                        className={style.carouselGalleryContainer}
                    >
                        {
                            data.map((image, index) => (
                                <Box>
                                    <Box
                                        alt="single"
                                        component={'img'}
                                        key={index}
                                        src={image['data_url']}
                                    />
                                </Box>
                            ))
                        }
                    </Box>
            }
              {
                Array.isArray(data) && data.length === 1  &&
                    <Box
                        component={AwesomeSlider}
                        play={true}
                        bullets={true}
                        interval={3000}
                        organicArrows={false}
                        buttonContentLeft={false}
                        buttonContentRight={false}
                        cancelOnInteraction={false}
                        className={style.carouselGalleryContainer}
                    >
                        {
                            data.map((image, index) => (
                                <Box>
                                    <Box
                                        alt="single"
                                        component={'img'}
                                        key={index}
                                        src={image['data_url']}
                                    />
                                </Box>
                            ))
                        }
                    </Box>
            }
            {
                Array.isArray(data) === false &&
                <Box src={data ? data : 'https://hyper-active.com/assets/images/new_main_banner.png'} component={'img'} />
            }
        </Box>
    )
}