import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const skeletonStyle = {
    backgroundColor: 'rgba(129, 129, 129, 0.5)',
    '::after': {
    background: 'linear-gradient( 90deg, transparent, rgba(112, 112, 112, 0.18), transparent )'
    }
}

export default function ReservationLoader() {
    return (
        <Box sx={{display: 'grid', gridGap: '30px'}}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <Skeleton variant="circular" width={42} height={42} sx={skeletonStyle} />
                <Box sx={{marginLeft: '10px'}}>
                    <Skeleton variant="rounded" width={190} height={36} sx={[skeletonStyle, {marginBottom: '10px'}]} />
                    <Skeleton variant="rounded" width={190} height={25} sx={skeletonStyle} />
                </Box>
            </Box>
            <Box sx={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', alignItems: 'center', justifyItems: 'center', position: 'relative'}}>
                <Skeleton variant="circular" width={30} height={30} sx={[skeletonStyle, {position: 'absolute', left: '-5px'}]} />
                <Skeleton variant="rounded" width={170} height={70} sx={[skeletonStyle]} />
                <Skeleton variant="rounded" width={170} height={70} sx={[skeletonStyle]} />
                <Skeleton variant="rounded" width={170} height={70} sx={[skeletonStyle]} />
                <Skeleton variant="circular" width={30} height={30} sx={[skeletonStyle, {position: 'absolute', right: '-5px'}]} />
            </Box>

            <Box>
                <Box display={'flex'}>
                    <Skeleton variant="rounded" width={40} height={30} sx={[skeletonStyle, {marginRight: '19px'}]} />
                    <Skeleton variant="rounded" width={40} height={30} sx={[skeletonStyle]} />
                </Box>
                <Skeleton variant="text" width={100} height={30} sx={[skeletonStyle]} />
            </Box>

            <Box sx={{gridGap: '20px', display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)'}}>
                {
                    Array.from(new Array(24)).map((_, index) => (
                        <Skeleton
                            key={index}
                            animation="wave"    
                            variant="rounded"
                            sx={[{
                                width: '80px',
                                height: '30px',
                                
                            }, skeletonStyle]}
                        />
                    ))
                }
            </Box>
        </Box>
    )
}