import style from "../ReservationLayout.module.css";
import React from "react";

function KartingParams(props) {
    
    const checkProps = () => {
        let types = [
            {
                type: "Single Race",
                id: "std",
            },
            {
                type: "Family Race",
                id: "fam",
            },
        ];
        if (props.reservationInfo.children === 0 ? false : true) {
            return types = [
                {
                    type: "DUO",
                    id: "std",
                },
            ];
        }else{
            return types
        }
    }

    const [value, setValue] = React.useState(checkProps()[0].id);
    const changeSlots = (type) => {
        props.setKartingType(type);
        if (type === 'dbl') props.setReservationInfo({ ...props.activeReservationInfo, time: "20" });
        else props.setReservationInfo({ ...props.activeReservationInfo, time: "10" });    
    }

    React.useEffect(() => {
        changeSlots(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <div>
            <div className={style.slotsParams}>
                {checkProps().map((i, j) => {
                    return (
                        <button
                            key={j}
                            onClick={() => setValue(i.id)}
                            className={`${props.kartingType === i.id ? style.activeTime : ''}`}
                        >
                            {i.type}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}
export default KartingParams;
