import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

const cardTitle = {
    color: '#59644B'
}

const cardDesc = {
    color: '#6c6c6c',
    whiteSpace: 'pre-line'
}

const container = {
    marginTop: 5,
    display: 'grid',
    gridTemplateColumns: {
        xl: 'repeat(5, 1fr)',
        md: 'repeat(4, 1fr)',
        xs: 'repeat(1, 1fr)'
    },
    columnGap: '20px',
    rowGap: '20px'
}

export default function MenuCard(props) {

    return (
        <Box sx={container}>
            {
                props.menuData && props.menuData.map((data, index) => (
                    <Box component={Link} to={`${data.id}`} key={index} sx={{textDecoration: 'none', cursor: 'pointer'}}>
                        {/* <Box>
                            <Box sx={{
                                backgroundImage: `url('${data.imgUrl}')`,
                                width: '100%',
                                height: '180px',
                                borderRadius: '15px',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundOrigin: 'border-box',
                                backgroundSize: 'cover'
                            }} alt={data.imgAlt} />
                        </Box> */}
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography variant="h6" fontWeight={'bold'} fontSize={17} sx={cardTitle}>{data.title}</Typography>
                            <Typography variant="h6" fontWeight={'bold'} fontSize={17} sx={cardTitle}>{data.price}&euro;</Typography>
                        </Box>
                        <Box sx={{marginTop: 1}}>
                            <Typography variant="body2" sx={cardDesc}>{data.desc}</Typography>
                        </Box>
                    </Box>
                ))
            }
        </Box>
    )
}