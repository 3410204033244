import React from 'react'
import FetchData from '../../../api/FetchData'
import { Box } from '@mui/material'
import ModuleLoader from '../../../components/modules/ModuleLoader'
import WaitingLoader from '../../../components/general/loader/WaitingLoader'

export default function NewComp() {

    const [modules, setModules] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    React.useState(() => {
        FetchData.create('/content/getUploadedData', 1)
            .then(res => {
                setModules(JSON.parse(res.data.data.content_json))
                setLoading(false);
            })
            .catch(err => {
            })
    }, [window.location.pathname])

    return (
        <>
            {
                loading === false ?
                    modules &&
                    <Box>
                        {
                            modules.map((data, index) => (
                                <Box key={index}>
                                    <Box sx={[data.style, { color: '#FFF' }]}>
                                        {
                                            data?.extraProps?.image?.active === "true" &&
                                            <Box sx={data?.extraProps?.image?.imageContainerStyle}>
                                                <Box component={'img'} src={data?.extraProps?.image?.imageUrl} sx={data?.extraProps?.image?.imageStyle} />
                                            </Box>
                                        }
                                        <ModuleLoader
                                            data={data?.children}
                                        />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                    :
                    <WaitingLoader open={loading} />
            }
        </>
    )
}