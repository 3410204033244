import style from "./Loader.module.css";
import loader from "../../../utils/lotties/lightning.json";
import Lottie from "lottie-react";

export const Loader = (props) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={`${props.hasPadding ?  style.spinnerContainer : style.noPaddingSpinner }  =`}>
      <Lottie options={defaultOptions} height={350} width={350} />
    </div>
  );
};

export default Loader;
