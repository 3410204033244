import React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import lng from '../../../utils/language';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Wrapper from '../../../components/general/Wrapper';
import { Checkbox, Typography } from '@mui/material';
// import { BorderColor } from '@mui/icons-material';

export default function NewRegister({ form, setForm, isForm }) {

    const { language } = useSelector((state) => state.language);

    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

    const months = {
        alb: [
            "Janar",
            "Shkurt",
            "Mars",
            "Prill",
            "Maj",
            "Qërshor",
            "Korrik",
            "Gusht",
            "Shtator",
            "Tetor",
            "Nëntor",
            "Dhjetor"
        ],
        eng: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
    };

    const [userInfo, setUserInfo] = React.useState({
        name: "",
        gender: "",
        lastname: "",
        fullname: "",
        email: "",
        mob: "",
        day: "",
        month: "",
        year: "",
        password: "",
        confirmPassword: '',
        city: "",
        country: "",
        terms: false,
        policy: false,

        
    })

    const [userInfoError, setUserInfoError] = React.useState({
        // ERRORS
        nameError: {
            error: false,
            // msg: lng[language]['']
            msg: ''
        },
        genderError: {
            error: false,
            msg: ''
        },
        lastnameError: {
            error: false,
            msg: ''
        },
        fullnameError: {
            error: false,
            msg: ''
        },
        emailError: {
            error: false,
            msg: ''
        },
        mobError: {
            error: false,
            msg: ''
        },
        dayError: {
            error: false,
            msg: ''
        },
        monthError: {
            error: false,
            msg: ''
        },
        yearError: {
            error: false,
            msg: ''
        },
        passwordError: {
            error: false,
            msg: ''
        },
        confirmPasswordError: {
            error: false,
            msg: ''
        },
        cityError: {
            error: false,
            msg: ''
        },
        countryError: {
            error: false,
            msg: ''
        },
        termsError: {
            error: false,
            msg: ''
        },
        policyError: {
            error: false,
            msg: ''
        }
    })

    const inputStyling = {
        backgroundColor: '#120f2e',
        borderRadius: '20px',
        outline: 'none',
        border: '1px solid #120f2e',
        padding: '10px 15px',
        width: '100%',
        boxSizing: "border-box",
        color: '#FFF',
        fontSize: '14px',
        input: {
            color: '#FFF',
            fontSize: '14px'
        }
    }

    const inputs = {
        inputName: (e) => {
            let value = e.target.value
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
               
            }
            setUserInfo({...userInfo, name: value})
        },
        inputLastName: (e) => {
            let value = e.target.value
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {

            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
               
            }
            setUserInfo({...userInfo, lastname: value})
        },
        inputGender: (e) => {
            let value = e.target.value
            setUserInfo({ ...userInfo, gender: value })
        },
        inputEmail: (e) => {
            let value = e.target.value
            setUserInfo({ ...userInfo, email: value })
        },
        inputMob: (e) => {
            let value = e.target.value
            setUserInfo({ ...userInfo, mob: value })
        },
        inputDay: (e) => {
            let value = e.target.value
            setUserInfo({ ...userInfo, day: value })
        },
        inputMonth: (e) => {
            let value = e.target.value
            setUserInfo({ ...userInfo, month: value })
        },
        inputYear: (e) => {
            let value = e.target.value
            setUserInfo({ ...userInfo, year: value })
        },
        inputPassword: (e) => {
            let value = e.target.value
            setUserInfo({ ...userInfo, password: value })
        },
        inputConfirmPassword: (e) => {
            let value = e.target.value
            if (value !== "") {
                if (userInfo.password !== value) {
                    setUserInfoError({
                        ...userInfoError, confirmPasswordError: {
                            error: true,
                            msg: lng[language]["doNotMatch"]
                        }
                    })
                }
            }
            setUserInfo({ ...userInfo, confirmPassword: value })
        },
        inputCity: (e) => {
            let value = e.target.value;
            if (!value) {
                setUserInfo({
                    ...userInfo, cityError: {
                        error: true,
                        msg: lng[language]["required"]
                    }, city: value
                })
            } else {
                setUserInfo({
                    ...userInfo, cityError: {
                        error: false,
                        msg: ''
                    }, city: value
                })
            }
        },
        inputCountry: (e) => {
            let value = e.target.value
            if (!value) {
                setUserInfo({
                    ...userInfo, countryError: {
                        error: true,
                        msg: lng[language]["required"]
                    }, country: value
                })
            } else {
                setUserInfo({
                    ...userInfo, countryError: {
                        error: false,
                        msg: ''
                    }, country: value
                })
            }
        },
        inputTerms: (e) => {
            if (userInfo.terms === false) {
                setUserInfo({
                    ...userInfo, termsError: {
                        error: true,
                    }, country: !userInfo.terms
                })
            } else {
                setUserInfo({
                    ...userInfo, termsError: {
                        error: false,
                    }, country: !userInfo.terms
                })
            }

        },
        inputPolicy: () => {
            if (userInfo.policy === false) {
                setUserInfo({
                    ...userInfo, policyError: {
                        error: false,
                    }, country: !userInfo.policy
                })
            } else {
                setUserInfo({
                    ...userInfo, policyError: {
                        error: true,
                    }, country: !userInfo.policy
                })
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <Box>
            <Wrapper
                title={lng[language]["register"]}
                logoTitle="Aventura Fillon tani!"
                isForm={isForm}
            >

                <Box component={'form'} onSubmit={(e) => handleSubmit(e)}>

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            sm: 'repeat(2, 1fr)',
                            xs: 'repeat(1, 1fr)'
                        },
                        columnGap: '25px',
                        rowGap: '25px'
                    }}>
                        {/* NAME */}
                        <Box position={'relative'}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["name"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { marginTop: 1 }, userInfoError.nameError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["name"]}
                                required={true}
                                defaultValue={userInfo.name}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputName(e)}
                            />
                            {
                                userInfoError.nameError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.nameError.msg
                                    }
                                </Typography>
                            }
                        </Box>
                        {/* LASTNAME */}
                        <Box position={'relative'}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["lastname"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { color: '#FFF', marginTop: 1 }, userInfoError.lastnameError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["lastname"]}
                                required={true}
                                defaultValue={userInfo.lastname}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputLastName(e)}
                            />
                            {
                                userInfoError.lastnameError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.lastnameError.msg
                                    }
                                </Typography>
                            }
                        </Box>
                        {/* EMAIL */}
                        <Box position={'relative'}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["email"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { color: '#FFF', marginTop: 1 }, userInfoError.emailError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["email"]}
                                required={true}
                                defaultValue={userInfo.email}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputEmail(e)}
                            />
                            {
                                userInfoError.emailError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.emailError.msg
                                    }
                                </Typography>
                            }
                        </Box>
                        {/* MOBILE NUMBER */}
                        <Box position={'relative'}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["mob"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { color: '#FFF', marginTop: 1 }, userInfoError.mobError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["mob"]}
                                required={true}
                                defaultValue={userInfo.mob}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputMob(e)}
                            />
                            {
                                userInfoError.mobError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.mobError.msg
                                    }
                                </Typography>
                            }
                        </Box>
                        {/* PASSWORD */}
                        <Box position={'relative'}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["password"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { color: '#FFF', marginTop: 1 }, userInfoError.passwordError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["password"]}
                                required={true}
                                defaultValue={userInfo.password}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputPassword(e)}
                            />
                            {
                                userInfoError.passwordError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.passwordError.msg
                                    }
                                </Typography>
                            }
                        </Box>
                        {/* CONFIRM PASSWORD */}
                        <Box position={'relative'}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["passwordConfirmation"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { color: '#FFF', marginTop: 1 }, userInfoError.confirmPasswordError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["passwordConfirmation"]}
                                required={true}
                                defaultValue={userInfo.confirmPassword}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputConfirmPassword(e)}
                            />
                            {
                                userInfoError.confirmPasswordError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.confirmPasswordError.msg
                                    }
                                </Typography>
                            }
                        </Box>
                        {/* COUNTRY */}
                        <Box position={'relative'}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["city"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { color: '#FFF', marginTop: 1 }, userInfoError.cityError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["city"]}
                                required={true}
                                defaultValue={userInfo.city}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputCity(e)}
                            />
                            {
                                userInfoError.cityError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.cityError.msg
                                    }
                                </Typography>
                            }
                        </Box>
                        {/* CITY */}
                        <Box position={'relative'} sx={{ position: 'relative' }}>
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["country"]}</InputLabel>
                            <TextField
                                sx={[inputStyling, { color: '#FFF', marginTop: 1 }, userInfoError.countryError.error ? { borderColor: '#d32f2f' } : {}]}
                                placeholder={lng[language]["country"]}
                                required={true}
                                defaultValue={userInfo.country}
                                variant='standard'
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => inputs.inputCountry(e)}
                            />
                            {
                                userInfoError.countryError.error &&
                                <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                    {
                                        userInfoError.countryError.msg
                                    }
                                </Typography>
                            }
                        </Box>

                        {/* DOB */}
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', columnGap: '10px', justifyItems: 'stretch' }}>
                            {/* DAY */}
                            <Box position={'relative'}>
                                <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["day"]}</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        sx={[inputStyling, { color: '#FFF', marginTop: 1, '.MuiSelect-select': { padding: '3px 20px' }, svg: { color: '#FFF' } }, userInfoError.dayError.error ? { borderColor: '#d32f2f' } : {}]}
                                        value={userInfo.day}
                                        onChange={(e) => inputs.inputDay(e)}
                                    >
                                        {
                                            Array.from({ length: 31 }, (_, i) => i + 1).map((data, index) => (
                                                <MenuItem value={index + 1} key={index}>
                                                    {data}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                {
                                    userInfoError.dayError.error &&
                                    <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                        {
                                            userInfoError.dayError.msg
                                        }
                                    </Typography>
                                }
                            </Box>
                            {/* MONTH */}
                            <Box position={'relative'}>
                                <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["month"]}</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        sx={[inputStyling, { color: '#FFF', marginTop: 1, '.MuiSelect-select': { padding: '3px 20px' }, svg: { color: '#FFF' } }, userInfoError.monthError.error ? { borderColor: '#d32f2f' } : {}]}
                                        value={userInfo.month}
                                        onChange={(e) => inputs.inputMonth(e)}
                                    >
                                        {
                                            months[language].map((data, index) => (
                                                <MenuItem value={index + 1} key={index}>
                                                    {data}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                {
                                    userInfoError.monthError.error &&
                                    <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                        {
                                            userInfoError.monthError.msg
                                        }
                                    </Typography>
                                }
                            </Box>
                            {/* YEAR */}
                            <Box position={'relative'}>
                                <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["year"]}</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        sx={[inputStyling, { color: '#FFF', marginTop: 1, '.MuiSelect-select': { padding: '3px 20px' }, svg: { color: '#FFF' } }, userInfoError.yearError.error ? { borderColor: '#d32f2f' } : {}]}
                                        value={userInfo.year}
                                        onChange={(e) => inputs.inputYear(e)}
                                    >
                                        {
                                            range(currentYear, currentYear - 50, -1).map((data, index) => (
                                                <MenuItem value={data} key={index}>
                                                    {data}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                {
                                    userInfoError.yearError.error &&
                                    <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                        {
                                            userInfoError.yearError.msg
                                        }
                                    </Typography>
                                }
                            </Box>
                        </Box>

                        {/* GENDER */}
                        <Box>
                            <Box position={'relative'}>
                                <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]["gender"]}</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        sx={[inputStyling, { color: '#FFF', marginTop: 1, '.MuiSelect-select': { padding: '3px 20px' }, svg: { color: '#FFF' } }, userInfoError.genderError.error ? { borderColor: '#d32f2f' } : {}]}
                                        value={userInfo.gender}
                                        onChange={(e) => inputs.inputGender(e)}
                                    >
                                        {
                                            Array.from([{ value: "f", label: "Female" }, { value: "m", label: "Male" }]).map((data, index) => (
                                                <MenuItem value={data.value} key={index}>
                                                    {data.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                {
                                    userInfoError.genderError.error &&
                                    <Typography variant='subtitle2' sx={{ color: '#d32f2f', fontSize: '12px', marginLeft: '10px' }}>
                                        {
                                            userInfoError.genderError.msg
                                        }
                                    </Typography>
                                }
                            </Box>
                        </Box>

                    </Box>

                    <Box sx={{ width: '600px', marginX: 'auto' }}>
                        {/* TERMS */}
                        <Box position={'relative'} sx={{ display: 'flex', marginTop: 5, alignItems: 'center' }}>
                            <Checkbox
                                sx={{
                                    '.MuiCheckbox-root': {
                                        padding: 0,
                                        borderRadius: 0,
                                    },
                                    svg: {
                                        backgroundColor: '#120f2e',
                                        border: userInfoError.termsError.error === true ? 'solid 1px red' : 'unset'
                                    },
                                    '&.Mui-checked': {
                                        color: '#321eff'
                                    }
                                }}
                                onChange={(e) => inputs.inputTerms(e)}
                                checked={userInfo.temrs}
                            />
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF', overflow: 'visible', whiteSpace: 'unset' }}>{lng[language]["terms"]}</InputLabel>
                        </Box>
                        {/* POLICIY */}
                        <Box position={'relative'} sx={{ display: 'flex', marginTop: 3, alignItems: 'center' }}>
                            <Checkbox
                                sx={{
                                    '.MuiCheckbox-root': {
                                        padding: 0,
                                        borderRadius: 0,
                                    },
                                    svg: {
                                        backgroundColor: '#120f2e',
                                        border: userInfoError.policyError.error === true ? 'solid 1px red' : 'unset'
                                    },
                                    '&.Mui-checked': {
                                        color: '#321eff'
                                    }
                                }}
                                onChange={(e) => inputs.inputPolicy(e)}
                                checked={userInfo.policy}
                            />
                            <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF', overflow: 'visible', whiteSpace: 'unset' }}>{lng[language]["policy"]}</InputLabel>
                        </Box>
                    </Box>

                    <Box sx={{ marginTop: 5, display: 'flex', justifyContent: 'center' }}>
                        <button type="submit" className="btn">
                            {lng[language]["register"]}
                        </button>
                    </Box>

                </Box>

            </Wrapper>

        </Box>
    )
}