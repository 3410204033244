import { Link } from "react-router-dom";
import style from "./General.module.css";

function Logo(props) {
  return (
    <Link to="/" className={`${style.logo} ${style[props.styleProp]}`}>
      <img src="/assets/images/logo.png" alt="Logo" />
      <span>{props.title}</span>
    </Link>
  );
}
export default Logo;
