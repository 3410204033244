import style from "../ReservationLayout.module.css";

function ReservationParamsChanger(props) {
  let options = [];

  if (props && props.parameters) {
    for (var i = 1; i <= parseInt(props.parameters.maxValue); i++) {
      options.push({ value: i, label: i });
    }
  }

  const pickTimePeriod = (time) => {
    if (props.reservationInfo.time !== time) {
      props.setReservationInfo({ ...props.activeReservationInfo, time: time });
    }
  };

  return (
    <div>
      {props && props.reservationInfo && (
        <div className={`${style.flex} ${style.paramsReservation}`}>
          <div>
            <span>Koha (minuta)</span>
            <div>
              {props.reservationInfo.min !== props.reservationInfo.max ? (
                <div className={style.slotsParams}>
                  {(() => {
                    let btn = [];
                    for (
                      let i = parseInt(props.reservationInfo.min);
                      i < parseInt(props.reservationInfo.max);
                      i += parseInt(props.reservationInfo.timeStepValue)
                    ) {
                      btn.push(
                        <button
                          key={i}
                          onClick={() => pickTimePeriod(i)}
                          className={`${
                            parseInt(props.reservationInfo.time) === i
                              ? style.activeTime
                              : ""
                          }`}
                        >
                          {i}
                        </button>
                      );
                    }
                    return btn;
                  })()}
                </div>
              ) : (
                <div className={style.slotsParams}>
                  <button
                    key={props.reservationInfo.min}
                    className={`${style.activeTime}`}
                  >
                    {props.reservationInfo.min}
                  </button>
                </div>
              )}
            </div>
          </div>
          {props.info >= 0 && (
            <div>
              <span>Numri i lojtarëve në slot</span>
              <div className={style.slotsParams}>
                <button className={`${style.activeTime}`}>{props.info}</button>
              </div>
            </div>
          )}
        </div>
      )}
      {props && props.reservationInfo && props.info >= 0 && (
        <p className={style.moreDetails}>
          * Në të njejtin sesion mund të luajnë deri në 20 persona, andaj në
          arenën e betejës mund të jeni edhe me persona të tjerë.
        </p>
      )}
    </div>
  );
}
export default ReservationParamsChanger;
