import { useDispatch, useSelector } from "react-redux";
import style from "../ReservationLayout.module.css";
import Grid from "../../../grid/Grid";
import { useEffect, useMemo, useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import ReactPixel from "../../../../utils/pixel/reactPixel";
import lng from "../../../../utils/language";
import { cartData } from "../../../../app/cart/Cart";
import SkeletonLoader from "../../../../components/general/loader/SkeletonLoader";
import { useNavigate } from "react-router-dom";

function ReservationSlots(props) {
    const { language } = useSelector((state) => state.language);
    const dispatch = useDispatch();
    const {items, errorMessage, isLoading } = useSelector((state) => state.cart);
    const [err, setError] = useState({ error: false, msg: '' })
    const [theme, setTheme] = useState({ type: 0, themeName: "" });
    const navigate = useNavigate();
    useEffect(() => {
        if (slots.length === 1) {
            setTheme({ type: 0, themeName: "" });
        } else setTheme({ type: 1, themeName: "" });
        // eslint-disable-next-line
    }, [items]);
    const isActive = (key, data) => {
        return (
            items.find((item) => {
                return (
                    item.data[0].time === data.slots[0].from &&
                    props.reservationInfo.selectedDate === item.date &&
                    data.slots[0].serviceSubId === item.data[0].serviceSubId
                );
            }) !== undefined
        );
    };
    const updateCart = (key, data, isActive) => {
        if (items.length !== 5) {
            // FACEBOOK TRACKER START
            ReactPixel.track("AddToCart", {
                name: data.slots[0].chargeName,
                date: new Date(),
            });
            // FACEBOOK TRACKER END

            if (!isActive) {
                //Add Cart Dispatcher Formatter - Selected Slot Format
                var slotsParams = [];
                if (data.slots.length === 1) {
                    data.slots.map((i) =>
                        slotsParams.push({
                            time: i.from,
                            serviceId: i.serviceId,
                            serviceSubId: i.serviceSubId,
                            chargeName: i.chargeName,
                            serviceName: i.serviceName,
                            selectedSlot: data.slotDetails,
                            available: i.available,
                        })
                    );
                } else {
                    //For more than one player in Karting
                    if (props?.kartingType && props?.kartingType !== "dbl") {
                        data.slots.map((i, j) =>
                            slotsParams.push({
                                time: i.from,
                                serviceId: i.serviceId,
                                serviceSubId: i.serviceSubId,
                                chargeName: i.chargeName,
                                serviceName: i.serviceName,
                                selectedSlot: [data.slotDetails[j]],
                                available: i.available,
                            })
                        );
                    } else {
                        data.slots.map((i, j) =>
                            slotsParams.push({
                                time: i.from,
                                serviceId: i.serviceId,
                                serviceSubId: i.serviceSubId,
                                chargeName: i.chargeName,
                                serviceName: i.serviceName,
                                selectedSlot: data.slotDetails[j],
                                available: i.available,
                            })
                        );
                    }
                }
                dispatch(cartData({
                    slots: slotsParams,
                    resInfo: props.reservationInfo
                }));
                if (data.slots[0].serviceId === "739734")
                    props.setInfo(
                        parseInt(props.reservationParams[0].maxValue) -
                        parseInt(data.slots[0].available)
                    );
            }
          navigate('../cart')
        } else { setError({ error: true, msg: lng[language]['maxNumber'] }) }
    };
    const alreadySelected = () => { };
    const slots = useMemo(() => {
        if (theme.themeName.length > 0) {
            return props.slots.filter(function (name) {
                return name.type === theme.themeName;
            });
        } else return props.slots;
    }, [props.slots, theme]);
    return (
        <>
            {!props.loader ? (
                <>
                    {errorMessage?.length > 0 && (
                        <p className="notifyMsg">
                            <HiOutlineInformationCircle size={20} />
                            {errorMessage}
                        </p>
                    )}
                    {err?.error > 0 && (
                        <p className="notifyMsg">
                            <HiOutlineInformationCircle size={20} />
                            {err.msg}
                        </p>
                    )}
                    {theme.type === 0 ? (
                        <div className={style.slotsWrapper}>
                            {
                                isLoading &&
                                <div className={style.overlay}>

                                </div>
                            }
                            {slots.length > 0 &&
                                slots.map((s, index) => {
                                    return Object.keys(s.data).length > 0 ? (
                                        <div className={style.slotContainer} key={index}>
                                            {theme.themeName && (
                                                <span className={style.themeNameWrapper}>
                                                    {theme.themeName}
                                                </span>
                                            )}
                                            <h2>
                                                {lng[language]["freeSlots"]}{" "}
                                                {props.reservationInfo.adult +
                                                    props.reservationInfo.children}{" "}
                                            </h2>
                                            <Grid no={6}>
                                                {Object.entries(s.data).map(([key, value], i) => (
                                                    <div className={style.slot} key={i}>
                                                        {/* <p>{props?.reservationParams[0].maxValue - value?.available}</p> */}
                                                        <div
                                                            key={Math.random()}
                                                            className={`${style.slotBox} ${isActive(key, value) && !isLoading
                                                                ? style.activeSlot
                                                                : "deactive"
                                                                }`}
                                                            onClick={() =>
                                                                !isActive(key, value)
                                                                    ? updateCart(key, value)
                                                                    : alreadySelected()
                                                            }
                                                        >
                                                            {key}
                                                        </div>
                                                    </div>
                                                ))}
                                            </Grid>
                                        </div>
                                    ) : (
                                        <p>{lng[language]["noAvailableSlot"]}</p>
                                    );
                                })}
                            {theme.themeName.length > 0 && (
                                <div
                                    className="btn"
                                    onClick={() => setTheme({ type: 1, themeName: "" })}
                                >
                                    Zgjedh një dhomë tjetër
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={style.type}>
                            <p>Zgjedhni dhomën</p>
                            <div className={style.typeWrapper}>
                                <Grid no={2}>
                                    {slots.map((s, index) => {
                                        return Object.keys(s.data).length > 0 ? (
                                            <div
                                                className={style.typeContainer}
                                                onClick={() => setTheme({ type: 0, themeName: s.type })}
                                            >
                                                <span>{index + 1}</span>
                                                <div>
                                                    {s.type}
                                                    <p>{s.desc}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={style.typeContainer}>
                                                <span>{index + 1}</span>
                                                {s.type} <br /> Nuk ka slote aktive
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <SkeletonLoader number={new Array(20)} />
            )}
        </>
    );
}
export default ReservationSlots;
