import React from 'react'
import Iframe from 'react-iframe'
import Box from '@mui/material/Box'

export default function ScoreBoard() {
    return (
        <Box sx={{width: '90%', marginX: 'auto'}}>
            <Iframe
                    frameBorder={0} 
                    url="https://www.apex-timing.com/gokarts/leaderboards.php?center=532"
                    className="axiframe"
                    width="100%"
                    height="1600px"
                />
        </Box>
    )
}