import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
// import ImageTextCard from "../../../components/card/ImageTextCard";
// import Grid from "../../../layout/grid/Grid";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
import React, { useRef } from "react";
// import BigCards from "../../../components/card/BigCard";
import CarouselGallery from "../../../components/carousel/CarouselGallery";
import FullBanner from "../../../components/general/banner/FullBanner";
// import { Link } from "react-router-dom";
import {
    Box,
    // Button, 
    Typography
} from "@mui/material";
import RandomCards from "../../../components/card/RandomCards";
import lng from "../../../utils/language";
import { arcade, lodra } from "../../../utils/gameLng";
import FetchData from "../../../api/FetchData";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
import PartyBanner from "../../../components/general/banner/PartyBanner";
import GridBox from "../../../layout/grid/GridBox";
import GalleryBox from "../../../components/gallery/Gallery";
import Text from "../../../components/text/Text";
import Videos from "../../../components/general/videos/Videos";

function EliPlay() {
    const { language } = useSelector((state) => state.language);

    const games = useRef(null);
    const gamePreparation = useRef(null);
    const rules = useRef(null);
    const gallery = useRef(null);

    const [gameData, setGameData] = React.useState();
    const [loading, setLoading] = React.useState(true)

    const load = () => {
        FetchData.create('/data/getAll', {
            id: 8,
            lng: language === "alb" ? 1 : 2
        })
        .then(res => {
            setGameData(res.data.data)
            setLoading(false);
        })
        .catch(err => {
            // console.log('Error', err);
        })
    }

    React.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language])

    const axe = {
        eng: {
            interest: [
                {
                    interest: "LODRA Playground",
                    ref: games,
                },
                {
                    interest: "The Sweet Factory",
                    ref: gamePreparation,
                },
                {
                    interest: "Food & Drinks",
                    ref: gallery,
                },
                {
                    interest: "Birthday Party",
                    ref: rules,
                },
            ],
            banner:
                "Grafikat futuristike të lojës, dritat dhe sensorët së bashku formojnë një përvojë të paharrueshme të bowling.",
            clickInterest: "Click interests",
        },
        alb: {
            interest: [
                {
                    interest: "LODRA Playground",
                    ref: games,
                },
                {
                    interest: "The Sweet Factory",
                    ref: gamePreparation,
                },
                {
                    interest: "Food & Drinks",
                    ref: gallery,
                },
                {
                    interest: "Birthday Party",
                    ref: rules,
                },
            ],
            games: [
                {
                    title: "Halo Fire Raven",
                    subtitle: "1-2 Lojtarë Mosha: 6+",
                    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do",
                    youtube: "www.google.com",
                    img: constants.imgPath + "FireRaven.png",
                },
                {
                    title: "Fast & Furious",
                    subtitle: "1-2 Lojtarë Mosha: 6+",
                    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do",
                    youtube: "www.google.com",
                    img: constants.imgPath + "FastFurious.png",
                },
                {
                    title: "Mario",
                    subtitle: "1-2 Lojtarë Mosha: 6+",
                    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do",
                    youtube: "www.google.com",
                    img: constants.imgPath + "Mario.png",
                },
            ],
            banner:
                "Grafikat futuristike të lojës, dritat dhe sensorët së bashku formojnë një përvojë të paharrueshme të bowling.",
            clickInterest: "Click interests",
            preparation: [
                {
                    title: "Skuadra",
                    desc: "Formoni një ekip ose një ushtri për një përballje emocionuese me lazer. Ftoni miqtë, familjen ose kolegët t'ju bashkohen në betejë. Ekipi juaj duhet të përbëhet nga të paktën 4 individë, me një maksimum prej 20 personash. Pjesëmarrësit duhet të jenë 6 vjeç ose më të vjetër.",
                },
                {
                    title: "Rezervimi",
                    desc: "Për të siguruar seancën tuaj me ekipin, ju këshillojmë të rezervoni paraprakisht online. Ju keni fleksibilitetin për të rezervuar lojën për 30 ose 60 minuta, ose vetëm një lojë që zgjat 10 minuta.",
                },
                {
                    title: "Ardhja",
                    desc: "Kur të mbërrini, mblidhuni me ekipin tuaj në dhomën e lojës për përgaditje të paktën 30 minuta para seancës tuaj për t'u përgatitur për të shtëna me armë zjarri. Gjatë periudhës së përgatitjes, do të merrni udhëzime mbi rregullat e lojës dhe do të njiheni me pajisjet që do të përdorni gjatë lojës.",
                },
                {
                    title: "Misioni",
                    desc: "Pas mbërritjes, do të keni mundësinë të zgjidhni një mision në të cilin dëshironi të merrni pjesë. Lojërat e disponueshme janë: Delta Tag, One Shot, Agents, Backstabber, Gladiators,One Shot FFA, Elimination, Free For All",
                },
            ],
        },
    };

    // const menuEli = [
    //     {
    //         title: "Ice Cream & Slushies",
    //         subtitle: "This is a subtitle paragraph",
    //         desc: 'A family entertainment center, often abbreviated FEC in the entertainment industry also known as an indoor amusement park, family amusement center, family fun center, or simply fun center, is a small amusement park marketed towards families with small children to teenagers, often entirely indoors. They usually cater to "sub-regional markets of larger metropolitan areas."[1] FECs are generally small compared to full-scale amusement parks, with fewer attractions, a lower per-person per-hour cost to consumers than a traditional amusement park, and not usually major tourist attractions, but sustained by an area customer base.',
    //         img: "https://cdn.apartmenttherapy.info/image/upload/v1559086139/at/archive/9af35e0912d6433f5fb19805edeec83aeaae9529.jpg",
    //     },
    //     {
    //         title: "Ice Cream & Slushies",
    //         subtitle: "This is a subtitle paragraph",
    //         desc: 'A family entertainment center, often abbreviated FEC in the entertainment industry also known as an indoor amusement park, family amusement center, family fun center, or simply fun center, is a small amusement park marketed towards families with small children to teenagers, often entirely indoors. They usually cater to "sub-regional markets of larger metropolitan areas."[1] FECs are generally small compared to full-scale amusement parks, with fewer attractions, a lower per-person per-hour cost to consumers than a traditional amusement park, and not usually major tourist attractions, but sustained by an area customer base.',
    //         img: "https://cdn.apartmenttherapy.info/image/upload/v1559086139/at/archive/9af35e0912d6433f5fb19805edeec83aeaae9529.jpg",
    //     },
    //     {
    //         title: "Ice Cream & Slushies",
    //         subtitle: "This is a subtitle paragraph",
    //         desc: 'A family entertainment center, often abbreviated FEC in the entertainment industry also known as an indoor amusement park, family amusement center, family fun center, or simply fun center, is a small amusement park marketed towards families with small children to teenagers, often entirely indoors. They usually cater to "sub-regional markets of larger metropolitan areas."[1] FECs are generally small compared to full-scale amusement parks, with fewer attractions, a lower per-person per-hour cost to consumers than a traditional amusement park, and not usually major tourist attractions, but sustained by an area customer base.',
    //         img: "https://cdn.apartmenttherapy.info/image/upload/v1559086139/at/archive/9af35e0912d6433f5fb19805edeec83aeaae9529.jpg",
    //     },
    //     {
    //         title: "Ice Cream & Slushies",
    //         subtitle: "This is a subtitle paragraph",
    //         desc: 'A family entertainment center, often abbreviated FEC in the entertainment industry also known as an indoor amusement park, family amusement center, family fun center, or simply fun center, is a small amusement park marketed towards families with small children to teenagers, often entirely indoors. They usually cater to "sub-regional markets of larger metropolitan areas."[1] FECs are generally small compared to full-scale amusement parks, with fewer attractions, a lower per-person per-hour cost to consumers than a traditional amusement park, and not usually major tourist attractions, but sustained by an area customer base.',
    //         img: "https://cdn.apartmenttherapy.info/image/upload/v1559086139/at/archive/9af35e0912d6433f5fb19805edeec83aeaae9529.jpg",
    //     },
    //     {
    //         title: "Ice Cream & Slushies",
    //         subtitle: "This is a subtitle paragraph",
    //         desc: 'A family entertainment center, often abbreviated FEC in the entertainment industry also known as an indoor amusement park, family amusement center, family fun center, or simply fun center, is a small amusement park marketed towards families with small children to teenagers, often entirely indoors. They usually cater to "sub-regional markets of larger metropolitan areas."[1] FECs are generally small compared to full-scale amusement parks, with fewer attractions, a lower per-person per-hour cost to consumers than a traditional amusement park, and not usually major tourist attractions, but sustained by an area customer base.',
    //         img: "https://cdn.apartmenttherapy.info/image/upload/v1559086139/at/archive/9af35e0912d6433f5fb19805edeec83aeaae9529.jpg",
    //     },
    // ];

    // const gallery2 = [
    //     {
    //         imgUrl: `${constants.imgPath}Ellie.png`,
    //     },
    //     {
    //         imgUrl: `${constants.imgPath}Ellie.png`,
    //     },
    //     {
    //         imgUrl: `${constants.imgPath}Ellie.png`,
    //     },
    //     {
    //         imgUrl: `${constants.imgPath}Ellie.png`,
    //     },
    //     {
    //         imgUrl: `${constants.imgPath}Ellie.png`,
    //     },
    // ];

    const items = [
        constants.imgPath + "EliPlay_Group_1171.svg",
        constants.imgPath + "EliPlay_Group_1665.svg",
        constants.imgPath + "EliPlay_Group_1759.svg",
        constants.imgPath + "EliPlay_Group_1785.svg",
        constants.imgPath + "EliPlay_Group_1862.svg",
        constants.imgPath + "EliPlay_Group_1863.svg",
        constants.imgPath + "EliPlay_Group_1865.svg",
        constants.imgPath + "EliPlay_Group_1867.svg",
        constants.imgPath + "EliPlay_Group_1869.svg",
        constants.imgPath + "EliPlay_Group_1872.svg",
    ];

    const containerSpacing = {
        paddingY: '80px'
    }
    
    const containerTop = {
        paddingTop: '80px'
    }

    return (
        loading === false ?
            <Box className={`${style.eliWrapper}`}>
                <Box className="container">
                    <Box className={style.eliLogo}>
                        <img src={constants.imgPath + "lodra.svg"} alt="logo eli" />
                    </Box>
                    {
                        gameData[1] &&
                        <ActivitiesBanner
                            title={gameData[1].body_header}
                            desc={gameData[1].body_desc}
                            image={constants.imgPath + gameData[1].images}
                            linkPrice="karting"
                            vidId="Y2uTFvwpA5g"
                            id={gameData[1].link}
                            prices={true}
                            changeColor='#FAA000'
                        />
                    }
                    <Box
                    sx={{
                        marginTop: {
                            xs: "100px",
                            sm: 20,
                            md: 0,
                        },
                    }}
                >
                    <ActivitiesInterest
                        interests={axe[language].interest}
                        color={"#F03C44"}
                    />
                </Box>
                    
                </Box>

                <Box className={'container'}
                    sx={[{
                        height: {
                            md: '500px',
                            sm: '416px',
                            xs: '209px'
                        },
                        position: 'relative',
                        marginTop: '70px'
                    }, containerTop]}
                >
                    <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                    <Videos id={'D3dPaLYw694'} />
                </Box>

                {
                    gameData[5] &&
                    <>
                        <Box className="container" sx={containerSpacing} ref={games}>
                            <Box className={style.wrapper}>
                                <Text
                                    containerStyling={{
                                        marginBottom: '100px'
                                    }}
                                    headerStyle={{
                                        textAlign: 'center',
                                        marginBottom: '30px',
                                        fontSize: {
                                            md: '50px',
                                            xs: '30px'
                                        },
                                        fontWeight: 700,
                                        letterSpacing: '1px'
                                    }}
                                    descStyling={{
                                        textAlign: 'center',
                                        whiteSpace: 'pre-line',
                                        marginX: 'auto',
                                        fontSize: '20px',
                                        width: {
                                            md: '787px',
                                            xs: '100%'
                                        }
                                    }}
                                    header={gameData[5]['body_header']}
                                    desc={gameData[5]['body_desc']}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: { lg: '80%' }, marginX: {sm: 'auto', xs: 'unset'}, borderRadius: '20px', overflow: 'hidden' }}>
                            <FullBanner
                                imgUrl={constants.imgPath + gameData[5]['images']}
                                imgAlt={"bruh"}
                            />
                        </Box>
                    </>
                }

                

                <Box sx={{ position: 'relative', overflow: 'hidden' }} className={'bodyWrapper'} paddingBottom={5}>
                    {
                        gameData[4] &&
                        <Box>
                            <Box className={style.wrapper} ref={rules}>
                                <Text
                                    descStyling={{
                                        marginTop: 0
                                    }}
                                    title={gameData[4]['wrapper_header']}
                                    desc={gameData[4]['wrapper_desc']}
                                />
                                <Box
                                    style={{
                                        backgroundImage: `url(${constants.imgPath + gameData[4]['images']})`,
                                        height: "650px",
                                        width: "100%",
                                        borderRadius: "20px",
                                    }}
                                ></Box>
                            </Box>
                        </Box>
                    }

                    {/* <Box sx={{position: 'absolute', width: '50%', left: '-50px', top: '-50px', zIndex: -1, display: {sm: 'none', md: 'flex'}}}>
                    <img src={items[1]} alt='img' style={{width: '50%'}} />
                </Box>
                <Box sx={{position: 'absolute', width: '50%', top: '-50px', right: '-50px', justifyContent: 'end', zIndex: -1, display: {sm: 'none', md: 'flex'}}}>
                    <img src={items[0]} alt="img" style={{width: '50%'}}/>
                </Box> */}

                    <Box sx={{ display: {md: 'block', xs: 'none'} }}>

                        <Box sx={{ position: 'relative', width: '100%', height: '150px',}}>
                            <Box position={'absolute'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} sx={{ left: '20%' }}>
                                <img src={items[7]} alt="" style={{ width: '85px' }} />
                                <img src={items[6]} alt="" style={{ width: '170px', position: 'relative', right: '100px', bottom: '75px' }} />
                                <img src={items[8]} alt="" style={{ width: '150px', position: 'relative', right: '150px', bottom: '130px' }} />
                            </Box>

                            <Box position={'absolute'} sx={{ right: '20%' }}>
                                <img src={items[9]} alt="" style={{ width: '200px' }} />
                            </Box>
                        </Box>
                    </Box>
                    {
                        gameData[6] &&
                        <Box ref={gamePreparation}>
                            <Box className="container">
                                <Typography align="center" component='h1' color={'#FFF'} variant='h2' gutterBottom fontWeight={500}>{gameData[6][0]['wrapper_header']}</Typography>
                                <Typography component={'p'} gutterBottom align="center" marginX={'auto'}
                                    sx={{
                                        width: {
                                            md: '672px',
                                            xs: '100%'
                                        },
                                        fontSize: '20px'
                                    }}
                                >
                                    {gameData[6][0]['wrapper_desc']}
                                </Typography>
                            </Box>

                            <Box marginTop={10} sx={containerSpacing}>
                                <CarouselGallery gallery={gameData[6][0]['images']} radius={5} />
                            </Box>
                        </Box>
                    }

                    {
                        gameData[7] &&
                        <Box className="container" sx={{ marginTop: 10, paddingBottom: '80px' }} ref={gallery}>
                            <Text
                                containerStyling={{ marginBottom: '50px' }}
                                header={gameData[7].wrapper_header}
                            />
                            <GridBox
                                gap={2}
                                gridTemplateColumns={
                                    {
                                        xs: 'repeat(2, 1fr)',
                                        sm: 'repeat(3, 1fr)',
                                        md: 'repeat(4, 1fr)'
                                    }
                                }
                                gridTemplateRows={{
                                    xs: 'repeat(3, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(2, 1fr)'
                                }}
                            >
                                <GalleryBox galleryImages={gameData[7].images} />
                            </GridBox>
                        </Box>
                    }

                    <Box sx={containerSpacing} ref={rules}>
                        <Typography 
                            variant="h3" 
                            sx={{ 
                                color: '#FFF', 
                                marginBottom: {
                                    md: '100px', 
                                    xs: '50px'
                                    }, 
                                whiteSpace: {
                                    sm: 'pre-line', 
                                    xs: 'normal'
                                }, 
                                fontWeight: 700,
                                marginX: {
                                    sm: 'unset',
                                    xs: 'auto'
                                } ,
                                fontSize: {
                                    sm: '3rem',
                                    xs: '25px'
                                },
                                width: {
                                    sm: '100%',
                                    xs: '90%'
                                }
                            }} 
                            textAlign={'center'}
                        >{lodra[language]['comeParty']}</Typography>
                        <PartyBanner
                            header={lodra[language]['happyBirthdayHeader']}
                            desc={lodra[language]['happyBirthdayDesc']}
                            buttonTxt={lng[language]['hyperBirthdayBtn']}
                            link={"/activities/party"}
                            imageUrl={"https://hyper-active.com/images/content/bIRTHDAY_3.png"}
                            containerStyle={{
                                width: { sm: "454px", xs: '90%' },
                                color: "#FFF",
                                background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 100%)",
                                padding: '50px',
                                borderRadius: "20px",
                                display: "grid",
                                gridTemplateRows: "0fr 0fr 0fr",
                                rowGap: "50px",
                                marginTop: {
                                    md: 'unset',
                                    xs: '20px'
                                }
                            }}
                            mainConStyling={{
                                height: {
                                    md: 700, 
                                    sm: 500,
                                    xs: 'max-content'
                                }
                            }}
                            imgStyling={{
                                'img': {
                                    height: {
                                        md: '100%',
                                        xs: '105%'
                                    }
                                }
                            }}
                            buttonStyle={{
                                borderColor: '#FFF !important',
                                paddingX: '30px',
                                borderRadius: '40px',
                                '&:hover': {
                                    borderColor: '#FFF !important',
                                    background: '#FFF',
                                    color: '#F88E0D !important'
                                }
                            }}
                        />
                    </Box>

                    <Box className="container">
                        <Box className={style.wrapper}>
                            <AppBanner
                                specialColor={'radial-gradient(circle, rgba(250,158,0,1) 68%, rgba(240,60,68,1) 96%) !important'}
                                header={lng[language]['hyperAppHeader']}
                                desc={lng[language]['hyperAppDesc']}
                                imgUrl={'../images/6582cf3c6d165.webp'}
                            />
                        </Box>
                        <Box paddingY={5} ref={games} sx={containerSpacing}>
                            <Typography variant="h3" textAlign={'center'} gutterBottom sx={{fontSize: {sm: '3rem', xs: '40px'}}}>Arcade Games</Typography>
                            <Typography variant="body1" textAlign={'center'}>{lodra[language]['arcade']}</Typography>
                        </Box>
                    </Box>
                    <Box className="container">
                        <Box paddingBottom={5} paddingTop='50px'>
                                <Box sx={{'h1': {textAlign: 'center'}}}>
                                    <TextWrapper title={lng[language]['gameSuggestion']} />
                                </Box>
                            <RandomCards removeGame='Lodra' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            :
            <WaitingLoader open={loading} />
    );
}
export default EliPlay;
