import React from "react";
import style from "./SpeedScales.module.css";
import { useSelector } from "react-redux";
import { karting } from "../../utils/gameLng";

export default function SpeedScales(props) {
  const { language } = useSelector((state) => state.language);
  const speed = [
    // {
    //   level: (
    //     <div className={style.dotCon}>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="10"
    //         height="10"
    //         viewBox="0 0 10 10"
    //       >
    //         <rect
    //           id="Rectangle_485"
    //           data-name="Rectangle 485"
    //           width="10"
    //           height="10"
    //           rx="5"
    //           fill="#4600be"
    //         />
    //       </svg>
    //     </div>
    //   ),
    //   header: karting[language]['begginer'],
    //   driver: karting[language]['begginerDriver'],
    //   advance: karting[language]['begginerAdvance'],
    // },
    {
      level: (
        <div className={style.dotCon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_485"
              data-name="Rectangle 485"
              width="10"
              height="10"
              rx="5"
              fill="#4600be"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_485"
              data-name="Rectangle 485"
              width="10"
              height="10"
              rx="5"
              fill="#4600be"
            />
          </svg>
        </div>
      ),
      header: karting[language]['driver'],
      driver: karting[language]['driverDriver'],
      advance: karting[language]['driverAdvance'],
    },
    {
      level: (
        <div className={style.dotCon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_485"
              data-name="Rectangle 485"
              width="10"
              height="10"
              rx="5"
              fill="#4600be"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_485"
              data-name="Rectangle 485"
              width="10"
              height="10"
              rx="5"
              fill="#4600be"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_489"
              data-name="Rectangle 489"
              width="10"
              height="10"
              rx="5"
              fill="#faa000"
            />
          </svg>
        </div>
      ),
      header: karting[language]['racer'],
      driver: karting[language]['racerDriver'],
      advance: karting[language]['racerAdvance'],
    },
    {
      level: (
        <div className={style.dotCon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_485"
              data-name="Rectangle 485"
              width="10"
              height="10"
              rx="5"
              fill="#4600be"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_485"
              data-name="Rectangle 485"
              width="10"
              height="10"
              rx="5"
              fill="#4600be"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_489"
              data-name="Rectangle 489"
              width="10"
              height="10"
              rx="5"
              fill="#faa000"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <rect
              id="Rectangle_493"
              data-name="Rectangle 493"
              width="10"
              height="10"
              rx="5"
              fill="#f03c44"
            />
          </svg>
        </div>
      ),
      header: karting[language]['pro'],
      driver: karting[language]['proDriver'],
      advance: karting[language]['proAdvance'],
    },
    // {
    //   level: (
    //     <div className={style.dotCon}>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="10"
    //         height="10"
    //         viewBox="0 0 10 10"
    //       >
    //         <rect
    //           id="Rectangle_485"
    //           data-name="Rectangle 485"
    //           width="10"
    //           height="10"
    //           rx="5"
    //           fill="#4600be"
    //         />
    //       </svg>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="10"
    //         height="10"
    //         viewBox="0 0 10 10"
    //       >
    //         <rect
    //           id="Rectangle_485"
    //           data-name="Rectangle 485"
    //           width="10"
    //           height="10"
    //           rx="5"
    //           fill="#4600be"
    //         />
    //       </svg>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="10"
    //         height="10"
    //         viewBox="0 0 10 10"
    //       >
    //         <rect
    //           id="Rectangle_489"
    //           data-name="Rectangle 489"
    //           width="10"
    //           height="10"
    //           rx="5"
    //           fill="#faa000"
    //         />
    //       </svg>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="10"
    //         height="10"
    //         viewBox="0 0 10 10"
    //       >
    //         <rect
    //           id="Rectangle_493"
    //           data-name="Rectangle 493"
    //           width="10"
    //           height="10"
    //           rx="5"
    //           fill="#f03c44"
    //         />
    //       </svg>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="10"
    //         height="10"
    //         viewBox="0 0 10 10"
    //       >
    //         <rect
    //           id="Rectangle_493"
    //           data-name="Rectangle 493"
    //           width="10"
    //           height="10"
    //           rx="5"
    //           fill="#f03c44"
    //         />
    //       </svg>
    //     </div>
    //   ),
    //   header: karting[language]['legend'],
    //   driver: karting[language]['legendDriver'],
    //   advance: karting[language]['legendAdvance'],
    // },
  ];

  return (
    <div className={style.speedScalesContainer}>
      <table>
        <thead className={style.speedScalesHeader}>
          <tr>
            <th>{karting[language]['category']}</th>
            <th>{karting[language]['whoCanDrive']}</th>
          </tr>
        </thead>
        <tbody className={style.speedScalesBody}>
          {speed &&
            speed.map((data, index) => (
              <tr key={index}>
                <td>
                  {data.header}
                  {data.level}
                </td>
                <td>{data.driver}</td>
                {/* <td>{data.advance}</td> */}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
