import Wrapper from "../../../components/general/Wrapper";
import LottieMessage from "../../../components/general/message/LottieMessage";

function NotFound() {
  return (
    <>
      <LottieMessage
        text="Page Not found!"
        type="404"
      />
    </>
  );
}
export default NotFound;
