import React from "react";
import { useState } from "react";
import style from "./RandomCards.module.css";
import { Link } from "react-router-dom";
import constants from "../../utils/consts";
import { Typography } from "@mui/material";

export default function RandomCards({ removeGame }) {
  const arr = [
    {
      name: "HyperDrive",
      imgUrl: "https://hyper-active.com/images/homepage/compressed/gokart-h.png",
      imgAlt: "HyperDrive",
      url: "../activities/karting",
    },
    {
        name: "HyperStrike",
        imgUrl: "https://hyper-active.com/assets/images/new_main_banner.png",
        imgAlt: "Hyperstrike",
        url: "../activities/hyperstrike",
      },
    {
      name: "Laser Tag",
      imgUrl: "https://hyper-active.com/images/homepage/compressed/lasertag-h.png",
      imgAlt: "Laser Tag",
      url: "../activities/laser",
    },
    {
      name: "Arcade Games",
      imgUrl: "https://hyper-active.com/images/homepage/compressed/arcade-h.png",
      imgAlt: "Arcade Games",
      url: "../activities/arcade",
    },
    {
      name: "Virtual Reality",
      imgUrl: "https://hyper-active.com/images/homepage/compressed/vr-h.png",
      imgAlt: "Virtual Reality",
      url: "../activities/virtual",
    },
    // {
    //   name: "Axe Throwing",
    //   imgUrl: constants.reservationImgPath + "AxeThrow.webp",
    //   imgAlt: "Axe Throwing",
    //   url: "../activities/axe",
    // },
    {
      name: "Escape Rooms",
      imgUrl: "https://hyper-active.com/images/homepage/compressed/escaperoom-h.png",
      imgAlt: "Escape Rooms",
      url: "../activities/escape",
    },
    {
      name: "Bowling",
      imgUrl: "https://hyper-active.com/images/homepage/compressed/bowling-h.png",
      imgAlt: "Bowling",
      url: "../activities/bowling",
    },
    {
      name: "Lodra",
      imgUrl: "https://hyper-active.com/images/homepage/compressed/lodra-h.png",
      imgAlt: "Lodra",
      url: "../activities/eli",
    },
  ];

  function shuffle(arra1) {
    var ctr = arra1.length,
      temp,
      index;
    while (ctr > 0) {
      index = Math.floor(Math.random() * ctr);
      ctr--;
      temp = arra1[ctr];
      arra1[ctr] = arra1[index];
      arra1[index] = temp;
    }
    return arra1;
  }

  function getMultipleRandom(arr) {
    const randomObject = shuffle(arr);

    const filteredArray = randomObject.filter((emp) => emp.name !== removeGame);

    const newArray = filteredArray.slice(0, 3);
    return newArray;
  }

  const [randomData] = useState(() => getMultipleRandom(arr));

  return (
    <div className={style.randomCardContainer}>
      {randomData.map((data, index) => (
        <Link to={data.url} key={index} className={style.randomCard}>
          <Typography
            component={"h1"}
            color={"#FFF"}
            textAlign={"center"}
            gutterBottom
            fontSize={25}
            fontWeight={"bold"}
          >
            {data.name}
          </Typography>
          <div className={style.randomCardImage}>
            <img src={data.imgUrl} alt={data.imgAlt} />
          </div>
        </Link>
      ))}
    </div>
  );
}
