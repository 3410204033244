import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import style from "./CartProducts.module.css";
import { Link } from "react-router-dom";
import lng from "../../utils/language";
import { useSelector } from "react-redux";

function ReserveText(props) {
  const { language } = useSelector((state) => state.language);
  return (
    <Box className={style.reserveNow}>
      <Typography color={'#FFF'} component={'h1'} fontWeight={'bold'} sx={{fontSize: {md: '52px', xs: '30px'},marginBottom: '10px !important'}}>{props.header}</Typography>
      <Typography color={'#FFF'} component={'p'} sx={{marginBottom: '15px !important', whiteSpace: 'pre-line'}}>
        {props.desc}
      </Typography>
      <Box 
        to={`/reservation/${props.id}`} 
        className="btn"
        component={Link}
        sx={{
          '&:hover': {
            background: '#FFF',
            color: '#321eff',
            borderColor: '#fff'
          }
        }}
      >
        {lng[language]['bookNow']}
      </Box>
    </Box>
  );
}
export default ReserveText;
