import React from "react";
import { Link } from "react-router-dom";
import style from "./Message.module.css";
import loader from "../../../utils/lotties/73060-blue-search-not-found.json";
import error from "../../../utils/lotties/19230-payment-failed.json";
import success from "../../../utils/lotties/101253-successful.json";
import mail from "../../../utils/lotties/data.json";
import notFound from "../../../utils/lotties/404.json";

import Lottie from "lottie-react";

const types = {
  "success": success,
  "error": error,
  "noFound": loader,
  "mail":mail,
  "404":notFound
}

function LottieMessage(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:  types[props.type],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="container pbt50">
        <div className={style.lottieBox}>
          <div className={style.lottieWrapper}>
            <Lottie options={defaultOptions} />
            <p>{props.text}</p>
            {props.linkText && <Link to={props.linkTo} className="btn">
              {props.linkText}
            </Link>}
          </div>
        </div>
      </div>
    </>
  );
}

export default LottieMessage;
