import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import Grid from "../../layout/grid/Grid";
import style from "./CustomCard.module.css";
import lng from "../../utils/language";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

export default function CustomCard(props) {
  const { language } = useSelector((state) => state.language);
  return (
    <div className={style.mainWrapper}>
      <Grid no={61}>
        <div className={style.bigCon}>
          <div className={style.infoCon}>
            <h2>{props.title}</h2>
            <h4>{props.desc}</h4>
            <Button LinkComponent={Link} to={'https://www.youtube.com/watch?v=ZmkoDcjWrM8'} target={"_blank"} rel="noreferrer" sx={{"&:hover":{background: 'rgb(240, 60, 68)','& svg': {fill: '#FFF'}}}}>
              {lng[language]['watchVideo']} <AiFillCaretRight fontSize={20} color="#F03C44" />
            </Button>
          </div>
          <div className={style.mainImg}>
            <img src={props.mainImg} alt="" />
          </div>
        </div>
        <div className={style.numContainer}>
          {props.numData &&
            props.numData.map((data, index) => (
              <div key={index}>
                <h1 className={style[data.type]}>
                  {data.numTitle}
                </h1>
                <p>{data.desc}</p>
              </div>
            ))}
        </div>
      </Grid>
    </div>
  );
}
