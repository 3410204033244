import style from "./ShoppingCart.module.css";
import { BsCart2 } from "react-icons/bs";
import CartProducts from "../../components/reservation/CartProducts";
import lng from "../../utils/language";
import { 
    // useDispatch, 
    useSelector } from "react-redux";
// import { Button } from "@mui/material";
// import { initializeCart } from "../../app/cart/Cart";

function ShoppingCart() {
  const { language } = useSelector((state) => state.language);
// const dispatch = useDispatch();
  return (
    <div className={style.cartContainer}>
      <div className={style.cartHeader}>
        <h2>
          <span>
            <BsCart2 />
          </span>{" "}
          {lng[language]["cart"]} 
        </h2>
      </div>
      <CartProducts/>
    </div>
  );
}
export default ShoppingCart;