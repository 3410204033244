import React from 'react';
import { Link } from 'react-router-dom';
import style from './FullBanner.module.css';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import lng from '../../../utils/language';
import { useSelector } from 'react-redux';


export default function FullBanner({imgUrl, imgAlt, title, desc, url, color, special}) {
  const { language } = useSelector((state) => state.language);
  return (
    <Box className={style.fullBannerContainer} sx={{
        height: {
            md: '80vh',
            xs: '500px'
        }
    }}>
        {
            
            imgUrl &&
            <Box className={style.fullBannerImg}>
                <Box 
                    src={imgUrl} 
                    alt={imgAlt} 
                    component={'img'}
                    sx={{
                        objectFit: special ? 'contain' : 'cover'
                    }}
                />
            </Box>
        }
        {
            title || desc || url ?
            <Box className={style.fullBannerInfo}
              sx={{
                width: {
                  lg: '53%',
                  md: '80%',
                  xs: '90%'
                },
                right: {
                  xs: 0, 
                }
              }}
            >
                {title &&
                <Typography component={'h1'} fontWeight={'bold'}
                sx={{
                  width: {
                    md: '550px',
                    xs: '100%'
                  },
                  fontSize: {
                    xs: '35px',
                    md: '45px'
                  }
                }}
                >{title}</Typography>}
                {
                    desc && <p>{desc}</p>
                }
                {
                    url && <Typography component={Link} to={url} color={'#FFF'} sx={{backgroundColor: color ? color : 'unset'}}>{lng[language]['partyNow']}</Typography>
                }
            </Box>
            :
            ''
        }
    </Box>
  )
}