import style from "./General.module.css";
import Logo from "../../components/general/Logo";

function Wrapper(props) {
  return (
    <div className="container">
      <div className={props.isFrom === false ? style.userWrapper : style.userWrapperDif}>
        {!props.removeLogo && <div className={style.wrapperText}>
          <Logo/>
          <p>{props.info}</p>
        </div>}
        {props.children}
      </div>
    </div>
  );
}
export default Wrapper;
