import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import lng from '../../utils/__language';
import { useSelector } from 'react-redux';
import constants from '../../utils/consts';

export default function RaceEventCard({arr, type, customButton, compStyling, imgStyling}) {
const { language } = useSelector((state) => state.language);

  return (
    <Box 
        className={"reverseSingle"} 
        sx={{
            gap: 5, 
            display: 'flex', 
            flexDirection: 'column'
        }}
    >
        {
            arr && arr.map((data, index) => (
                arr &&
                <Box
                    key={index}
                    sx={[
                        {
                            display: 'flex',
                            height: {
                                md: '350px',
                                sm: '350px',
                                xs: 'auto'
                            },
                            borderRadius: 6,
                            backgroundColor: '#120e2e',
                            overflow: 'hidden',
                            position: 'relative',
                        },
                        compStyling? compStyling : {}
                    ]}
                >
                    <Box
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '50%'
                            },
                            minWidth: {
                                xs: '100%',
                                sm: '50%'
                            },
                            zIndex: 0,
                            position: {
                                sm: 'relative',
                                xs: 'absolute'
                            },
                            opacity: {
                                sm: 1,
                                xs: .3
                            } 
                        }}
                    >
                        <Box 
                            component={'img'} 
                            src={constants.imgPath+data.images} 
                            alt={'img'}
                            sx={{
                                width: {
                                    sm: '100%',
                                    xs: 'unset'
                                },
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        ></Box>
                    </Box>
                    <Box
                        sx={[{
                            padding: '30px',
                            width: {
                                sm: '50%',
                                xs: '100%'
                            },
                            zIndex: 1,
                            display: 'grid',
                            gridTemplateRows: '0fr 0fr 0fr',
                        }, data.compStyling ? JSON.parse(data.compStyling) : {}]}
                    >
                        <Typography component="h1" color="#FFF" fontSize={32} fontWeight={'bold'} marginBottom={'20px'}>{data.body_header}</Typography>
                        <Typography variant="p" component={'p'} marginBottom={'10px'} color="#FFF">{data.body_desc}</Typography>
                        {
                            data.link &&
                            <Typography 
                                component={Link} 
                                variant="a" 
                                to={data.link}
                                sx={[{
                                    background: '#AF46FA',
                                    color: '#FFF',
                                    marginTop: '20px',
                                    textDecoration: 'none',
                                    borderRadius: '40px',
                                    padding: '12px 20px',
                                    width: 'fit-content',
                                    height: 'min-content',
                                    alignSelf: 'end',
                                    transition: '.3s all',
                                    "&:hover": {
                                        backgroundColor: '#FFF',
                                        color: '#AF46FA'
                                    }
                                }, customButton ? customButton : {}]}
                            >
                                {
                                    type ?
                                    lng[language]['bookNow']
                                    :
                                    lng[language]['checkResulsts']
                                }
                            </Typography>               
                        }
                        {/* {
                            data.link &&
                            <Typography 
                                component={Link} 
                                variant="a" 
                                to={data.link}
                                sx={{
                                    background: '#F03C44',
                                    color: '#FFF',
                                    marginTop: '20px',
                                    textDecoration: 'none',
                                    borderRadius: '40px',
                                    padding: '12px 20px',
                                    width: 'fit-content',
                                    height: 'min-content',
                                    alignSelf: 'end',
                                    transition: '.3s all',
                                    "&:hover": {
                                        backgroundColor: '#FFF',
                                        color: '#F03C44'
                                    }
                                }}
                                
                            >{lng[language]['bookNow']}</Typography>               
                        } */}
                    </Box>
            
                </Box>
            ))
        }
    </Box>
  )

}