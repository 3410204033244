import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'


export default function Text(props) {
    
    const { containerStyling ,header, headerStyle, desc, descStyling } = props;

    const containerDefaultStyling = (
        containerStyling || {
            
        }
    )

    const headerDefaultStyling = (
        headerStyle || {
            textAlign: 'center',
            marginBottom: '10px'
        }
    )

    const descDefaultStyling = (
        descStyling || {
            textAlign: 'center'
        }
    )
    
    return (
        <Box sx={[containerDefaultStyling, {display: 'flex', flexDirection: 'column'}]}>
            {
                header &&
                <Typography variant='h3' sx={[{fontSize: {sm: '52px', xs: '34px'}, fontWeight: 'bold'}, headerDefaultStyling]}>
                    {header}
                </Typography>
            }
            {
                desc &&
                <Typography variant='body1' sx={[{fontSize: '18px', fontWeight: 'normal'}, descDefaultStyling]}>
                    {desc}
                </Typography>
            }
        </Box>   
    )
}