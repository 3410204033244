import React from "react";
import { Link } from "react-router-dom";
import style from "./LinkCard.module.css";
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';

export default function LinkCard({ data }) {
    return (
        <Box
            component={data.comingSoon ? 'div' : Link}
            to={data.homepage ? data.url : "/reservation" + data.url}
            sx={{
                backgroundImage: data.homepage ? `url(${data.imgUrl})`: `url(../assets/reservation/${data.imgUrl})`,
            }}
            className={`${style.linkCardContainer} ${data.width ? style[data.width] : ""
                }`}
        >
            {   
                    data.comingSoon &&
                    <Typography variant="h4" color={'#FFF'} fontWeight={'bold'} sx={{
                        textDecoration: 'none',
                        top: 'calc(50% - 70px)',
                        position: 'absolute',
                        width: '100%',
                        textAlign: 'center',
                        zIndex: 2,
                        fontSize: '50px'
                    }}
                    >Coming Soon</Typography>
                }
            {
                data.comingSoon &&
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)'
                }} />
            }
                {
                    data.desc &&
                    <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '20%',
                    position: 'absolute',
                    bottom: 0,
                    backgroundColor: 'rgba(70, 0, 190, 0.9)',
                    opacity: data.comingSoon ? 0.7 : 1
                }}
            >
                <h1>{data.desc}</h1>
            </Box>
                }
            
        </Box>
    );
}