import { useState } from "react";
import style from "./PickPlayers.module.css";
import Input from "../../../../components/form/Input";
// import DateWrapper from "../../../../components/form/DatePicker";
import Grid from "../../../grid/Grid";
import { useDispatch, useSelector } from "react-redux";
// import { addUserInfo } from "../../../../redux/app/cart/actions";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { calculateAge } from "../../../../utils/helpers";
import lng from "../../../../utils/language";
import { addPlayerData } from "../../../../app/cart/Cart";
import Box from "@mui/material/Box";
import DatePickerSeperate from "../../../../components/form/DatePickerSeperate";
import Typography from '@mui/material/Typography'

function PlayersData({ setReservationInfo, reservationInfo, updatePage, reservationParams, }) {
    const { language } = useSelector((state) => state.language);
    const dispatch = useDispatch();
    const { playerInfo } = useSelector((state) => state.cart);
    const { user } = useSelector((state) => state.auth);
    //DOB START 
    const years = new Date().getFullYear();
    const [day, setDay] = useState("01");
    const [month, setMonth] = useState("01");
    const [year, setYear] = useState(years);
    //DOB END
    const [players, setPlayers] = useState(
        playerInfo.length > 0
            ? playerInfo
            : [
                {
                    name: user?.name,
                    lastname: user?.lastname,
                    dob: user?.dob,
                    // email: user?.email,
                },
            ]
    );
    const [message, setMessage] = useState("");

    const changeValue = (event, playerIndex, field) => {
        const newPlayers = [...players];
        if (!newPlayers[playerIndex]) {
            newPlayers[playerIndex] = {};
        }
        newPlayers[playerIndex][field] = event.target.value;
        setPlayers(newPlayers);
    };

    const changeDate = (field, value, playerIndex) => {
        if (value) {
            const newPlayers = [...players];
            if (!newPlayers[playerIndex]) {
                newPlayers[playerIndex] = {};
            }
            newPlayers[playerIndex][field] = value;
            setPlayers(newPlayers);
        }
    };

    const validateNames = (e) => {
        e.preventDefault();
        let isFormValid = true;
        // let seenEmails = [];
        let message = "";
        let playerInfo = [];
        
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        for (let i = 0; i < reservationInfo.adult + reservationInfo.children; i++) {
            if (
                !players[i]?.name ||
                !players[i]?.lastname ||
                !players[i]?.dob
                // !players[i]?.email
            ) {
                message = lng[language]['racingDriverError'];
                isFormValid = false;
                break;
            }
            if (!calculateAge(players[i]?.dob, 15)) {
                message = lng[language]['racingDriverAgeError'];
                isFormValid = false;
                break;
            }
            //   if (!regex.test(players[i]?.email)) {
            //     message = "Ju lutem shënoni email adresë valide!";
            //     isFormValid = false;
            //     break;
            //   }
            //   if (seenEmails.includes(players[i].email)) {
            //     isFormValid = false;
            //     message = "Ju lutem vendosni email adresa unike!";
            //     break;
            //   } else {
            //     isFormValid = true;
            //     playerInfo.push(players[i]);
            //     seenEmails.push(players[i].email);
            //   }
        }
        if (isFormValid) {
            dispatch(addPlayerData(playerInfo));
            updatePage();
        } else {
            window.scrollTo(0, 0);
            setMessage(message);
            setTimeout(() => {
                setMessage("");
            }, 3000);
        }
    };

    return (
        <div className={style.playerDataWrapper}>
            {message.length > 0 && <p className="errorMsg">{message}</p>}
            <form>
                <div>
                    <div className={style.border}>
                        <div>
                            <span onClick={() => updatePage(false)}>
                                <MdOutlineArrowBackIos color="#fff" />
                            </span>
                            <div>
                                <p>{lng[language]["playerData"]}</p>
                                <p>{lng[language]['fillData']}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {Array.from(
                    Array(reservationInfo.adult + reservationInfo.children),
                    (_, i) => {
                        return (
                            <div className={style.playerData} key={i}>
                                <p>{lng[language]['player']} {i + 1}: </p>
                                <Box sx={{display: 'grid',  gridTemplateColumns: {sm: '1fr 1fr', xs: '1fr'}, columnGap: '20px', rowGap: '20px'}}>
                                        <Input
                                            placeholder={lng[language]["name"]}
                                            label={lng[language]["name"]}
                                            name="name"
                                            type="text"
                                            value={players[i]?.name || ""}
                                            onChange={(e) => changeValue(e, i, "name")}
                                        />
                                        <Input
                                            placeholder={lng[language]["lastname"]}
                                            label={lng[language]["lastname"]}
                                            name="lastname"
                                            type="text"
                                            value={players[i]?.lastname || ""}
                                            onChange={(e) => changeValue(e, i, "lastname")}
                                        />
                                    <Box>
                                        <Typography variant="body1" sx={{fontSize: '14px !important', marginLeft: '0 !important', marginBottom: '10px !important'}}>{lng[language]["dob"]}</Typography>
                                        <DatePickerSeperate 
                                                day={day}
                                                month={month}
                                                year={year}

                                                setDay={setDay}
                                                setMonth={setMonth}
                                                setYear={setYear}
                                            index={i}
                                            fieldValue={changeDate}
                                            date={players[i]?.dob ? players[i]?.dob : null}
                                        />
                                    </Box>

{/* 
                                    <DateWrapper
                                        placeholder={lng[language]["dob"]}
                                        label={lng[language]["dob"]}
                                        name="dob"
                                        type="text"
                                        setFieldValue={changeDate}
                                        onChange={changeDate}
                                        index={i}
                                        hasIndex={true}
                                        date={players[i]?.dob ? new Date(players[i]?.dob) : null}
                                    /> */}

                                    {/* <Input
                    placeholder={lng[language]["email"]}
                    label={lng[language]["email"]}
                    name="email"
                    type="text"
                    value={players[i]?.email || ""}
                    onChange={(e) => changeValue(e, i, "email")}
                  /> */}
                                </Box>
                            </div>
                        );
                    }
                )}

                <button className="btn mt20" onClick={(e) => validateNames(e)}>
                    {lng[language]["next"]}
                </button>
            </form>
        </div>
    );
}
export default PlayersData;
