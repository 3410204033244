import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function Modals(props) {

    const { open, children, handleClose } = props;

    return (
    <Modal onClose={handleClose} open={open}>
        <Box sx={{width: '80%', marginX: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', position: 'relative'}}>
            {children}
        </Box>
    </Modal>
    )
}