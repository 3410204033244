
export default function TermsConditions() {
  return (
    <div className="container pbt100">
      <div>
        <h1>Privatësia</h1>
        Ju lutemi që ta lexoni këtë shënim. Politikat në vijim shpjegojnë
        rregullat tona të privatësisë dhe sigurisë së informatave. Mbajtja
        konfidenciale e informatave të klientëve tanë është prioritet i lartë i
        Kompanisë sonë.  HyperActive do ti mbrojë të dhënat personale fizike dhe
        elektronike sipas LIGJI Nr. 03/L- 172 PËR MBROJTJEN E TË DHËNAVE
        PERSONALE të miratuar nga Kuvendi i Republikës së Kosovës me date
        20.04.2010. Këto të dhëna personale mblidhen, përpunohen dhe
        regjistrohen nëpërmjet aplikacionit të publikuar në web – faqen e
        Kompanisë, me qëllim që ti përgjigjemi kërkesave tuaja për produktet dhe
        sherbimet tona që ju mund të jeni të interesuar, përmes telefonit,
        postës, e- mailit, kontaktit të drejtëpërdrejtë në Kompaninë tonë apo
        ndonjë formë tjetër të komunikimit me ne. Duke u njohur me përmbajtjen e
        kësaj rubrike, unë si subjekt i të dhënave personale në mënyrë
        vullnetare jap pëlqimin për përpunimin e të dhënave të mia personale të
        paraqitura në këtë aplikacion.
      </div>
      <div>
        <h1>MOSPRANIMI / MOHIMI</h1>
        HyperActive nuk mban përgjegjësi për pasaktësi eventuale të përmbajtjes
        të të dhënave apo informacionit të dhënë nga vetë klienti
      </div>
    </div>
  );
}
