import style from "./CardTopUp.module.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { api } from "../../../api/config";
import { MdOutlineArrowBackIos } from "react-icons/md";
import Grid from "../../../layout/grid/Grid";
import { useDispatch } from "react-redux";
// import { showLoader } from "../../../redux/app/loader/actions";

function CardRefill() {
  const [value, setValue] = useState(5);
//   const dispatch = useDispatch();
  const options = [5, 10, 15, 20];
  const params = useParams();

  useEffect(() => {
    api
      .post("card/verify", {
        id: params.id,
      })
      .then((res) => {
        if (!res.data.success) {
          window.location.href = "/notfound";
        }
      })
      .catch((error) => {
        console.error(error);
      });
      // eslint-disable-next-line
  }, []);

  const processPayment = () => {

    api
    .post("card/refill", {
      id: params.id,
      total: value
    })
    .then((res) => {
      if (res.data.success) {
        // dispatch(showLoader(true));
        window.location.replace(res.data.url);
      }
    })
    .catch((error) => {
      console.error(error);
    });


  };

  return (
    <div className="container pbt20">
      <Link to="/reservation" className="bckButton">
        <MdOutlineArrowBackIos color="#fff" />
      </Link>
      <div className="pbt20">
        <h3>Zgjedh një pako dhe kliko Paguaj</h3>
        <Grid no={4}>
          {options.map((i, j) => {
            return (
              <div
                className={`${style.refillBox} ${
                  i === value && style.refillBoxActive
                }`}
                key={j}
                onClick={() => setValue(i)}
              >
                {i}&euro;
              </div>
            );
          })}
        </Grid>
        <div className={style.refillBtn}>
          <Link className="orangeBtn" to="" onClick={()=>processPayment()}>
            Paguaj
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CardRefill;
