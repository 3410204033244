import style from "./StepAdvisor.module.css";

function StepAdvisor({ step, stepNames }) {
 
  return (
    <div>
      <ul className={style.steps}>
        {stepNames.map((i, j) => {
          return (
            <li key={j}>
              <p>{i}</p>
              <span className={step === j ? style.active : style.list}></span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default StepAdvisor;
