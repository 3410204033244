import style from "./Input.module.css";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Input(props) {
  const [startDate, setStartDate] = useState(props.date);
  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };

  const years = range(1960, 2022, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const updateDate = (value) => {
    const index = props.index;
    if (!props.hasIndex) {
      setStartDate(value);
      let date = new Date(value);

      date.setDate(date.getDate() + 1);

      const formattedDate = date.toISOString().substring(0, 10);
      props.setFieldValue("dob", formattedDate);
    } else {
      setStartDate(value);
      let date = new Date(value);

      date.setDate(date.getDate() + 1);
      const formattedDate = date.toISOString().substring(0, 10);
      props.setFieldValue("dob", formattedDate, index);
    }
  };

  return (
    <div className={style.input}>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={`${props.errorMessage && props.touched && style.error}`}
        dateFormat="yyyy-MM-dd"
        renderCustomHeader={({
          year,
          month,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className={style.btnContainer}>
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className={style.prevButton}
            >
              {"<"}
            </button>
            <select
              value={year}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[month]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className={style.prevButton}
            >
              {">"}
            </button>
          </div>
        )}
        selected={startDate}
        onChange={(date) => updateDate(date)}
      />
    </div>
  );
}
export default Input;
