const vr = {
    eng: {
        banner: "Experience the ultimate virtual reality with Hologate! Prepare to be transported to worlds beyond your wildest imagination, where you can fight zombies and aliens or become a brave pirate chef. With Hologate, the possibilities are endless.",
        gameDesc: 'Confront zombies and aliens or step into the shoes of a daring pirate chef. With Hologate, the adventures are boundless',
        safetyDesc: 'Experience VR to the fullest by having fun and respecting the rules of the game.',
        noRes: 'Online booking for VR is not possible, you just come and choose your game.'
    },
    alb: {
        banner: "Përjetoni realitetin më të fundit virtual me Hologate! Përgatituni për t'u transportuar në botë përtej imagjinatës tuaj më të egër, ku mund të luftoni zombitë dhe alienët ose të bëheni një kuzhinier i guximshëm pirat. Me Hologate, mundësitë janë të pakufishme.",
        gameDesc: 'Luftoni zombitë dhe alienët ose të bëheni një kuzhinier i guximshëm pirat. Me Hologate, mundësitë janë të pakufishme.',
        safetyDesc: 'Përjetoni VR deri në fund duke bërë qejf dhe respektuar rregullat e lojës.',
        noRes: 'Rezervimi online për VR nuk është i mundur, ju thjesht ejani dhe zgjidhni lojën tuaj.'
    },
};

const escape = {
    eng: {
        banner: `CAN YOU ESCAPE?

      Escape Rooms are an adrenaline-filled adventure that throws participants directly into the action with a mission to solve puzzles and challenges. You and your team are transported to a new world where you must race against the clock to beat the game, racing through multiple rooms to find clues and solve challenging puzzles.

      Experience a taste of adventure in your hometown with our immersive Escape Rooms, designed to challenge your mind and put your mystery-solving skills to the test:

      La Mision Del Profesor, The Haunted Mansion, One Thousand and One Nights, School of Magic`,
        safetyDesc: 'Experience Escape Room to the end by having fun and respecting the rules of the game',
        escapeMonth: 'Escape of the Month',
    },
    alb: {
        banner: `Escape Rooms janë dhoma të mbushura plot adrenalinë që i hedhin pjesëmarrësit drejtpërdrejt në aksion me një mision për të zgjidhur enigmat dhe sfidat. Ju dhe ekipi juaj transportoheni në një botë të re ku duhet të garoni kundër orës ku duhet zgjedhur enigmat sfiduese për të gjetur daljen.

      Shumë emocione e adrenalinë ju presin brenda dhomave, një përvojë zhytëse që do ju sfidoj mendjen dhe do testoj aftësitë tuaja për zgjidhjen e mistereve në dhomat:
      
      La Mision Del Profesor, Haunted Mansion, One Thousand & One Nights, School of Magic`,
        safetyDesc: 'Përjetoni Escape Room deri në fund duke bërë qejf dhe respektuar rregullat e lojës',
        escapeMonth: 'Arratisjet e Muajit',
    },
};

const arcade = {
    eng: {
        banner: `Step back in time and relive the golden age of arcades! Our collection of arcade machines is packed full of nostalgia with classic games as well as the latest innovations for gamers of all ages. From exclusive titles to well-known favorites, we've got something for everyone.
      
      So bring your friends and family and let the adventure begin!`,
        aracadeInfoHeader: '+50 Arcade Games',
        aracadeInfoDesc: `Add another layer to your adventure with an extra 50 arcade games!`
    },
    alb: {
        banner: `Kthehuni mbrapa n’kohë dhe rijetoni epokën e artë të arkadave! Koleksioni ynë i makinerive arcade është i mbushur plot nostalgji me lojërat klasike si dhe risitë më të fundit për lojtarë e të gjitha moshave. Nga titujt ekskluzivë të filmave të preferuar deri te lojërat e fëmirisë, ka diçka për të gjithë.
      
      Kshuqë mbledhe shoqninë e familjen tuaj dhe le t’fillojë aventura!`,
        aracadeInfoHeader: '+50 Arcade Games',
        aracadeInfoDesc: '50 lojra arkade të tjera për të plotësuar aventurën tuaj!'
    },
};

const axe = {
    eng: {
        banner: `Feel the buzz of a true Viking!
      
      The best way to get your heart pumping! A genuine wood-chopping experience with a traditional Viking theme, ax-throwing is a fun and exhilarating activity for all ages. This ancient sport combines skill, strength and a large dose of adrenaline as you prove who is the best ax-thrower in town.
      
      Combine this thrilling activity with delicious food and drinks in our recharge bar, where you’ll feel like a true Viking warrior when you leave.
      
      You heard it! Now, let's throw`,
        safetyDesc: 'Experience Sakica to the end by having fun and respecting the rules of the game',
    },
    alb: {
        banner: `Ndjehuni si një viking i vërtetë! Një përvojë e vërtetë e qarjes së drurit me një temë tradicionale vikinge, gjuajtja e sakicës është një aktivitet argëtues dhe emocionues. Ky sport i lashtë kombinon aftësinë, forcën dhe një dozë të madhe adrenaline ndërsa provoni se kush është gjuajtësi më i mirë i sakicës në qytet.
      
      Kombinoje këtë aktivitet emocionues me ushqime dhe pije të shijshme në barin tonë “Recharge”.`,
        safetyDesc: 'Përjetoni Sakicën deri në fund duke bërë qejf dhe respektuar rregullat e lojës',
    },
}

const bowling = {
    eng: {
        banner: `Knock down those pins and roll in style with Hyper Bowl! A first-of-its-kind virtual reality experience, You can play individual games or group bowling, whether solo or with a full team. Choose your lane theme and get ready to compete. With one of the most advanced technology facilities in the world, Hyper Bowling sets the new standard for immersive entertainment.

        As the first fully interactive bowling simulator, you can now be surrounded by a virtual world. The augmented reality technology enables you to display an animated bowling lane on top of your physical environment.

        Get your friends together for a fun tournament or pick your lane theme... We have it all: from group bowling to individual games, we have something for everyone. Invite your friends and book a lane today.`,
        whatIsBowling: 'What is AR Bowling?',
        gameType: "Games",
        gameTypeDesc: "Choose your theme!",
        filler: `The augmented reality technology enables you to display an animated bowling lane on top of your physical environment.`
    },
    alb: {
        banner: `Një përvojë e parë në llojin e tij të realitetit virtual, ku mund të luani lojëra individuale ose bowling në grup, qoftë vetëm ose me një ekip të plotë. Me një nga pajisjet më të avancuara të teknologjisë në botë, Ar Bowling vendos standardin e ri për argëtim gjithëpërfshirës.Teknologjia e realitetit të shtuar ju mundëson të shfaqni një korsi të animuar bowling në mjedisin tuaj fizik.

        Mbledhe familjen e shoqninë për një turne argëtues ose zgjidhni temën e korsisë tuaj...`,
        whatIsBowling: 'Çka Është AR Bowling?',
        gameType: "Llojet E Lojërave",
        gameTypeDesc: "Zgjidh temën e korsisë tuaj!",
        filler: `Teknologjia e realitetit të shtuar ju mundëson të shfaqni një korsi të animuar bowling në mjedisin tuaj fizik.`
    },

};

const laser = {
    eng: {
        reservetXT: "We recommend making a reservation for each attraction so you don't have to wait in line for an incredible experience.",
        banner: `What's Laser Tag? Imagine a thrilling live-action video game. If you're looking for a dose of adrenaline, look no further. The arena is filled with obstacles that add intensity and make every game different.
      Is a game of strategy. You have to be smart, fast and focused if you want to survive! Take your friends and put your survival instincts to the test as you try to outshine your opponents in laser tag.`,
        battleType: 'Laser Tag Games',
        battleTypeDesc: `The arena is filled with obstacles that add intensity and make every game different. It is a game of strategy. You have to be smart, fast, and focused if you want to survive! 
      
      Gather your friends and put your survival instincts to the test as you try to outshine your opponents in laser tag.`,
        safetyDesc: 'Experience Laser Tag to the end while having fun and respecting the rules of the game',
    },
    alb: {
        reservetXT: "Ju rekomandojmë rezervimin për çdo atraksion që të mos pritni në rend për një përvojë të jashtëzakonshme.",
        banner: `Çfarë është Laser Tag? Imagjinoni një video-lojë emocionuese live-aksion. Laser Tag Arena është e mbushur me pengesa që shtojnë intensitetin dhe e bëjnë çdoherë lojën të duket ndryshme.Është një lojë strategjie. Duhet të jesh i zgjuar, i shpejtë dhe i fokusuar nëse dëshirontë mbijetosh!
      Merrne shoqninë dhe sprovoni instinktet tuaja të mbijetesës ndërsa përpiqeni t'i mundni kundërshtarët tuaj.`,
        battleType: 'Llojet e Betejave',
        battleTypeDesc: `Laser Tag Arena është e mbushur me pengesa që shtojnë intensitetin dhe e bëjnë çdoherë lojën të duket ndryshme. Është një lojë strategjie. Duhet të jesh i zgjuar, i shpejtë dhe i fokusuar nëse dëshiron të mbijetosh! 
      
      Merrne shoqninë dhe sprovoni instinktet tuaja të mbijetesës ndërsa përpiqeni t'i mundni kundërshtarët tuaj.`,
        safetyDesc: 'Përjetoni Laser Tag deri në fund duke bërë qejf dhe respektuar rregullat e lojës',
    },
};

const karting = {
    eng: {
        rezRace: 'Reserve the Race',
        category: 'Category',
        whoCanDrive: 'Karting Eligibility',
        promoBannerTitle: 'Play More, Pay Less.',
        promoBannerDesc: `Maximize your karting excitement with our unbeatable COMBO PACKAGES for you and your crew! Book 2 races and save up to €8. The track awaits you! 
        
        Valid: Monday to Friday from 22:00 - 24:00`,
        promoBannerButtonText: 'Book Your Race',
        partyKarting: "Celebrate Your Birthday On The Track!",
        partyKartingDesc: "Experience the ultimate birthday celebration with our specially crafted programs for you and your friends.",
        safetyVidDesc: 'Play By The Rules',
        banner: "The most powerful and safest model available on the market. Featuring advanced technology, the RT10 provides exceptional driving comfort and impressive performance for racers of all levels, including beginners, to ensure a safe and exhilarating racing experience on our 500m track. Come and experience the fastest, longest, and most thrilling track around!",
        clickInterest: "Click interests",
        eventsDescription: "Competition events are waiting for you!",
        monthDriver: "Driver of the month",
        monthDriverDesc: "Book now to be part of the biggest race in the country",
        rides: "Go-Kart Track",
        ridesDesc: "A vibrant karting arena, with 3 levels and an observation platform, creates the perfect environment for an all-encompassing racing experience.",
        speedScaleTitle: 'Speed Levels',
        speedScale: 'The 5-speed level system allows you to safely master racing skills, and the intelligent dynamic speed adjustment system enables you to progress based on your age, experience, and driving abilities.',
        CompetitionRulesTitle: 'Competition Rules',
        CompetitionRulesDesc: 'Follow the rules of the game and stay safe on the track.',
        checkTheTrack: 'Explore the Track',
        // SPEED SCALE
        begginer: 'Begginer',
        begginerDriver: 'For younger drivers aged 6 to 11 who will learn the basics of karting and racing cars.',
        begginerAdvance: 'If you drive a lap faster than 75.5 s per lap, move to the DRIVER speed class.',

        driver: 'Family',
        driverDriver: 'For young drivers aged 6 to 16, learning the basics of karting and race cars. Children must meet a minimum height requirement of 150cm.',
        driverAdvance: 'If you complete a lap faster than 53.9s per lap, you move up to the RACER speed class.',

        racer: 'Standard +',
        racerDriver: 'In a standard + race , you gain instant access to the boost option. Simply press the button on the steering wheel, and enjoy a 5-second burst of super speed in every race. For individuals aged 14 and above with the ability to drive a kart.',

        pro: 'Pro Expert',
        proDriver: 'For drivers aged 18 and above, demonstrating excellent karting skills and achieving lap times under 50 seconds to qualify for PRO level certification.Available every day from 22:00 - 00:00.',

        safetyVid: 'Safety Briefing Video',
    },
    alb: {
        rezRace: 'Rezervo Garën',
        category: 'Kategoria',
        whoCanDrive: 'Kush mund të drejtojë makinën?',
        promoBannerTitle: 'LUJ SHUMË, PAGUJ PAK.',
        promoBannerDesc: `Garo më shumë me Double Race, Super e volitshme për ju dhe shoqnin tuaj! Rezervo 2 gara ne karting dhe kurseni 8€. Pista ju pret! 
        
        E vlefshme: Hëne - Premte nga ora 22:00 - 24:00.`,
        promoBannerButtonText: 'Rezervo garën',
        partyKarting: "Festo Ditëlindjen n'pistë!",
        partyKartingDesc: "Ne kemi përgatitur programet më të mira të ditëlindjeve për ju dhe miqtë tuaj. Rezervoni festën tuaj!",
        safetyVidDesc: 'Respekto Rregullat E Garës',
        banner: "Përjetoni garat e nivelit profesional në pistën 500m me Gokart- RSX2 krejt elektrik më të fuqishmin dhe më të sigurtin në treg. Me teknologjitë e reja, RT10 ofron komoditet të pashembullt të ngasjes, performancë mbresëlënëse që i lejon të gjithë shoferët, ekspertët apo fillestarët, të garojnë të sigurt! Pista më e shpejtë, më e gjatë dhe më emocionuese ju pret!",
        eventsDescription: "Ne kemi përgatitur evente garash dhe ngjarje si lojtari më i mirë i muajit dhe ekipi më I mirë i muajit.",
        monthDriver: "Shoferi i muajit",
        monthDriverDesc: "Rezervo tani dhe behu pjesë e garës më të madhe në vend!",
        rides: "Pista E Karting",
        speedScaleTitle: 'Shkallët E Shpejtësisë',
        ridesDesc: "Një arenë tërheqëse kartingu shumëngjyrëshe, 3 katshe dhe me një platformë shikimi krijon ambientin më të mirë për të gjithë eksperiencën.",
        speedScale: 'Një arenë tërheqëse kartingu shumëngjyrëshe, 3 katshe dhe me një platformë shikimi krijon ambientin më të mirë për të gjithë eksperiencën.',
        CompetitionRulesTitle: 'Rregullat e Garës',
        CompetitionRulesDesc: 'Ndiqni rregullat e lojës dhe qëndroni të sigurtë në pistë.',
        checkTheTrack: 'Shikoni pistën',
        // SPEED SCALE
        begginer: 'Gara Familjare',
        begginerDriver: 'E përshtatshme për familjet me fëmijë më të rinj të moshës 6 deri në 11 vjeç, të cilët do të mësojnë bazat e kartingut dhe makinave të garave.',
        begginerAdvance: 'Nëse vozitni një xhiro më shpejt se 75,5 s për xhiro, kaloni në klasën e shpejtësisë DRIVER.',

        driver: 'Family',
        driverDriver: 'Për shoferët më të rinj të moshës 6 deri në 16 vjeç, të cilët do të mësojnë bazat e kartingut dhe makinave të garave. Fëmijët duhet të jenë të gjatë 150 cm +',

        racer: 'Standard +',
        racerDriver: 'Në garat standard +, ju keni akses të menjëhershëm në opsionin Boost. Thjesht shtypni butonin në timon dhe shijoni super shpejtësinë prej 5 sekondash në çdo garë. Për individët e moshës 16 vjeç e lart me aftësi për të drejtuar kart.',

        pro: 'Pro Expert',
        proDriver: 'Për shoferët e moshës 18 vjeç e mbi, të cilët tregojnë aftësi të shquara në ngasjen e kartingut dhe arrijnë të përfundojnë xhiron nën 50 seconda për tu certifikuar në nivelin PRO. E disponueshme çdo ditë nga 22:00 - 00:00',
        
        safetyVid: 'Video e sigurisë',

        safety: 'Përjetoni GoKart deri në fund duke bërë qejf dhe respektuar rregullat e pistës'
    },
};

const lodra = {
    eng: {
        banner: `Experience the super day of fun at LODRA!`,
        welcomeHeader: "Welcome to Lodra",
        welcomeDesc: `Our playground is designed to spark creativity and curiosity in children and toddlers, with interactive play areas that ignite their minds and bodies. Our soft play equipment and obstacle courses provide a safe and exciting space for children to explore, climb and slide. With our bright colors, friendly staff and endless play possibilities, our indoor playground is the perfect place for children to learn, grow and have fun. Come and join the adventure today!`,
        welcomeTHeader: 'The world of toys',
        welcomeTDesc: `World of Toys Children have their own land in HyerActive. A land they built themselves, so you can expect some surprises. And singing. And dancing. And of course the carousels that offer physical thrills, the best treats in the world and lots of playful fun. Children run around here, taking advantage of every opportunity to have fun. Because the more fun we have, the more fun it is!`,
        rezNow: 'Rezerve Now!',
        rezDesc: 'We recommend you for every attraction not to wait in line for an extraordinary experience.',
        sweetHeader: 'The Sweet Factory',
        sweetDesc: `Our candy and chocolate factory is the perfect place for kids and adults to indulge their sweet cravings. From delicious chocolates and gummies to colorful confections, we have a wide variety of delicious treats that are sure to please every eye and palate. Come and treat yourself to something sweet!`,
        comeParty: `Celebrate it your way!
    Celebrate your birthday with us`,

        happyBirthdayHeader: 'Celebrate Your Birthday With Us',
        happyBirthdayDesc: "Experience the ultimate birthday celebration with our specially crafted programs for you and your friends.",
        arcade: 'Nga titujt ekskluzivë të filmave të preferuar deri te lojërat e fëmirisë, ka diçka për të gjithë.'
    },
    alb: {
        banner: `Përjetoni super ditën e argëtimit në LODRA!`,
        welcomeHeader: "Mirëseerdhet to Lodra",
        welcomeDesc: `Sheshi ynë i lojërave është krijuar për të ndezur kreativitetin dhe kuriozitetin tek fëmijët dhe të vegjlit, me zona interaktive lojërash që ndezin mendjen dhe trupin e tyre. Pajisjet tona të buta të lojës dhe kurset me pengesa ofrojnë një hapësirë të sigurtë dhe emocionuese për fëmijët për të eksploruar, ngjitur dhe rrëshqitur. Me ngjyrat tona të ndezura, stafin miqësor dhe mundësitë e pafundme të lojës, këndi ynë i lojërave të brendshme është vendi i përsosur për fëmijët që të mësojnë, të rriten dhe të argëtohen. Ejani dhe bashkohuni në aventurën sot!`,
        welcomeTHeader: 'Bota e lodrave',
        welcomeTDesc: `Bota e Lodrave Fëmijët kanë tokën e tyre në HyerActive. Një tokë që ata e ndërtuan vetë, ndaj mund të prisni disa surpriza. Dhe duke kënduar. Dhe duke kërcyer. Dhe sigurisht karuselet që ofrojnë emocione fizike, trajtimet më të mira në botë dhe shumë argëtim lozonjare. Fëmijët vrapojnë këtu, duke shfrytëzuar çdo mundësi për t'u argëtuar. Sepse sa më shumë argëtim kemi, aq më shumë argëtim është!`,
        rezNow: 'Rezervo Tani!',
        rezDesc: 'Ju rekomandojmë për çdo atraksion që të mos pritni në rend për një përvojë të jastëzakonshme.',
        sweetHeader: 'Fabrika e ëmbël',
        sweetDesc: `Fabrika jonë e ëmbëlsirave dhe çokollatës është vendi i përsosur për fëmijët dhe të rriturit që të kënaqen me dëshirat e tyre për dëshirat e ëmbëla. Nga çokollata të shijshme dhe çamçakëz deri tek ëmëlsirat shumëngjyrëshe, ne kemi një shumëllojshmëri të gjerë ëmbëlsirash të shijshme që me siguri do të kënaqin çdo sy dhe shijeje. Ejani dhe trajtojeni veten me diçka të ëmbël!`,
        comeParty: `Festoje në mënyrën tuaj!
    Festoni ditëlindjen tuaj me ne`,

    happyBirthdayHeader: 'Festo Ditëlindjen Në HyperActive',
    happyBirthdayDesc: "Ne kemi përgatitur programet më të mira të ditëlindjeve për ju dhe miqtë tuaj. Rezervoni festën tuaj!",
    arcade: 'Nga titujt ekskluzivë të filmave të preferuar deri te lojërat e fëmirisë, ka diçka për të gjithë.'
    },

}

const bussinessRoom = {
    eng: {
        mainHeader: 'Celebrate your successes at HyperActive!',
        mainDesc: 'Toast to your success in our exquisitely crafted private rooms, tailored for unforgettable celebrations with your colleagues. Designed with meticulous attention to detail, our rooms are perfect for corporate triumphs and can accommodate teams of 20 to 200 people, ensuring a memorable experience for all.',
        mainBtnTxt: 'Book now',

        rezNow: 'Book Now!',
        contactTeam: `Get in touch with our team to ensure your next event is perfectly
        tailored to your needs`,
        callNow: 'Call 049/909-196',

        menuHeader: 'Special Menu For Your Special Day!',
        menuDesc: 'Our menu is packed with mouth-watering options that will satisfy any craving and our bar is fully stocked with a range of beers, wines, and cocktails.',
        menuBtn: 'Check menu',

        bookEvents: 'Book Your Upcoming Event',

        fullName: 'Name & Surname',
        companyName: 'Company',
        email: 'E-Mail',
        phone: 'Telephone',
        eventType: 'Type of Event',
        request: 'Requirements and details',
        submitBnt: 'Submit your request',
    },
    alb: {
        mainHeader: "Festo sukseset n'HyperActive",
        mainDesc: 'Festoni në dhomat tona private të dizajnuara me përpikmëri për festat me kolegët tuaj. Të përshtatshme për festime të korporatave dhe për ekipe nga 20 deri në 200 persona.',
        mainBtnTxt: 'Rezervo',

        rezNow: 'Rezervo Tani!',
        contactTeam: `Kontakto ekipën tonë për të përgaditur
        eventin tuaj të radhës `,
        callNow: 'Thirr tani 049/909-196',

        menuHeader: 'Special Menu For Your Special Day!',
        menuDesc: 'Menuja jonë është e mbushur me zgjedhje të shijshme që plotësojnë çdo dëshirë. Shumë llojshmëri birrash, verërash dhe koktejesh.',
        menuBtn: 'Shiko menu-në',

        bookEvents: 'Rezervo Eventin',

        fullName: 'Emri dhe Mbiemri',
        companyName: 'Emri i kompanisë',
        email: 'E-mail',
        phone: 'Telephone',
        eventType: 'Lloji i eventit',
        request: 'Kërkesat dhe detajet',
        submitBnt: 'Dërgo kërkesen'
    }
}

const recharge = {
    eng: {

        rechargeDesc: 'Parties with friends and family at SportsBar become more interesting. From sports games on the giant screen, spectacular Races in Racing Sims, Darts, Billiards to the super menu of food and drinks.',

        ignoreIfYouCanHeader: 'Ignore it. If you can',
        ignoreIfYouCanDesc: 'The largest sports broadcast television in the country',

        upperGallery: `Whether you’re a die-hard sports fan or just looking for a lively atmosphere to hang out with friends and family, we’ve got you covered. With multiple screens broadcasting live sports events, you won’t miss a single moment of the action. And if you need a break from the game, you can challenge your friends to a game of Bowling, Shuffleboard, AR darts, Axe Throwing, Biliardo 
        
        Check the weekly schedule below.`,
        timeCheck: 'Schedule',
        gallery: 'Gallery',

        food: 'Food',
        menuDesc: "Recharge's menu is filled with a wide variety of delicious options and the bar features a wide selection of beers, wines and cocktails to quench your thirst.",

        cocktails: 'Cocktails',

        reserveHeader: `Eat, 
        Drink,
        Play.`,

        // INPUTS
        fullName: 'Name & Surname',
        guests: 'Guests',

        // HELPER TEXTS
        fullNameHelperText: 'Your First And Last Name',
        emailHelperText: 'Your Email',
        phoneHelperText: 'Your Phone Number',
        guestsHelperText: 'The Number Of Guests',
    },
    alb: {

        rechargeDesc: 'Ndejat me shoqni e familje n’SportsBar bëhen ma interesant. Prej Lojërave sportive në ekran gjigand, Gara spektakulare në Racing Sims, Pikado, Biliardo e deri në super menu t’ushqimit e pijeve.',

        ignoreIfYouCanHeader: 'Hekja sytë nëse mundesh',
        ignoreIfYouCanDesc: 'Televizioni më i madh i transmetimit sportive në vend',

        upperGallery: `Kqyre ekipën tënde t’preferume në ekran gjigant LED  320 inç i cili transmeton  4K Ultra HD, ose në një nga 25 televizorët tanë me definicion të lartë që shfaqin ngjarje sportive drejtpërdrejt dhe mos humbisni  asnjë minutë nga aksioni.

        Përveq ndeshjeve të futboolit në ekran mund ti ndiqni edhe ndeshjet më të famshme te boxit, formula 1, basketball etj.

        Shiko orarin javorë më poshtë.`,
        timeCheck: 'Shiko Orarin',
        gallery: 'Galeria',

        food: 'Ushqimi',
        menuDesc: 'Menuja e Recharge është e mbushur me një shumëllojshmëri të gjerë opsionesh të shijshme dhe bari përmban një përzgjedhje të gjerë të birrave, verërave dhe koktejve për të shuar etjen tuaj.',

        cocktails: 'Cocktails',

        reserveHeader: `Ha, 
        Pi, 
        Luj.`,

        // INPUTS
            fullName: 'Emri dhe Mbiemri',
            guests: 'Mysafirët',

        // HELPER TEXTS
            fullNameHelperText: 'Emri dhe mbiemri juaj',
            emailHelperText: 'Emaili juaj',
            phoneHelperText: 'Numri i telefonit',
            guestsHelperText: 'Numri i mysafirëve',
    }
}

const hyperstrike = {
    eng: {
          // HYPERSTRIKE BANNER
          bannerTitle: `HYPERSTRIKE`,
          bannerDesc: `Full strategy game. You have to be smart, fast and focused if you want to survive!`,

            arenaHeader: `Epic Arena Of Legends`,
            arenaDesc: `An attractive colorful karting arena, 3 floors and a viewing platform creates the best environment for the whole experience.`, 

            arenaRunWay: 'Runway',
            arenaPlayers: 'People for battle',
            arenaBattle: 'Battle',
            arenaMatches: 'Matches per day',
            arenaAdrenaline: 'Adrenaline',

            gunsHeader: `Epic Weapon`,
            gunsDesc: `RSX2 Fully Electric Gokart, with the most advanced technology on the market, RTI 0 offers unparalleled driving comfort, impressive performance that allows all drivers, experts or beginners, to race safely!`,

          // BIRTHDAY BANNER 
          hyperBirthdayHeader: `Festo Ditëlindjen
          Në Hyperactive`,
          hyperBirthdayDescBigSH: `Festo ditëlindjen ndryshe, me më
          shumë adrenalinë!`,
          hyperBirthdayDescBig: 'Ne kemi përgatitur paketat më të mira të programeve të ndryshme Për ju dhe miqtë tuaj, të cilat përfshijnë atraksionet më argëtuese HyperActive.',
          bookNow: 'Rezervo',
          rezRace: "Let's Heat That Laser Gun",
          rezDesc: `We recommend making reservations for each attraction so you 
          don't have to wait in line for a great experience.`,
          howItsPlayed: "This is how it's played!",
    },
    alb: {
        // HYPERSTRIKE BANNER
        bannerTitle: `HYPERSTRIKE`,
        bannerDesc: `Lojë plotë strategji. Duhet të jesh i zgjuar, i shpejtë dhe i fokusuar nëse dëshiron të mbijetosh!`,

        arenaHeader: `Arena Epike E Legjendave`,
        arenaDesc: `Një arenë tërheqëse kartingu shumëngjyrëshe, 3 katshe dhe me një platformë shikimi krijon ambientin më të mirë Për të gjithë eksperiencën.`,

        arenaRunWay: 'Pistë',
        arenaPlayers: 'Persona për betejë',
        arenaBattle: 'Betejë',
        arenaMatches: 'Gara në ditë',
        arenaAdrenaline: 'Adreanlinë',

        gunsHeader: `Armë Epike`,
        gunsDesc: `RSX2 Gokart plotësisht elekrtik, me teknologjinë më të avancuar në treg, RTI 0 ofron komoditet të pashembullt të ngasjes, performancë mbresëlënëse që lejon të gjithë shoferët, ekspertët apo fillestarët, te garojnë të sigurt!`,

        // BIRTHDAY BANNER 
        hyperBirthdayHeader: `Festo Ditëlindjen
        Në Hyperactive`,
        hyperBirthdayDescBigSH: `Festo ditëlindjen ndryshe, me më
        shumë adrenalinë!`,
        hyperBirthdayDescBig: 'Ne kemi përgatitur paketat më të mira të programeve të ndryshme Për ju dhe miqtë tuaj, të cilat përfshijnë atraksionet më argëtuese HyperActive.',
        bookNow: 'Rezervo',
        rezRace: "Let's Heat That Laser Gun",
        rezDesc: `Ju rekomandojmë rezervimin për çdo atraksion që të mos 
        pritni në rend për një përvojë të jashtëzakonshme.`,
        howItsPlayed: 'Qe Qysh Luhet Loja!',
    }
}

export { vr, escape, arcade, axe, bowling, laser, karting, lodra, bussinessRoom, recharge, hyperstrike };