import { Link } from "react-router-dom";
import Grid from "../../layout/grid/Grid";
import style from "./ActivitiesBanner.module.css";
import lng from "../../utils/language";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

function SingleBanner(props) {
  const { language } = useSelector((state) => state.language);
  return (
    <div className={style.categoriesBanner}>
      <Grid no={"Banner12"}>
        <div className={style.categoriesData}>
          <Typography variant="h1" sx={{marginBottom: 0, fontSize: '32px', fontWeight: '800', whiteSpace: 'preserve-breaks'}}>{props.title}</Typography>
          <p>{props.desc}</p>
          {props.id && (
            <Box 
              component={Link} 
              className={style.reserveBtn} 
              to={`${props.id}`}
              sx={{
                borderColor: props.changeColor ? `${props.changeColor} !important` : '#af46fa !important',
                '&:hover': {
                  backgroundColor: props.changeColor ? `${props.changeColor} !important` : '#af46fa !important',
                }
              }}
            >
              {lng[language]["bookNow"]}
            </Box>
          )}
          {
            props.prices &&
            <p>
            {lng[language]["seePrices"]}{" "}
            <Box 
              component={Link} 
              to={`/activities/price#${props.linkPrice}`}
              sx={{
                color: props.changeColor ? `${props.changeColor} !important` : '#af46fa !important'
              }} 
            >{lng[language]["seePriceLink"]}</Box>
          </p>
          }
        </div>
        <div className={style.lightningImage}>
          <div className={style.lightningWrapper}>
            <div>
              <img src={props.image} alt={props.title} />
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
}
export default SingleBanner;
