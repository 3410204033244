import { Link } from "react-router-dom";
import style from "./SingleBanner.module.css";
import { useSelector } from "react-redux";
import lng from "../../utils/language";
import { Box } from "@mui/material";

function SingleBanner({ image, title, desc, list, longDesc, id, logo, color, referenc }) {
  const { language } = useSelector((state) => state.language);
  return (
    <>
      <Box
        ref={referenc ? referenc : ''}
        className={style.categoriesBanner}
        style={{ background: ` linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) ),url(${image})` }}
      >
        <Box>
          {logo && (
            <div>
              <Box component={'img'} src={logo} alt="category-logo" sx={{width: '100%', height: '100%', objectFit: 'contain'}} />
            </div>
          )}
          <p>{desc}</p>
          <Link to={`/reservation/${id}`} className="btn">
            {lng[language]['reserveARoom']}
          </Link>
        </Box>
      </Box>
      <Box className="container">
        {list && (
          <Box 
            sx={{
                display: 'grid',
                gridTemplateColumns: {
                    md: '1fr 1fr 1fr 1fr',
                    xs: '1fr 1fr'
                },
                gridGap: '20px'
            }} 
          >
            {Object.entries(list).map(([key, value]) => {
              return (
                <Box className={`${style.list}`} key={value} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <p>{key}</p>
                  <p style={{ color: color }}>{value}</p>
                </Box>
              );
            })}
          </Box>
        )}
        {longDesc && <p className={style.longDesc}>{longDesc}</p>}
      </Box>
    </>
  );
}
export default SingleBanner;
