import { useState } from "react";
import style from "./ShoppingCart.module.css";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import CartProducts from "../../components/reservation/CartProducts";
import {  useSelector } from "react-redux";

function MobileShoppingCart() {
  const [toggleCard, setToggleCart] = useState(false);
  const { items } = useSelector((state) => state.cart);
  const toggleShoppingCard = () => {
    setToggleCart(!toggleCard);
  }
  return (
    <>
      <div className={style.mobileCart} onClick={()=>toggleShoppingCard()}>
        <p>Shporta ime ({items?.length} )</p>
        <span>{!toggleCard ? <MdOutlineKeyboardArrowDown size={30}/> :  <MdOutlineKeyboardArrowUp size={30}/>} </span>
      </div>
      {toggleCard && <CartProducts/>}
    </>
  );
}
export default MobileShoppingCart;
