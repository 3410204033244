import { Box } from '@mui/material'
import Logo from "../general/Logo";
import React from 'react'
import LoginForm from './LoginForm';
import Register from '../../pages/public/register/Register';
import {MdClose} from "react-icons/md";
import style from "./LoginModal.module.css";
import NewRegister from '../../pages/public/register/NewRegister';

export default function LoginRegisterModal(props) {
    
    const [form, setForm] = React.useState(true);
    const modularForm = true 

    const container = {
        position: 'absolute',
        width: 1,
        overflow: 'hidden',
        height: 1,
        zIndex: 9999,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        backgroundColor: 'rgba(0, 0, 0,0.8)'
    }

    const wrapper = {
        width: 'fit-content',
        // padding:'40px 20px',
        borderRadius: '10px',
        margin: 'auto',
        backgroundColor: '#020013',
        border: '1px solid #321eff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    }

    return (
        <Box>
            {
                form === true
                ?
                <Box className={style.loginModal}>
                    <Box className={style.loginWrapper}>
                        <Logo/>
                        <span className={style.closeBtn} onClick={props.closeModal}><MdClose/></span>
                        <LoginForm form={form} setForm={setForm} isForm={modularForm} />
                    </Box>
                </Box>
                :
                <Box sx={container}>
                    <Box sx={wrapper}>
                    <span className={style.closeBtn} onClick={props.closeModal}><MdClose/></span>
                        {/* <Register form={form} setForm={setForm}  isForm={modularForm} /> */}
                        <NewRegister 
                            form={form}
                            setForm={setForm}
                            isForm={modularForm}
                        />
                    </Box>
                </Box>
            }
        </Box>
    )
}