import Grid from "../grid/Grid";
import ImageTextCard from "../../components/card/ImageTextCard";

function GeneralLayout(props) {

  return (
    <Grid no={"ReverseSingle"}>
      {props.data.map((i, j) => {
        return <ImageTextCard data={i} key={j} specialStyling={props.specialStyling} txtContainerStyling={props.txtContainerStyling} />;
      })}
    </Grid>
  );
}
export default GeneralLayout;
