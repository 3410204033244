import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import constants from '../../utils/consts'

const image = {zIndex: 2, objectFit: 'contain', position: 'relative'}
const closeBtn = {position: 'absolute', top: '25px', right: '30px', zIndex: 3, cursor: 'pointer', fontSize: '30px', color: '#FFF'}
const mainImg = {width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px', cursor: 'pointer'}

export default function GalleryBox({galleryImages, ignorePath}) {
  const [open, setOpen] = useState(false);
  const [currImg, setCurrImg] = useState()

  const galleryAllign = [
    {
      gridArea: {
        xs: '1 / 1 / 2 / 2',
        sm: '1 / 1 / 2 / 3',
        md: '1 / 1 / 2 / 3'
      },
      height: {
        xs: 'auto',
        sm: '300px',
        md: '300px'
      },
    },
    {
      gridArea: {
        xs: '1 / 2 / 2 / 3',
        sm: '1 / 3 / 2 / 4',
        md: '1 / 3 / 2 / 4'
      },
      height: {
        xs: 'auto',
        sm: '300px',
        md: '300px'
      },
    },
    {
      gridArea: {
        xs: '2 / 1 / 3 / 2',
        sm: '2 / 1 / 3 / 2',
        md: '1 / 4 / 2 / 4'
      },
      height: {
        xs: 'auto',
        sm: '300px',
        md: '300px'
      },
    },
    {
      gridArea: {
        xs: '2 / 2 / 3 / 3',
        sm: '2 / 2 / 3 / 4',
        md: '2 / 1 / 3 / 2'
      },
      height: {
        xs: 'auto',
        sm: '300px',
        md: '300px'
      },
    },
    {
      gridArea: {
        xs: '3 / 1 / 4 / 2',
        sm: '3 / 1 / 4 / 3',
        md: '2 / 2 / 3 / 3'
      },
      height: {
        xs: 'auto',
        sm: '300px',
        md: '300px'
      },
    },
    {
      gridArea: {
        xs: '3 / 2 / 4 / 3',
        sm: '3 / 3 / 4 / 4',
        md: '2 / 3 / 3 / 5'
      },
      height: {
        xs: 'auto',
        sm: '300px',
        md: '300px'
      },
    }
  ];

//   function getData(data) {
//     const dataArr = [];
//     if (data) {
//       data.map((data) => {
//         dataArr.push({imgPath: data})  
//       })  
//     }
//     return dataArr;
//   }

//   function shuffle(arra1) {
//     var ctr = arra1.length,
//       temp,
//       index;
//     while (ctr > 0) {
//       index = Math.floor(Math.random() * ctr);
//       ctr--;
//       temp = arra1[ctr];
//       arra1[ctr] = arra1[index];
//       arra1[index] = temp;
//     }
//     return arra1;
// }

//   function getMultipleRandom(arr) {
//     const randomObject = shuffle(arr);
//     return randomObject;
// }

  const [ gallery ] = useState(() => galleryImages.map((data, i) => (
    { "imgPath": data, "gridSx" : galleryAllign[i]} 
    ))
  )


  const bigImage = (
    <Box 
    sx={{
        position: 'fixed', 
        width: '100%', 
        height: {
            xs: 'calc(100vh - 79px)', 
            sm: 'calc(100vh - 142px)'
        }, 
        top: {
            xs: '79px', 
            sm: '142px'
        }, 
        left: 0, 
        display: open === true ? 'block' : 'none', 
        zIndex: 2
    }}
>
        <Box onClick={() => {setOpen(false); document.body.style.overflow = "unset"}} sx={closeBtn}><AiFillCloseCircle /></Box>
        <Box component={'img'} src={currImg} height={'100%'}  width={'100%'} sx={image} />
        <Box sx={{position: 'absolute', background: '#000', opacity: .5, top: 0, left: 0, height: '100%', width: '100%'}}></Box>
    </Box>
)

  return (
    <>
      {
        gallery && gallery.map((data, index) => (
          <Box key={index} sx={data.gridSx}>
              <Box component={'img'} onClick={e => {setOpen(true); document.body.style.overflow = "hidden"; setCurrImg(e.target.currentSrc);}} src={ignorePath === true ? data.imgPath : constants.imgPath+data.imgPath} alt={'gallery'} sx={mainImg} />
          </Box>
          
        ))
      }
      {bigImage}
    </>
  )
}