import React from 'react';
import Box from '@mui/material/Box';
import Containers from '../../layout/container/Containers';

export default function Test() {
    return (
        <Containers mainStyle={{color: '#FFF', paddingTop: '80px'}}>
            <Box sx={{backgroundColor: 'blue'}}>
                1
            </Box>

            <Box sx={{backgroundColor: 'red'}}>
                2
            </Box>

            <Box sx={{backgroundColor: 'green'}}>
                3
            </Box>
        </Containers>
    )
}